import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Select from "components/shared/Select";
import InputField from "components/shared/InputField";

const UserHeader = (props) => {
  const { onChangeSearch, onChangeProfile, profiles, filters, onReset } = props;
  return (
    <>
      <div className="col-sm-4 header-app">
        <Link to="/user/" className="uil-plus-circle plus-btn"></Link>
      </div>

      <div className="col-sm-3 header-app padding-right-header">
        <Select
          name="role"
          label="Perfil: "
          defaultOption="Tots els perfils"
          value={filters.roleId}
          onChange={onChangeProfile}
          options={profiles}
          horizontal={true}
          required={true}
        />
      </div>

      <div className="col-sm-4 header-app padding-right-header">
        <InputField
          label="Buscar: "
          placeholder="Filtrar usuaris"
          value={filters.search}
          onChange={(e) => onChangeSearch(e.target.value)}
          horizontal={true}
          isMandatory={false}
        />
      </div>
      <div className="col-sm-1 header-app padding-right-header">
        <span
          className="uil-filter-slash delete-filters-btn"
          title="Netejar filtres"
          onClick={onReset}
        ></span>
      </div>
    </>
  );
};

UserHeader.propTypes = {
  profiles: PropTypes.array,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeProfile: PropTypes.func.isRequired,
};

export default UserHeader;
