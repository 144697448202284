import {
  handleResponse,
  handleError
} from "./utilApi";

import axios from 'axios'

const baseUrl = window.location.origin + "/api/roles/"

export function getAllProfiles() {
  return axios.get(baseUrl)
    .then(handleResponse)
    .catch(handleError);
}

