import { UsersPage } from "components/pages/users/UsersPage";
import { ContactsPage } from "components/pages/contacts/ContactsPage";
import ManageUserForm from "components/pages/users/components/ManageUserForm";
import ContactFormPage from "components/pages/contacts/ContactFormPage";
import { Roles } from "constants/Roles";

export const privateRoutes = [
  {
    key: "authentication",
    path: "/authentication/login-callback",
    component: ContactsPage,
    exact: true,
    permission: [Roles.ADMIN, Roles.CONSULTA, Roles.EDITOR],
  },
  {
    key: "contacts",
    path: "/contacts",
    component: ContactsPage,
    exact: true,
    permission: [Roles.ADMIN, Roles.CONSULTA, Roles.EDITOR],
  },
  {
    key: "users",
    path: "/users",
    component: UsersPage,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    key: "user-detail",
    path: "/user/:id",
    component: ManageUserForm,
    permission: [Roles.ADMIN],
  },

  {
    key: "contact-detail",
    path: "/contact/:id",
    component: ContactFormPage,
    permission: [Roles.ADMIN, Roles.CONSULTA, Roles.EDITOR],
  },
  {
    key: "new-user",
    path: "/user/",
    component: ManageUserForm,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    key: "new-contact",
    path: "/contact/",
    component: ContactFormPage,
    exact: true,
    permission: [Roles.ADMIN, Roles.EDITOR],
  },
  {
    key: "home",
    path: "/",
    component: ContactsPage,
    exact: true,
    permission: [Roles.ADMIN, Roles.CONSULTA, Roles.EDITOR],
  },
];
