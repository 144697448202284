import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  editModeState,
  modalListJobsData,
  setModalListJobs,
} from 'redux/slices/contactSlice';
import { Accordion, Card } from 'react-bootstrap';
import MultiSelectListModal from 'components/shared/MultiSelectListModal';
import SimpleTable from 'components/shared/SimpleTable';
import JobsModalListFilters from 'components/pages/contacts/components/Modals/JobsModalListFilters';
import { getFilteredJobs } from 'api/masterEntitiesApi';

const JobForm = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  const editMode = useSelector(editModeState);

  const [openJobsListModal, setOpenJobsListModal] = useState(false);
  const modalListJobs = useSelector(modalListJobsData);
  const dispatch = useDispatch();
  const [jobFilters, setJobFilters] = useState({
    jobType: '',
    jobFamilyType: '',
  });

  const [arrowClass, setArrowClass] = useState("accordion-arrow-down")

  const handleArrowClass = () => {
    const newArrowClass = arrowClass === "accordion-arrow-down" ? "accordion-arrow-up" : "accordion-arrow-down";
    setArrowClass(newArrowClass)
  }

  const columnsModal = [
    {
      dataField: 'id',
      text: 'id',
      hidden: true,
    },
    {
      dataField: 'jobType',
      text: 'Ofici',
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
    },
    {
      dataField: 'jobFamilyType',
      text: "Família de l'ofici",
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
    },
  ];

  const formatFamilyTypeCol = (cell, row) => {
    return (
      <>
        <span>{'Família ' + row.jobFamilyType}</span>
      </>
    );
  };

  const columnsTable = [
    {
      dataField: 'jobType',
      text: 'Oficis assignats',
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
    },
    {
      dataField: 'jobFamilyType',
      text: '',
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
      formatter: formatFamilyTypeCol,
    },
  ];

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    getJobs(jobFilters);
  }, [jobFilters]);

  const getJobsModal = () => setOpenJobsListModal(true);

  const handleAreaContactArtisanJob = (values) => {
    setFieldValue("area.artisan.jobTypes", [...values])
    setOpenJobsListModal(false);
  };

  const getJobs = async (jobFilters) => {
    const data = await getFilteredJobs(jobFilters);
    dispatch(setModalListJobs(data));
  };


  const onChangeJobSearch = (values) => {
    setJobFilters({
      ...jobFilters,
      jobType: values.hasOwnProperty('jobType')
        ? values.jobType
        : jobFilters.jobType,
      jobFamilyType: values.hasOwnProperty('jobFamilyType')
        ? values.jobFamilyType
        : jobFilters.jobFamilyType,
    });
  };

  const jobsModalListFilters = JobsModalListFilters({
    onChangeSearch: onChangeJobSearch,
    filters: jobFilters,
  });

  return (
    <>
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} onClick={handleArrowClass} eventKey="0">
          Ofici
          <i className={"uil-arrow-circle-down " + arrowClass} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="row form-content">
              <div className="col-sm-4 form-col table-content scrollableList-big no-margin-left no-margin-right">
                  <SimpleTable
                    columns={columnsTable}
                    data={values.area.artisan.jobTypes}
                    noResultsFound={"Cap ofici assignat."}
                  />
              </div>
              {editMode && (
                <div className="col-sm-2 form-col">
                  <button
                    type="button"
                    className="open-modal-btn form-col-btn margin-left-24px"
                    onClick={() => {
                      getJobsModal();
                    }}
                  >
                    <i className="uil-plus-circle form-col-btn-icon" />
                    <span className="form-col-btn-text">Seleccionar oficis</span>
                  </button>
                </div>
                )
              }
            </div>
            <div className="error-msg">{errors?.area?.artisan?.jobTypes}</div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
    <MultiSelectListModal
      isOpen={openJobsListModal}
      title="SELECCIONAR OFICIS"
      data={modalListJobs}
      columns={columnsModal}
      filterElement={jobsModalListFilters}
      noResultsFound={"No s'han trobat oficis."}
      onAccept={handleAreaContactArtisanJob}
      onCancel={setOpenJobsListModal}
      selectedItems={values.area.artisan.jobTypes}
    />
    </>
  );
};

export default JobForm;
