import React from "react";
import PropTypes from "prop-types";

function InputField({
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
  type,
  horizontal,
  disabled,
  autofocus,
  readonly,
  isMandatory,
  className,
  classNameCols
}) {
  let inputClass = "form-control";
  if (error && error.length > 0) {
    inputClass += " is-invalid";
  }

    if (value!=undefined && value.toUpperCase() == "NO ESTABLECIDO") {
    inputClass += " required-field";
  }

  const handleDateChange = (e) => {
    if(!e.target.value)
      onChange(e)
    const year = new Date(e.target.value).getFullYear();
    if(year <= (new Date().getFullYear() + 50))
      onChange(e)
    return;
  }

  let classNameDiv =
    className !== undefined ? "form-group " + className : "form-group ";

  return horizontal ? (
    <div className={classNameDiv + " row"}>
      <label htmlFor={name} className={classNameCols ? "col-sm-" + classNameCols[0] : "col-sm-2"}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <div className={classNameCols ? "col-sm-" + classNameCols[1] : "col-sm-10"}>
        <input
          type={type ? type : "text"}
          className={inputClass}
          name={name}
          placeholder={placeholder}
          onChange={(e) => type && type === "date" ? handleDateChange(e) : onChange(e)}
          onBlur={onBlur}
          value={value ?? ""}
          disabled={disabled}
          readOnly={readonly}
          autocomplete="off"
          autoFocus={autofocus}
        />
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  ) : (
    <div className={classNameDiv}>
      <label htmlFor={name}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <input
        type={type ? type : "text"}
        className={inputClass}
        name={name}
        placeholder={placeholder}
        onChange={(e) => type && type === "date" ? handleDateChange(e) : onChange(e)}
        onBlur={onBlur}
        value={value ?? ""}
        disabled={disabled}
        readOnly={readonly}
        autocomplete="off"
        autoFocus={autofocus}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string || PropTypes.number,
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  isMandatory: PropTypes.bool,
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  classNameCols: PropTypes.array,
};

export default InputField;
