import React, { useEffect, useState } from "react";
import PageTitle from "../../shared/PageTitle";
import ContactHeader from "./components/ContactHeader";
import ContactList from "./components/ContactList";
import { toSelect, toTextDate } from "helpers/utils";
import { contactsMaster, consentsMaster } from "api/masterEntitiesApi";
import { getAllCityHalls } from "api/addressApi";
import { getFilteredContacts } from "api/contactApi";
import { useHistory } from "react-router-dom";
import {
  getAreasTypes,
  areasTypesData,
  getCarnetTypes,
  carnetTypesData,
  getJobs,
  jobsData,
  getAssociations,
  associationsData,
  getRelations,
  relationsData,
} from "redux/slices/masterSlice";
import { useDispatch, useSelector } from "react-redux";

export const ContactsPage = () => {
  const title = "CONTACTES";
  const breadcrumbItems = [
    {
      text: "Inici",
      current: false,
      linkTo: "/",
    },
    {
      text: "Contactes",
      current: true,
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();

  const [paginationOptions, setPaginationOptions] = useState({});
  const [contactTypes, setContactTypes] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [cityHalls, setCityHalls] = useState([]);
  const [consentTypes, setConsentTypes] = useState([]);
  const [timeOut, setTimeOut] = useState(null);

  const [filters, setFilters] = useState({
    totalSize: 0,
    page: 1,
    sizePerPage: 10,
    orderBy: "",
    sortOrder: "asc",
    surname: "",
    email: "",
    documentNumber: "",
    contactTypeId: "",
    cityHallId: "",
    consentTypeId: "1",
    inactiveTypeId: "1",
    areaTypeId: "",
    relationTypeId: "",
    associationTypeId: "",
    jobType: "",
    artisanContactCarnetTypeId: "",
    subscriptedFrom: "",
    subscriptedTo: "",
  });

  const areaTypes = toSelect(useSelector(areasTypesData));
  const carnetTypes = toSelect(useSelector(carnetTypesData));
  const jobs = toSelect(useSelector(jobsData));
  const relations = toSelect(useSelector(relationsData));
  const associations = toSelect(useSelector(associationsData));

  const getContactTypes = async () => {
    const contactTypes = await contactsMaster();
    setContactTypes(toSelect(contactTypes));
  };

  const getCityHalls = async () => {
    const CityHalls = await getAllCityHalls();
    setCityHalls(toSelect(CityHalls));
  };

  const getConsents = async () => {
    const consentTypes = await consentsMaster();
    setConsentTypes(toSelect(consentTypes));
  };

    const getInactiveStates = () => {
        return toSelect([{
            id: 1, name: 'Només actius'
        }, { id: 2, name: 'Tots' }]);
    };

  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "surname1",
      text: "Cognom 1",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "surname2",
      text: "Cognom 2",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "name",
      text: "Nom",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "phoneNumber1",
      text: "Telèfon 1",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "cityName",
      text: "Població",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "provinceName",
      text: "Província",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "tradeName",
      text: "Nom comercial",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "consent",
      text: "Consentiment",
      sort: false,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "deleteDate",
      text: "Dada baixa",
      sort: false,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];

  useEffect(() => {
    dispatch(getAreasTypes());
    dispatch(getCarnetTypes());
    dispatch(getJobs());
    dispatch(getAssociations());
    dispatch(getRelations());
  }, [dispatch]);

  useEffect(() => {
    getContactTypes();
    getCityHalls();
    getConsents();
  }, []);

  useEffect(() => {
    const getContacts = async () => {
      const filteredContacts = await getFilteredContacts(filters);
      const { data, ...filtersServer } = filteredContacts;
        setContacts(data.map(({ deleteDate, ...rest }) => ({
            deleteDate: toTextDate(deleteDate),           
            ...rest,
        })));

      setPaginationOptions({
        pageStartIndex: 1,
        firstPageText: "Primer",
        firstPageTitle: "Primera pàgina",
        prePageText: "Anterior",
        prePageTitle: "Pàgina anterior",
        nextPageText: "Següent",
        nextPageTitle: "Següent pàgina",
        lastPageText: "Últim",
        lastPageTitle: "Última pàgina",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [5, 10, 25],
        page: filtersServer.page,
        sizePerPage: filtersServer.sizePerPage,
        totalSize: filtersServer.totalSize,
      });
    };

    getContacts();
  }, [filters]);

  const noResultsFound = () => `No s'han trobat resultats`;

  const defaultSorted = [
    {
      dataField: "surname1",
      order: "asc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
      Mostrant {from} a {to} de {size} entrades
    </span>
  );

  const handleTable = async (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "pagination") {
      setFilters({ ...filters, page: page, sizePerPage: sizePerPage });
    }

    if (type === "sort") {
      setFilters({
        ...filters,
        sortOrder: sortOrder,
        orderBy: sortField,
      });
    }
  };

  const handleChangeEmail = (email) => {
    if (timeOut) clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
        setFilters({ ...filters, email });
      }, 30)
    );
  };

    const handleChangeName = (surname) => {

    if (timeOut) clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
          setFilters({ ...filters, surname });
          console.log(filters);
      }, 30)
    );
  };

  const handleChangeDocumentNumber = (documentNumber) => {
    if (timeOut) clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
        setFilters({ ...filters, documentNumber });
      }, 30)
    );
  };

  const handleChangeContactType = async (event) => {
    setFilters({ ...filters, contactTypeId: event.target.value });
  };

  const handleChangeCityHall = async (event) => {
    setFilters({ ...filters, cityHallId: event.target.value });
  };

  const handleChangeAreaType = async (event) => {
    setFilters({ ...filters, areaTypeId: event.target.value });
  };

  const handleChangeJobType = async (value) => {
    setFilters({ ...filters, jobType: value });
  };

  const handleChangeArtisanContactCarnetType = async (event) => {
    setFilters({ ...filters, artisanContactCarnetTypeId: event.target.value });
  };

  const handleChangeAssociationType = async (event) => {
    setFilters({ ...filters, associationTypeId: event.target.value });
  };

  const handleChangeRelationType = async (event) => {
    setFilters({ ...filters, relationTypeId: event.target.value });
  };

  const handleChangeSubscriptedFrom = async (value) => {
    setFilters({ ...filters, subscriptedFrom: value });
  };

  const handleChangeSubscriptedTo = async (value) => {
    setFilters({ ...filters, subscriptedTo: value });
  };

  const onChangeConsentType = async (event) => {
    setFilters({ ...filters, consentTypeId: event.target.value });
  };

  const onChangeInactiveType = async (event) => {
      setFilters({ ...filters, inactiveTypeId: event.target.value });
  };

  const handleRowEvents = (e, row, rowIndex) => {
    history.push(`/contact/${row.id}`);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowEvents(e, row, rowIndex);
    },
  };

  const handleReset = async () => {
    setFilters({
      ...filters,
      surname: "",
      email: "",
      documentNumber: "",
      contactTypeId: "",
      cityHallId: "",
      consentTypeId: "1",
      areaTypeId: "",
      jobTypeId: "",
      associationTypeId: "",
      relationTypeId: "",
      artisanContactCarnetTypeId: "",
      subscriptedFrom: "",
      subscriptedTo: "",
    });
    };

    const rowClasses = (row, rowIndex) => {
        console.log(row);
        return row.deleteDate!="" && row.deleteDate != null ? 'th-baixa' : '';
    };

  return (
    <>
      <PageTitle title={title} breadcrumbItems={breadcrumbItems} />
      <div className="main-content">
        <ContactHeader
                  filters={filters}
                  
                  onChangeName={handleChangeName}
                  onChangeEmail={handleChangeEmail}
                  onChangeDocumentNumber={handleChangeDocumentNumber}
                  onChangeContactType={handleChangeContactType}
                  onChangeConsentType={onChangeConsentType}
                  onChangeInactiveType={onChangeInactiveType}
                  onChangeCityHall={handleChangeCityHall}
                  onChangeAreaType={handleChangeAreaType}
                  onChangeJobType={handleChangeJobType}
                  onChangeArtisanContactCarnetType={
                      handleChangeArtisanContactCarnetType
                  }
                  onChangeAssociationType={handleChangeAssociationType}
                  onChangeRelationType={handleChangeRelationType}
                  onChangeSubscriptedFrom={handleChangeSubscriptedFrom}
                  onChangeSubscriptedTo={handleChangeSubscriptedTo}
                  onReset={handleReset}
                  cityHalls={cityHalls}
                  areaTypes={areaTypes}
                  jobs={jobs}
                  associations={associations}
                  relations={relations}
                  carnetTypes={carnetTypes}
                  contactTypes={contactTypes}
                  consentTypes={consentTypes}
                  inactiveTypes={getInactiveStates()}
        />
        <div className="table-content margin-top-30px">
          <ContactList
            contacts={contacts}
            defaultSorted={defaultSorted}
            columns={columns}
            handleTable={handleTable}
            noResultsFound={noResultsFound}
            paginationOptions={paginationOptions}
            wrapperClasses="table-responsive"
            bordered={false}
            rowEvents={rowEvents}
            rowClasses={rowClasses}
          />
        </div>
      </div>
    </>
  );
};
