import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { userProfileData } from "redux/slices/userSlice";

const CanAccessContent = (props) => {

    const {children, roles} = props

    const userProfile = useSelector(userProfileData);

    const canAccess = () => roles.includes(userProfile.role)

    return (canAccess() && children)
}

CanAccessContent.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.arrayOf(PropTypes.string)
}

export default CanAccessContent;
