import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import SimpleTable from 'components/shared/SimpleTable';
import MultiSelectListEditableModal from "components/shared/MultiSelectListEditableModal";
import RelationsModalListFilters from 'components/pages/contacts/components/Modals/RelationsModalListFilters';
import { getFilteredRelations } from "api/contactApi"
import { useDispatch, useSelector } from 'react-redux';
import { editModeState } from 'redux/slices/contactSlice';
import InputField from "components/shared/InputField";
import moment from "moment";

const RelationForm = () => {


    const [currentAction, setCurrentAction] = useState('');
    const [currentYear, setCurrentYear] = useState('');

    const [currentRow, setCurrentRow] = useState({ action: '', year: '', selectedDate: '' });
    const [currentRowIndex, setCurrentRowIndex] = useState(0);

    const dispatch = useDispatch();

    const { values, errors, handleChange, setFieldValue } = useFormikContext();

    const [openRelationsListModal, setOpenRelationsListModal] = useState(false);
    const [modalListRelations, setModalListRelations] = useState([]);
    const [relationFilters, setRelationFilters] = useState({
        name: ''
    });

    const editMode = useSelector(editModeState);

    const columns = [
        {
            dataField: 'id',
            text: 'id',
            hidden: true,
        },
        {
            dataField: 'name',
            text: 'Nom',
            sort: false,
            headerStyle: () => {
                return { width: '35%' };
            }
        },
        {
            dataField: 'action',
            text: 'Acció',
            sort: false,
            headerStyle: () => {
                return { width: '15%' };
            }
        },
        {
            dataField: 'year',
            text: 'Any',
            sort: false,
            headerStyle: () => {
                return { width: '15%' };
            }
        }
      ];

    useEffect(() => {
        getRelations();
    }, []);

    useEffect(() => {
        getRelations(relationFilters);
    }, [relationFilters]);

    const onChangeRelationSearch = (values) => {
        console.log(values);
        
        setRelationFilters({
            ...relationFilters,
        name: values.hasOwnProperty('name')
            ? values.name
            : relationFilters.name
        });
    };

    const handleReset = () => {
        setRelationFilters({
            name: ''
        });
    }

    const relationsModalListFilters = RelationsModalListFilters({
        onChangeSearch: onChangeRelationSearch,
        filters: relationFilters,
        onReset: handleReset
    });

    const getRelationModal = () => setOpenRelationsListModal(true);

    const getRelations = async (relationFilters) => {
        const data = await getFilteredRelations(relationFilters);
        setModalListRelations(data);
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#e7c5c5'
    };

    const rowEvent = {
        onClick: (e, row, rowIndex) => {

            console.log(row);
            setCurrentRowIndex(rowIndex);
           
            setCurrentRow(row);
           
        }
    };

    const updateValue = (val) => {
        
        values.relation.relations[currentRowIndex].selectedDate = val;
        values.relation.relations[currentRowIndex].year = moment(val, "YYYY/MM/DD").year();
        setCurrentYear(val);
    }

    const onChange = (e) => {
        console.log(e);
        var val = e.target.value;
        updateValue(val);
    }

    const updateActionValue = (val) => {

        values.relation.relations[currentRowIndex].action = val;
        setCurrentAction(val);
    }

    const onChangeAction = (e) => {
        var val = e.target.value;
        updateActionValue(val);
    }


    const handleRelations = (values) => {   

        setFieldValue("relation.relations", [...values]);
        setOpenRelationsListModal(false);        
    };

    return (
        <>
            <div className="row form-content">
              <div className="col-sm-5 form-col table-content scrollableList-big no-margin-bottom">
                  <SimpleTable
                    columns={columns}
                        data={values.relation.relations}
                        rowEvents={rowEvent}
                        selectRow={selectRow}
                    noResultsFound={"Cap relació assignada."}
                  />
                </div>

                {(values.relation.relations.length>0 && currentRowIndex != null && currentRowIndex >= 0) &&

                    <div className="col-sm-2 form-col">
                    <InputField
                            label="Acció"
                            name="action"
                            placeholder="Acció"
                            type="text"
                            horizontal={false}
                        value={values.relation.relations[currentRowIndex].action}
                        disabled={!editMode}    
                        onChange={onChangeAction}
                            isMandatory={true}
                        />
                    </div>
                }
                {(values.relation.relations.length > 0 && currentRowIndex != null && currentRowIndex >= 0) &&


                    <div className="col-sm-2 form-col">

                    <InputField
                        label="Any"
                        name="year"
                        value={
                            values.relation.relations[currentRowIndex].selectedDate                               
                        }
                        horizontal={false}
                        disabled={!editMode}
                        onChange={onChange}
                        isMandatory={true}
                        type="date"
                    />

                        
                    </div>               
                }
              
            </div>
            {editMode && (
                <div className="form-content no-margin-top">
                    <button
                        type="button"
                        className="open-modal-btn form-col-btn margin-left-24px no-margin-top"
                        onClick={() => {
                            getRelationModal();
                        }}
                    >
                        <i className="uil-plus-circle form-col-btn-icon" />
                        <span className="form-col-btn-text">Seleccionar relacions</span>
                    </button>
                </div>
            )}
            <MultiSelectListEditableModal
                isOpen={openRelationsListModal}
                title="SELECCIONAR RELACIÓ"
                data={modalListRelations}
                columns={columns}
                filterElement={relationsModalListFilters}
                noResultsFound={"No s'han trobat relacions."}
                onAccept={handleRelations}
                onCancel={setOpenRelationsListModal}
                selectedItems={values.relation.relations}
            />
        </>
    );
};

export default RelationForm;
