import { getFilteredCompanies } from "api/companyApi";
import InputField from "components/shared/InputField";
import ListModal from "components/shared/ListModal";
import Select from "components/shared/Select";
import { COMPANY_TYPE } from "constants/applicationConstants";
import { useFormikContext } from "formik";
import { toSelect } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsCompanyType,
  viewModeState,
  editModeState,
  isCompanyTypeState,
} from "redux/slices/contactSlice";
import { contactTypesData } from "redux/slices/masterSlice";
import CompaniesModalListFilters from "components/pages/contacts/components/Modals/CompaniesModalListFilters";

const WorkInfoForm = () => {
  const { values, errors, handleChange, setFieldValue } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const isCompanyType = useSelector(isCompanyTypeState);
  const contactTypes = toSelect(useSelector(contactTypesData));
  const dispatch = useDispatch();
  const [openCompaniesListModal, setOpenCompaniesListModal] = useState(false);
  const [modalListCompanies, setModalListCompanies] = useState([]);
  const [companyFilters, setCompanyFilters] = useState({
    orderBy: "businessName",
    sortOrder: "asc",
    businessName: "",
    tradeName: "",
    cif: "",
  });
  const [paginationOptions, setPaginationOptions] = useState({});
  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "businessName",
      text: "Nom",
      sort: false,
      headerStyle: () => {
        return { width: "40%" };
      },
    },
    {
      dataField: "tradeName",
      text: "Raó social",
      sort: false,
      headerStyle: () => {
        return { width: "40%" };
      },
    },
    {
      dataField: "cif",
      text: "CIF",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];
  const onChangeCompanySearch = (values) => {
    setCompanyFilters({
      ...companyFilters,
      businessName: values.hasOwnProperty("businessName")
        ? values.businessName
        : companyFilters.businessName,
      tradeName: values.hasOwnProperty("tradeName")
        ? values.tradeName
        : companyFilters.tradeName,
      cif: values.hasOwnProperty("cif") ? values.cif : companyFilters.cif,
    });
  };

  const onResetCompaniesFilters = () =>
    setCompanyFilters({
      orderBy: "businessName",
      sortOrder: "asc",
      businessName: "",
      tradeName: "",
      cif: "",
    });

  const companiesModalListFilters = CompaniesModalListFilters({
    onChangeSearch: onChangeCompanySearch,
    filters: companyFilters,
    onReset: onResetCompaniesFilters,
  });

  useEffect(() => {
    getCompanies(companyFilters);
  }, [companyFilters]);

  useEffect(() => {
    getCompanies(companyFilters);

    setPaginationOptions({
      pageStartIndex: 1,
      firstPageText: "Primer",
      firstPageTitle: "Primera pàgina",
      prePageText: "Anterior",
      prePageTitle: "Pàgina anterior",
      nextPageText: "Següent",
      nextPageTitle: "Següent pàgina",
      lastPageText: "Últim",
      lastPageTitle: "Última pàgina",
      showTotal: true,
      sizePerPageList: [5, 10, 25],
      page: companyFilters.page,
      sizePerPage: companyFilters.sizePerPage,
      totalSize: companyFilters.totalSize,
      paginationTotalRenderer: customTotal,
    });
  }, []);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
      Mostrant {from} a {to} de {size} entrades
    </span>
  );

  const getCompanies = async (companyFilters) => {
    const data = await getFilteredCompanies(companyFilters);
    setModalListCompanies(data);
  };

  const handleContactTypeChange = (e) => {
    setFieldValue("dades.contactTypeId", e.target.value);
    dispatch(setIsCompanyType(e.target.value === COMPANY_TYPE));
  };
  
  const getCompaniesModal = () => setOpenCompaniesListModal(true);

  const handleContactDadesCompany = (value) => {
    setContactDadesCompany(value);
    setOpenCompaniesListModal(false);
  };

  const setContactDadesCompany = (company) => {
    setFieldValue("dades.company", company.tradeName);
    setFieldValue("dades.companyId", company.id);
    setFieldValue("dades.tradeName", company.tradeName);
    setFieldValue("dades.businessName", company.businessName);
  };
  return (
    <>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <InputField
            label="Càrrec"
            name="dades.position"
            placeholder="Càrrec"
            value={values.dades.position}
            error={errors.dades?.position}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <Select
            label="Tipus de contacte"
            name="dades.contactTypeId"
            value={values.dades.contactTypeId}
            error={errors.dades?.contactTypeId}
            onChange={(ev) => handleContactTypeChange(ev)}
            options={contactTypes}
            defaultOption="Tipus de contacte"
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
            required={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>

        {isCompanyType ? (
          <>
            <div className="col-sm-2 form-col">
              <InputField
                label="Empresa del contacte"
                name="dades.company"
                placeholder="Empresa"
                value={values.dades.company}
                error={errors.dades?.company}
                onChange={handleChange}
                horizontal={false}
                disabled={viewMode}
              />
            </div>
            <div className="col-sm-1 form-col"></div>
            
            {!viewMode && (
              <div className="col-sm-2 form-col">
              <button
                type="button"
                className="open-modal-btn form-col-btn"
                onClick={() => {
                  getCompaniesModal();
                }}
              >
                <i
                  to="/contact/"
                  className="uil-plus-circle form-col-btn-icon"
                />
                <span className="form-col-btn-text">Buscar empresa</span>
              </button>
            </div>
            )}

          </>
        ) : (
          <div className="col-sm-2 form-col">
            <InputField
              label="Nom comercial"
              name="dades.tradeName"
              placeholder="Nom comercial"
              value={values.dades.tradeName}
              error={errors.dades?.tradeName}
              onChange={handleChange}
              horizontal={false}
              disabled={viewMode}
            />
          </div>
        )}
      </div>
      <ListModal
        isOpen={openCompaniesListModal}
        title="BUSCAR EMPRESA"
        data={modalListCompanies}
        columns={columns}
        filterElement={companiesModalListFilters}
        paginationOptions={paginationOptions}
        noResultsFound={"No s'han trobat empreses."}
        onAccept={handleContactDadesCompany}
        onCancel={setOpenCompaniesListModal}
      />
    </>
  );
};

export default WorkInfoForm;
