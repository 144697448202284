import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';

function MultiSelectListEditableModal(props) {
  const [selectedValues, setSelectedValues] = useState([]);

  const {
    isOpen,
    onAccept,
    selectedItems,
    data,
    columns,
    noResultsFound,
    onCancel,
    size,
    title,
    filterElement,
  } = props;

  const handleRowEvents = (e, row, rowIndex) => {
    if(selectedValues && selectedValues.length > 0){
      const selectedElement = selectedValues.find((x) => x.id === row.id);
      if (!selectedElement) {
        setSelectedValues([...selectedValues, row]);
      }
    }else{
      setSelectedValues([row]);
    }
  };

  const deleteElement = (e, elem) => {
    if(selectedValues && selectedValues.length > 0){
      const selectedElement = selectedValues.find((x) => x.id === elem.id);
      if (selectedElement) {
        setSelectedValues(
          selectedValues.filter((x) => x.id !== selectedElement.id)
        );
      }
    }
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowEvents(e, row, rowIndex);
    },
  };

  useEffect(() => {
    setSelectedValues(selectedItems);
  }, [selectedItems]);  

  const rowStyle = (row, rowIndex) => {
    const style = {};
    if (selectedValues 
        && selectedValues.length > 0 
        && selectedValues.map((x) => x.id).includes(row.id)) {
      style.backgroundColor = '#FFE9E9';
    }
    return style;
  };

  const handleCellChange =  (oldValue, newValue, row, column, done) => {
    let selectedValuesTemp = [...selectedValues]
    if(selectedValues && selectedValues.length > 0){
      const selectedElement = selectedValuesTemp.find((x) => x.id === row.id);
      if (selectedElement) {
        selectedValuesTemp.find((x) => x.id === row.id)[column.dataField] = newValue
        setSelectedValues(selectedValuesTemp);
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={onCancel}
        fade={true}
        centered={true}
        size={size ?? 'lg'}
        modalTransition={{ timeout: 250 }}
      >
        <ModalHeader toggle={() => onCancel(false)}>{title}</ModalHeader>
        <ModalBody>
          <div className="modal-list-container">
            <div className="modal-list-filters">{filterElement}</div>
            <div className="table-content scrollableList">
              <BootstrapTable
                remote
                keyField="id"
                wrapperClasses="table-responsive"
                data={data}
                columns={columns}
                cellEdit={
                  cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    beforeSaveCell: handleCellChange
                  })
                }
                noDataIndication={noResultsFound}
                bordered={false}
                rowEvents={rowEvents}
                rowStyle={rowStyle}
                bootstrap4
              />
            </div>
            {selectedValues && (
              <>
                <span className="badge-label first-form-element">
                  {selectedValues.length > 0
                    ? 'Elements seleccionats: '
                    : 'No hi ha elements seleccionats.'}
                </span>
                {selectedValues.length > 0 && selectedValues.map((elem) => {
                  return (
                    <Badge key={elem.id}>
                      {elem.name}
                      &nbsp;&nbsp;&nbsp;
                      <span onClick={(e) => deleteElement(e, elem)} className="closeBadge">x</span>
                    </Badge>
                  ) 
                })}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn-form-group">
            <Button
              className="app-btn submit-btn"
              onClick={() => onAccept(selectedValues)}
            >
              Acceptar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

MultiSelectListEditableModal.propTypes = {
  title: PropTypes.string.isRequired,
  noResultsFound: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  size: PropTypes.string,
  filterElement: PropTypes.element,
  selectedItems: PropTypes.array,
};

export default MultiSelectListEditableModal;
