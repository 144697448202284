import React from "react";
import { useFormikContext } from "formik";
import Select from "components/shared/Select";
import InputField from "components/shared/InputField";
import { useSelector } from "react-redux";
import { genderTypesData, documentTypesData } from "redux/slices/masterSlice";
import { toSelect } from "helpers/utils";
import { viewModeState } from "redux/slices/contactSlice";

const PersonalInfoForm = () => {
  const { values, errors, handleChange, setFieldValue } = useFormikContext();
  const genderTypes = toSelect(useSelector(genderTypesData));
  const documentTypes = toSelect(useSelector(documentTypesData));
  const viewMode = useSelector(viewModeState);

  const handleDocumentTypeChange = async (e) => {
    const documentType = documentTypes.find(
      (x) => x.value === Number(e.target.value)
    );
    const documentTypeId = e.target.value;
    setFieldValue("dades.documentTypeId", documentTypeId);
    setFieldValue("dades.documentType", documentType.text);
  };

  return (
    <>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <InputField
            label="Nom"
            name="dades.name"
            placeholder="Nom"
            value={values.dades.name}
            error={errors.dades?.name}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            isMandatory={true}
            autofocus={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <InputField
            label="Cognom 1"
            name="dades.surname1"
            placeholder="Primer cognom"
            value={values.dades.surname1}
            error={errors.dades?.surname1}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            isMandatory={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <InputField
            label="Cognom 2"
            name="dades.surname2"
            placeholder="Segon cognom"
            value={values.dades.surname2}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
          />
        </div>
        
      </div>

      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <Select            
            label="Gènere"
            name="dades.genderType"
            value={values.dades.genderType}
            error={errors.dades?.genderType}
            onChange={handleChange}
            options={genderTypes}
            defaultOption="Seleccionar gènere"
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
            required={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <Select
            label="Tipus de document"
            name="dades.documentTypeId"
            value={values.dades.documentTypeId}
            error={errors.dades?.documentTypeId}
            onChange={(ev) => handleDocumentTypeChange(ev)}
            options={documentTypes}
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
            required={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <InputField
            label="Número de document"
            name="dades.documentNumber"
            placeholder="Número de document"
            value={values.dades.documentNumber}
            error={errors.dades?.documentNumber}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
          />
        </div>
      </div>
    </>
  );
};

export default PersonalInfoForm;
