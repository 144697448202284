
import React, {useEffect, useState} from "react";
import AlertMessage from "components/shared/AlertMessage";
import PropTypes from "prop-types";
import { isEmptyObject } from "helpers/utils";
import { useFormikContext } from "formik";

const ErrorsForm = (props) => {

  const { errorsFromServer, successMessage, onResetError } = props
  const { errors } = useFormikContext();
  const [ showFormErrors , setShowFormErrors] = useState(false)

  useEffect(() => {
    setShowFormErrors(!isEmptyObject(errors))
  }, [errors]);

  return (
    <>
      {showFormErrors && (
        <>
          <br />
          <AlertMessage
            message="Falten dades obligatòries per informar o hi ha dades incorrectes."
            isError={true}
            showAlert={true}
            neverClose={true}
          />
        </>
      )}

      {successMessage !== "" && (
        <>
          <br />
          <AlertMessage
            message={successMessage}
            isError={false}
            showAlert={true}
            handleResetErrors={onResetError}
          />
        </>
      )}

      {errorsFromServer.onSave && (
        <>
          <br />
          <AlertMessage
            message={errorsFromServer.onSave}
            isError={true}
            showAlert={true}
            handleResetErrors={onResetError}
          />
        </>
      )}
    </>
    )
}

ErrorsForm.propTypes = {
  errorsFromServer: PropTypes.object.isRequired,
  successMessage: PropTypes.object.isRequired,
  onResetError: PropTypes.func.isRequired,
};

export default ErrorsForm;