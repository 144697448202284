import React, { useEffect } from "react";
import AddressInfoForm from "components/pages/contacts/components/DataForm/components/AddressInfoForm";
import WorkInfoForm from "components/pages/contacts/components/DataForm/components/WorkInfoForm";
import OtherInfoForm from "components/pages/contacts/components/DataForm/components/OtherInfoForm";
import { useDispatch } from "react-redux";
import {
  getContactTypes,
  getDocumentTypes,
  getGenderTypes,
  getRoadTypes,
  getLocalEntitiesTypes,
  getSectorTypes

} from "redux/slices/masterSlice";
import PersonalInfoForm from "components/pages/contacts/components/DataForm/components/PersonalInfoForm";
import { getCountries, getAllCityHalls } from "redux/slices/contactSlice";

const ManageDataForm = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGenderTypes());
    dispatch(getLocalEntitiesTypes());
    dispatch(getSectorTypes());
    dispatch(getDocumentTypes());
    dispatch(getRoadTypes());
    dispatch(getCountries());
    dispatch(getContactTypes());
    dispatch(getAllCityHalls());
  }, [dispatch]);

  return (
    <>
      <PersonalInfoForm />
      <AddressInfoForm showContactData={false}/>
      <WorkInfoForm />
      <OtherInfoForm />
    </>
  );
};

export default ManageDataForm;
