import { useFormikContext } from "formik";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  newModeState,
  editModeState,
  isPendingState,
  enableViewMode,
  disableEditMode,
} from "redux/slices/contactSlice";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { useHistory } from "react-router";

const GlobalSubmitForm = (props) => {

  const { onResetForm } = props;
  const { resetForm } = useFormikContext();

  const dispatch = useDispatch();
  const newMode = useSelector(newModeState);
  const editMode = useSelector(editModeState);
  const isPending = useSelector(isPendingState);
  const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
  const history = useHistory();
  const handleOnCancelBack = () => setOpenCancelConfirmation(false);

  const handleOnAcceptBack = () => {
    setOpenCancelConfirmation(false);
    if (editMode) {
      dispatch(enableViewMode());
      dispatch(disableEditMode());
      onResetForm();
      resetForm();
    }
    if (newMode) {
      history.push("/contacts");
    }
  };
  
  return (
    <>
      {(newMode || editMode) && (
        <div className="row form-content btn-form-group">
          <button
            type="button"
            className="app-btn cancel-btn"
            disabled={isPending}
            onClick={() => {
              setOpenCancelConfirmation(true);
            }}
          >
            Cancel·lar
          </button>
          <button
            type="submit"
            disabled={isPending}
            className="app-btn submit-btn"
          >
            Guardar
          </button>
        </div>
      )}
      <ConfirmationModal
        isOpen={openCancelConfirmation}
        title="Sortir"
        message="Si cancel·la perdrà tots els canvis no desats del formulari. Està segur?"
        onAccept={handleOnAcceptBack}
        onCancel={handleOnCancelBack}
      />
    </>
  );
};

GlobalSubmitForm.propTypes = {
  onResetForm: PropTypes.func.isRequired
};

export default GlobalSubmitForm;
