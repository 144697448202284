import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "components/shared/PageTitle";
import ConfirmationModal from "components/shared/ConfirmationModal";
import { capitalizeFirstLetter, toSelect } from "helpers/utils";
import { UserForm } from "./UserForm";
import * as Yup from "yup";
import { createUser, getById, updateUser } from "api/userApi";
import { getAllProfiles } from "api/profileApi";
import { useHistory } from "react-router";

const ManageUserForm = (props) => {
  const [isPending, setIsPending] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState({
    id: "",
    userName: "",
    name: "",
    surname1: "",
    surname2: "",
    email: "",
    role: "",
    observations: "",
    created: "",
    deleted: "",
    refresh: "",
  });
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

    const [newMode, setNewMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);

    const validationSchema = Yup.object({
        userName: Yup.string().required("És obligatori introduir l' àlies."),
        name: Yup.string().required("És obligatori introduir el nom."),
        surname1: Yup.string().required(
            "És obligatori introduir el primer cognom."
        ),
        surname2: Yup.string(),
        observations: Yup.string(),
        email: Yup.string()
            .email("El format de correu és incorrecte.")
            .required("És obligatori introduir el perfil."),
        role: Yup.string().required("És obligatori introduir el perfil."),
    });

    useEffect(() => {
        getProfiles();
        if (props.match.params.id) {
            getUser(props.match.params.id);
            setViewMode(true);
        } else {
            setNewMode(true);
        }
    }, []);

  const getUser = async (id) => {
    const user = await getById(id);
    setUser(user);
  };

    const getProfiles = async () => {
        const profilesToMap = await getAllProfiles();
        setProfiles(toSelect(profilesToMap));
    };

  const handleSubmit = async (values) => {
    setIsPending(true);
    if (editMode) {
      const userToEdit = {
        deleted: values.deleted,
        email: values.email,
        id: values.id,
        name: values.name,
        observations: values.observations,
        role: values.role,
        rolename: values.rolename,
        surname1: values.surname1,
        surname2: values.surname2,
        userName: values.userName,
      };
      if (values.deleted === "") {
        userToEdit.deleted = null;
      }
      if (values.deleted && !user.deleted) {
        removeUser(userToEdit);
      } else {
        editUser(userToEdit);
      }
    } else {
      addUser(values);
    }
  };

    const addUser = async (user) => {
        await createUser(user)
            .then((data) => {
                setViewMode(true);
                setEditMode(false);
                setNewMode(false);
                setErrors({});
                setUser(data);
                setSuccessMessage(
                    `L'usuari "${user.userName}" s'ha creat correctament. Se li ha enviat un email per a que es configuri una contrasenya.`
                );
            })
            .catch((error) => {
                setErrors({ onSave: handleErrorMessages(error) });
            })
            .finally(() => setIsPending(false));
    };

    const editUser = async (user) => {
        await updateUser(user.id, user)
            .then((data) => {
                setViewMode(true);
                setEditMode(false);
                setNewMode(false);
                setErrors({});
                setUser(data);
                setSuccessMessage(
                    `L'usuari "${user.userName}" s'ha actualitzat correctament.`
                );
            })
            .catch((error) => {
                setErrors({ onSave: handleErrorMessages(error) });
            })
            .finally(() => setIsPending(false));
    };

    const removeUser = async (user) => {
        await updateUser(user.id, user)
            .then((data) => {
                setViewMode(true);
                setEditMode(false);
                setNewMode(false);
                setErrors({});
                setUser(data);
                setSuccessMessage(
                    `L'usuari "${user.userName}" s'ha donat de baixa amb data "${user.deleted}" correctament.`
                );
            })
            .catch((error) => {
                setErrors({ onSave: handleErrorMessages(error) });
            })
            .finally(() => setIsPending(false));
    };

    const handleErrorMessages = (error) => {
        if (
            error.response &&
            error.response.status === 422 &&
            error.response.data
        ) {
            return error.response.data;
        }
        return "Ha hagut un error processant la petició.";
    };

  const handleOnAcceptBack = () => {
    setOpenDeleteConfirmation(false);
    if (editMode) {
      setViewMode(true);
      setEditMode(false);
      setNewMode(false);
      setUser({ ...user, refresh: user.refresh + " " });
    }
    if (newMode) {
      history.push("/users");
    }
  };

    const handleOnCancelBack = () => setOpenDeleteConfirmation(false);

    const handleEditMode = () => {
        setEditMode(true);
        setViewMode(false);
        setSuccessMessage("");
    };

  const breadcrumbItems = [
    {
      text: "Inici",
      current: false,
      linkTo: "/",
    },
    {
      text: "Usuaris",
      current: false,
      linkTo: "/users",
    },
    {
      text: capitalizeFirstLetter(viewMode ? "USUARI DETALL" : "NOU USUARI"),
      current: true,
      linkTo: "/users/detail",
    },
  ];
  const handleResetErrors = () => {
    setErrors({});
  };

  return (
    <>
      <PageTitle
        title={viewMode ? "USUARI DETALL" : "NOU USUARI"}
        breadcrumbItems={breadcrumbItems}
      />

      <div className="row main-content">
        <div className="col new-user-subheader">
          <span>{viewMode ? "CONSULTA DE DADES" : "EDICIÓ DE DADES"}</span>
          {(viewMode || editMode) && (
            <button
              type="button"
              className="app-btn float-right edit-btn"
              disabled={isPending || editMode}
              value="Edita usuari"
              onClick={function () {
                handleEditMode();
              }}
            >
              <i className="uil uil-edit" aria-hidden="true" /> Edita usuari
            </button>
          )}
          <hr className="divider"></hr>
          <UserForm
            user={user}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            profiles={profiles}
            errors={errors}
            successMessage={successMessage}
            viewMode={viewMode}
            newMode={newMode}
            editMode={editMode}
            isPending={isPending}
            setOpenDeleteConfirmation={setOpenDeleteConfirmation}
            onResetError={handleResetErrors}
          />
        </div>
      </div>

            <ConfirmationModal
                isOpen={openDeleteConfirmation}
                title="Sortir"
                message="Si cancel·la perdrà tots els canvis no desats del formulari. Està segur?"
                onAccept={handleOnAcceptBack}
                onCancel={handleOnCancelBack}
            />
        </>
    );
};

ManageUserForm.propTypes = {
    match: PropTypes.object,
};

export default ManageUserForm;
