import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
  return (
    <div className="row">
      <div className="col">
        <div className="page-title-box">
          <h4 className="page-title">{props.title}</h4>
          <div className='page-title-right'>
            {
              props.breadcrumbItems.map((item, i) => {
                return (
                  <>
                  <Link key={i+'_link'} to={item.linkTo}>
                    <span 
                      className={item.current === true ? 'breadcrumb-current' : 'breadcrumb-elem ' }
                      key={i+'_text'}>{item.text}
                    </span>
                  </Link>
                  {
                    props.breadcrumbItems.length-1 === i
                    ? null
                    : <span key={i+'_gt'} className='breadCrumbSeparator'> &gt; </span>
                  }
                  </>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(Object)
}

export default PageTitle;
