import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

const AutoSuggestInput = (props) => {
  const {
    label,
    name,
    onChange,
    error,
    options,
    placeholder,
    disabled,
    isMandatory,
    maxResults,
  } = props;

  const [currentValue, setCurrentValue] = useState([]);

  const setSelection = (val) => {
    setCurrentValue(val);
    onChange(val[0]);
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <br />
      <Form.Group>
        <Typeahead
          id="basic-typeahead-single"
          labelKey="name"
          clearButton
          defaultSelected={options.slice(0, 1)}
          options={options}
          onChange={(val) => setSelection(val)}
          placeholder={placeholder}
          selected={currentValue}
          disabled={disabled}
          emptyLabel="No s'han trobat resultats."
          maxResults={maxResults}
          paginationText="Mostrar més resultats"
        />
      </Form.Group>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

AutoSuggestInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  maxResults: PropTypes.number,
};

export default AutoSuggestInput;
