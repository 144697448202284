// @flow
import React, { Suspense } from "react";

const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
const Topbar = React.lazy(() => import("./Topbar"));

// loading
const emptyLoading = () => <div></div>;
const loading = () => <div className="text-center"></div>;

export default function VerticalLayout(props) {
  /**
   * Opens the left menu - mobile
   */
  const openLeftMenu = () => {
    if (document.body) document.body.classList.add("sidebar-enable");
  };


  // /**
  //  * Handles the menu on window resizes
  //  */
  // const updateDimensions = () => {
  //   // activate the condensed sidebar if smaller devices like ipad or tablet
  //   if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
  //     props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
  //   } else if (window.innerWidth > 1028) {
  //     props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   props.changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);
  //   // activate the condensed sidebar if smaller devices like ipad or tablet
  //   if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
  //     props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
  //   }
  //   return () => {
  //     window.removeEventListener("resize", updateDimensions);
  //   };
  // }, []);

  // get the child view which we would like to render
  const children = props.children || null;

  return (
    <div className="app">
      <div className="wrapper">
        <Suspense fallback={emptyLoading()}>
          <LeftSidebar
            {...props}
            isCondensed={false}
            isLight={false}
            hideUserProfile={true}
          />
        </Suspense>
        
        <div className="content-page">
          <div className="content">
            <Suspense fallback={emptyLoading()}>
              <Topbar
                {...props}
                openLeftMenuCallBack={openLeftMenu}
                hideLogo={true}
              />
            </Suspense>
            <div className="container-fluid">
              <Suspense fallback={loading()}>{children}</Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
