import React from "react";
import PropTypes from "prop-types";

function TextAreaField({
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  error,
  value,
  horizontal,
  disabled,
  rows,
  isMandatory
}) {

  let inputClass = "form-control";
  if (error && error.length > 0) {
    inputClass += " is-invalid";
  }

  return horizontal ? (
    <div className="form-group row">
      <label htmlFor={name} className="col-sm-2">
        {label && <><span>{label}</span>{isMandatory && <span className='asterisc'>*</span>}</>}
      </label>
      <div className="col-sm-10">
        <textarea
          className={inputClass}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          rows={rows}
        />
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  ) : (
    <div className="form-group">
      <label htmlFor={name}>
        {label && <><span>{label}</span>{isMandatory && <span className='asterisc'>*</span>}</>}
      </label>
      <textarea
        className={inputClass}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        rows={rows}
      />
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  horizontal: PropTypes.bool,
  disabled: PropTypes.bool,
  isMandatory: PropTypes.bool,
  rows: PropTypes.string,
};

export default TextAreaField;
