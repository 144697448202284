import React from "react";
import PropTypes from "prop-types";

function RadioButton({
  label,
  name,
  onChange,
  defaultOption,
  selectedOption,
  error,
  options,
  horizontal,
  disabled,
  isMandatory,
}) {

  return horizontal ? (
    <div className="form-group row">
      <label htmlFor={name} className="col-sm-2">
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <div className="col-sm-10">
          {options.map((option) => {
            return (
              <>
                <label for={option.text}>{option.text}</label>
                <input type="radio" 
                  name={name} 
                  checked={selectedOption===option.value} 
                  onChange={onChange}
                  disabled={disabled}
                  value={option.value} 
                />
              </>
            );
          })}
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  ) : (
    <div className="form-group">
      <label htmlFor={name}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <br/>
          {
          options.map((option) => {
            return (
              <>
                <label for={option.text}>{option.text}</label>
                <input type="radio" 
                  name={name} 
                  checked={Number(selectedOption)===Number(option.value)} 
                  onChange={onChange}
                  disabled={disabled}
                  value={option.value} 
                />
              </>
            );
          })
        }  
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  selectedOption: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  horizontal: PropTypes.bool,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
