import React from "react";
import PropTypes from "prop-types";
import InputField from "components/shared/InputField";
import Select from "components/shared/Select";

const RelationsModalListFilters = props => {

    const { filters, onChangeSearch, onReset } = props

    return (
    <>
        <div className="row form-content">
            <div className="col-sm-8 form-col">
                <InputField
                    label="Nom:"
                    placeholder="Filtrar per nom"
                    value={filters.name}
                    onChange={(e) => onChangeSearch({name:e.target.value})}
                    horizontal={true}
                />
            </div>
            <div className="col-sm-1 form-col"></div>
            <div className="col-sm-3 form-col">
                <span
                    className="uil-filter-slash delete-filters-btn"
                    title="Netejar filtres"
                    onClick={onReset}
                ></span>
            </div>
        </div>
    </>
    );
      
}

RelationsModalListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired
};

export default RelationsModalListFilters;