import React from "react";
import PropTypes from "prop-types";
import InputField from "components/shared/InputField";
import Select from "components/shared/Select";

const AssociationsModalListFilters = props => {

    const { filters, onChangeSearch, onReset, areaTypes } = props

    return (
    <>
        <div className="row form-content">
            <div className="col-sm-7 form-col">
                <InputField
                    label="Nom:"
                    placeholder="Filtrar per nom"
                    value={filters.name}
                    onChange={(e) => onChangeSearch({name:e.target.value})}
                    horizontal={true}
                />
            </div>
            <div className="col-sm-1 form-col"></div>
            <div className="col-sm-4 form-col">
                <span
                    className="uil-filter-slash delete-filters-btn"
                    title="Netejar filtres"
                    onClick={onReset}
                ></span>
            </div>
        </div>
        <div className="row form-content">
            <div className="col-sm-7 form-col">
                <InputField
                    label="CIF:"
                    placeholder="Filtrar per CIF"
                    value={filters.cif}
                    onChange={(e) => onChangeSearch({cif:e.target.value})}
                    horizontal={true}
                    classNameCols={[2,10]}
                />
            </div>
            <div className="col-sm-4 form-col">
                <Select
                    label="Àrea: "
                    name="areaTypeId"
                    defaultOption="Totes"
                    value={filters.areaTypeId}
                    onChange={(e) => onChangeSearch({areaTypeId:e.target.value})}
                    options={areaTypes}
                    horizontal={true}
                />
            </div>
        </div>
    </>
    );
      
}

AssociationsModalListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    areaTypes: PropTypes.array.isRequired,
};

export default AssociationsModalListFilters;