import React, { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Route } from "react-router";
import { privateRoutes } from "./privateRoutes";
import VerticalLayout from "components/layout/VerticalLayout";
import Spinner from "components/shared/Spinner";

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <VerticalLayout>
          <Route exact path={privateRoutes.map((x) => x.path)}>
            <Switch>
              {privateRoutes.map((privateRouteProps) => {
                return (
                  <Route
                    key={privateRouteProps.key}
                    path={privateRouteProps.path}
                    component={privateRouteProps.component}
                    props={privateRouteProps.props}
                    permission={privateRouteProps.permission}
                  />
                );
              })}
            </Switch>
          </Route>
        </VerticalLayout>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
