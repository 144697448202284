import axios from "axios";
import { baseURL } from "constants/applicationConstants";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // Get token and add it to header "Authorization"
    const token = sessionStorage.getItem(
      `oidc.user:${baseURL}:CCAM.Artesans.Web`
    );
    if (token != null) {
      const tokenObject = JSON.parse(token);
      config.headers.Authorization = `Bearer ${tokenObject.access_token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
