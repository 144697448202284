import React, { useState, useEffect } from "react";
import Proptypes from 'prop-types';
import { useFormikContext } from "formik";
import InputField from "components/shared/InputField";
import { useSelector } from 'react-redux';
import { carnetTypesData } from "redux/slices/masterSlice";
import { toSelect } from "helpers/utils";
import Select from "components/shared/Select";
import SimpleTable from "components/shared/SimpleTable";
import MultiSelectListModal from "components/shared/MultiSelectListModal";
import JobsModalListFilters from "components/pages/contacts/components/Modals/JobsModalListFilters";
import { getFilteredJobs } from "api/masterEntitiesApi";
import ListModal from "components/shared/ListModal";
import { getFilteredCompanies } from "api/companyApi";
import moment from "moment";
import CompaniesModalListFilters from "components/pages/contacts/components/Modals/CompaniesModalListFilters";
import TextAreaField from "components/shared/TextAreaField";
import { 
  viewModeState, 
  editModeState
} from "redux/slices/contactSlice";

import { ARTISAN_CARNETS_ARRAY_NAME } from "constants/applicationConstants";

const CarnetForm = (props) => {
  
  const { values, errors, setFieldValue } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const editMode = useSelector(editModeState);
  const carnetTypes = toSelect(useSelector(carnetTypesData));
  
  const [openJobsListModal, setOpenJobsListModal] = useState(false);
  const [modalListJobs, setModalListJobs] = useState([]);
  const [openCompaniesListModal, setOpenCompaniesListModal] = useState(false);
  const [modalListCompanies, setModalListCompanies] = useState([]);
  const [companyFilters, setCompanyFilters] = useState({
    orderBy: "businessName",
    sortOrder: "asc",
    businessName: "",
    tradeName: "",
    cif: "",
  });
  
  const [jobFilters, setJobFilters] = useState({
    orderBy: "jobType",
    sortOrder: "asc",
    jobType: "",
    jobFamilyType: ""
  });

  const modalCompanyColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "businessName",
      text: "Nom",
      sort: false,
      headerStyle: () => {
        return { width: "40%" };
      },
    },
    {
      dataField: "tradeName",
      text: "Raó social",
      sort: false,
      headerStyle: () => {
        return { width: "40%" };
      },
    },
    {
      dataField: "cif",
      text: "CIF",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
    },
  ];

  const onChangeCompanySearch = (values) => {
    setCompanyFilters({
      ...companyFilters,
      businessName: values.hasOwnProperty("businessName")
        ? values.businessName
        : companyFilters.businessName,
      tradeName: values.hasOwnProperty("tradeName")
        ? values.tradeName
        : companyFilters.tradeName,
      cif: values.hasOwnProperty("cif") ? values.cif : companyFilters.cif,
    });
  };

  const getJobs = async (jobFilters) => {
    const data = await getFilteredJobs(jobFilters);
    setModalListJobs(data);
  };

  useEffect(() => {
    getJobs();
  }, [])

  useEffect(() => {
    getCompanies(companyFilters);
  }, [companyFilters]);

  useEffect(() => {
    getJobs(jobFilters);
  }, [jobFilters]);

  const getJobsModal = () => setOpenJobsListModal(true);

  const onResetCompaniesFilters = () =>
    setCompanyFilters({
      orderBy: "businessName",
      sortOrder: "asc",
      businessName: "",
      tradeName: "",
      cif: "",
    });

  const getCompanies = async (companyFilters) => {
    const data = await getFilteredCompanies(companyFilters);
    setModalListCompanies(data);
  };

  const handleSelectedJobs = (values) => {
    setFieldValue(getName()+".jobTypes", values);
    setOpenJobsListModal(false);
  };

  const onChangeJobSearch = (values) => {
    setJobFilters({
      ...jobFilters,
      jobType: values.hasOwnProperty("jobType")
        ? values.jobType
        : jobFilters.jobType,
        jobFamilyType: values.hasOwnProperty("jobFamilyType")
        ? values.jobFamilyType
        : jobFilters.jobFamilyType
    });
  };

  const jobsModalListFilters = JobsModalListFilters({
    onChangeSearch: onChangeJobSearch,
    filters: jobFilters
  });  
  
  const columnsModal = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "jobType",
      text: "Ofici",
      sort: false,
      headerStyle: () => {
        return { width: "50%" };
      },
    },
    {
      dataField: "jobFamilyType",
      text: "Família de l'ofici",
      sort: false,
      headerStyle: () => {
        return { width: "50%" };
      },
    }
  ];

  const formatFamilyTypeCol = (cell,row) => {
    return(
      <>
        <span>{"Família " + row.jobFamilyType}</span>
      </>
    )
  }

  const columnsTable = [
    {
      dataField: 'jobType',
      text: 'Oficis assignats',
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
    },
    {
      dataField: 'jobFamilyType',
      text: '',
      sort: false,
      headerStyle: () => {
        return { width: '50%' };
      },
      formatter: formatFamilyTypeCol,
    },
  ];

  const getCompaniesModal = () => setOpenCompaniesListModal(true);

  const companiesModalListFilters = CompaniesModalListFilters({
    onChangeSearch: onChangeCompanySearch,
    filters: companyFilters,
    onReset: onResetCompaniesFilters,
  });

  const handleContactDadesCompany = (value) => {
    setContactDadesCompany(value);
    setOpenCompaniesListModal(false);
  };

  const setContactDadesCompany = (company) => {
    setFieldValue(getName()+".company", company.tradeName);
    setFieldValue(getName()+".companyId", company.id);
  };

  const getCarnetsCopy = () => [...values.area.artisan.contactAreaArtisanCarnets]

  const getErrorsCopy = () => [errors.area?.artisan?.contactAreaArtisanCarnets]

  const getIndex = () =>  getCarnetsCopy().map(x => x.id).indexOf(props.data.id)

  const getName = () => ARTISAN_CARNETS_ARRAY_NAME+"[" + getIndex().toString() + "]"

  const deleteCarnet = () => {
    let carnetErrors = getErrorsCopy()[0]
    let contactAreaArtisanCarnets = getCarnetsCopy()

    let index = contactAreaArtisanCarnets.map(x => x.id).indexOf(props.data.id);
    contactAreaArtisanCarnets.splice(index, 1);

    if(carnetErrors && carnetErrors.length>0)
      carnetErrors.splice(index, 1);

    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, contactAreaArtisanCarnets);
  }

  const handleCarnetTypeChange = (value) => {
    let carnets = getCarnetsCopy()
    carnets.filter(x => x.id === props.data.id)[0].artisanContactCarnetTypeId = value;
    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, carnets);
  }
  
  const handleCarnetNumberChange = (value) => {
    let carnets = getCarnetsCopy()
    carnets.filter(x => x.id === props.data.id)[0].number = value;
    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, carnets);
  }

  const handleCarnetSubscriptedChange = (value) => {
    let carnets = getCarnetsCopy()
    carnets.filter(x => x.id === props.data.id)[0].subscripted = value;
    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, carnets);
  }

  const handleCarnetUnSubscriptedChange = (value) => {
    let carnets = getCarnetsCopy()
    carnets.filter(x => x.id === props.data.id)[0].unSubscripted = value;
    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, carnets);
  }
  
  const handleCarnetObservationsChange = (value) => {
    let carnets = getCarnetsCopy()
    carnets.filter(x => x.id === props.data.id)[0].observations = value;
    setFieldValue(ARTISAN_CARNETS_ARRAY_NAME, carnets);
  }
  
  return (
    <>
      <div className="carnet-card">
        <div className="row form-content no-margin-top">
          <div className="col-sm-11 form-col no-margin-top"></div>
          <div className="col-sm-1 form-col no-margin-top">
            {
              editMode 
              ?
              <i 
                onClick={() => deleteCarnet()} 
                className="uil-trash form-col-btn-icon delete-button" 
                title="Eliminar carnet"
              />
              : <i className="uil-atm-card form-col-btn-icon delete-button" />
            }
          </div>
        </div>
        <div className="row form-content minus-50px-margin-top">
        <div className="col-sm-3 form-col">
          <Select
            label="Modalitat"
            name={getName()+".artisanContactCarnetTypeId"}
            defaultOption="Seleccionar modalitat"
            value={props.data.artisanContactCarnetTypeId}
            error={errors?.area?.artisan?.contactAreaArtisanCarnets 
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()]
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()].artisanContactCarnetTypeId}
            onChange={(ev) => handleCarnetTypeChange(ev.target.value)}
            options={carnetTypes}
            horizontal={false}
            disabled={viewMode}
            isMandatory={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col table-content simple-table scrollableList no-margin-left no-margin-right">
            <SimpleTable
              columns={columnsTable}
              data={props.data.jobTypes}
              noResultsFound={"Cap ofici assignat."}
            />
            <div className="table-error-msg">
            {
              errors?.area?.artisan?.contactAreaArtisanCarnets  
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()]
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()].jobTypes
            }
            </div>  
        </div>
        <div className="col-sm-1 form-col"></div>
        {editMode && (
          <div className="col-sm-2 form-col no-margin-left">
            <button
              type="button"
              className="open-modal-btn form-col-btn"
              onClick={() => getJobsModal()}
            >
              <i className="uil-plus-circle form-col-btn-icon" />
              <span className="form-col-btn-text">Seleccionar oficis</span>
            </button>
          </div>
          )
        }
      </div>
      <div className="row form-content no-margin-top">
        <div className="col-sm-3 form-col">
          <InputField
            label="Número de carnet"
            name={getName()+".number"}
            placeholder="Número de carnet"
            value={props.data.number}
            error={errors?.area?.artisan?.contactAreaArtisanCarnets 
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()]
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()].number}
            onChange={(ev) => handleCarnetNumberChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <InputField
            label="Taller del carnet"
            name={getName()+".company"}
            value={props.data.company}
            horizontal={false}
            disabled={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        {!viewMode && (
          <div className="col-sm-3 form-col">
          <button
            type="button"
            className="open-modal-btn form-col-btn"
            onClick={() => {
              getCompaniesModal();
            }}
          >
            <i className="uil-plus-circle form-col-btn-icon" />
            <span className="form-col-btn-text">Seleccionar taller</span>
          </button>
        </div>
        )}
      </div>
      <div className="row form-content">
        <div className="col-sm-3 form-col">
          <InputField
            label="Data de concessió"
            name={getName()+".subscripted"}
            value={
              props.data.subscripted
              ? moment(props.data.subscripted).format("YYYY-MM-DD")
              : ""}
            error={errors?.area?.artisan?.contactAreaArtisanCarnets 
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()]
              && errors?.area?.artisan?.contactAreaArtisanCarnets[getIndex()].subscripted}
            onChange={(ev) => handleCarnetSubscriptedChange(ev.target.value)}
            horizontal={false}
            disabled={viewMode}
            isMandatory={true}
            type="date"
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <InputField
            label="Data de baixa"
            name={getName()+".unSubscripted"}
            value={
              props.data.unSubscripted
              ? moment(props.data.unSubscripted).format("YYYY-MM-DD")
              : ""}
            error={errors.dades?.unSubscripted}
            onChange={(ev) => handleCarnetUnSubscriptedChange(ev.target.value)}
            horizontal={false}
            disabled={viewMode}
            type="date"
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-4 form-col">
          <TextAreaField
            label="Observacions"
            placeholder="Introduir comentaris"
            name={getName()+".observations"}
            value={props.data.observations}
            error={errors.dades?.observations}
            onChange={(ev) => handleCarnetObservationsChange(ev.target.value)}
            horizontal={false}
            disabled={viewMode}
            rows="4"
          />
        </div>
      </div>
    </div>
    
    <MultiSelectListModal
      isOpen={openJobsListModal}
      title="SELECCIONAR OFICIS"
      data={modalListJobs}
      columns={columnsModal}
      filterElement={jobsModalListFilters}
      noResultsFound={"No s'han trobat oficis."}
      onAccept={handleSelectedJobs}
      onCancel={setOpenJobsListModal}
      selectedItems={props.data.jobTypes}
    />
    <ListModal
        isOpen={openCompaniesListModal}
        title="BUSCAR EMPRESA"
        data={modalListCompanies}
        columns={modalCompanyColumns}
        filterElement={companiesModalListFilters}
        noResultsFound={"No s'han trobat empreses."}
        onAccept={handleContactDadesCompany}
        onCancel={setOpenCompaniesListModal}
      />
    </>
  );
};

CarnetForm.propTypes = {
  data: Proptypes.object
};

export default CarnetForm;
