import React from "react";
import InputField from "components/shared/InputField";
import {isCompanyTypeState} from "redux/slices/contactSlice";
import { useSelector } from "react-redux";

const FormHeader = (props) => {

  const isCompanyType = useSelector(isCompanyTypeState);

  const dateFormat = (date) => {
    
    // var dateParts = date.toString().split("T");
    // var dateParts2 = dateParts[1].toString().split(".")
    // return dateParts[0] + " " + dateParts2[0];
    return new Date(date).toLocaleString();
  }

  const {
    contact,
    isNewMode
  } = props;

  const headerValues = contact
  const isNewContact = isNewMode


  return (
    <>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <InputField
            label="Nom"
            name="dades.name"
            value={headerValues.dades.name}
            horizontal={false}
            disabled={true}
            autofocus={true}
          />
        </div>
        {/* <div className="col-sm-1 form-col"></div> */}
        <div className="col-sm-2 form-col">
          <InputField
            label="Cognom"
            name="dades.surname1"
            value={headerValues.dades.surname1}
            horizontal={false}
            disabled={true}
            
          />
        </div>
        {/* <div className="col-sm-1 form-col"></div> */}

        { isCompanyType ? <div className="col-sm-2 form-col">
          <InputField
            label="Empresa del contacte"
            name="dades.company"
            value={headerValues.dades.company}
            horizontal={false}
            disabled={true}
          />
        </div> :
        <div className="col-sm-4 form-col">
        <InputField
          label="Nom comercial"
          name="dades.company"
          value={headerValues.dades.tradeName}
          horizontal={false}
          disabled={true}
        />
      </div>
        
        }
        {/* <div className="col-sm-1 form-col"></div> */}
        <div className="col-sm-2 form-col">
          <InputField
            label="Número de document"
            name="dades.documentNumber"
            value={headerValues.dades.documentNumber}
            horizontal={false}
            disabled={true}
          />
        </div>
      
      { isNewContact ?
        <>
        </>
       :
      //  <div className="row form-content">
        <div className="col-sm-2 form-col">
          <InputField
            label="Última actualització"
            name="dades.modified"
            value={dateFormat(headerValues.dades.modified)}
            horizontal={false}
            disabled={true}
          />
        </div>
      // </div>
      }
      </div>
    </>
  );
};


export default FormHeader;
