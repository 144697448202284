import Routes from "components/routes/Routes";
import { useAuth } from "oidc-react";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setUserProfile } from "redux/slices/userSlice";

const AppAuth = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (auth?.userData?.access_token) {
      dispatch(setUserProfile(auth.userData.profile));
    }
  }, [auth?.userData?.access_token, dispatch, auth?.userData?.profile]);

  useEffect(() => {
    if (auth?.userData && window.location.href.includes("code=")) {
      history.replace("/");
    }
  }, [auth?.userData, history, auth?.userData?.profile.email_verified]);

  if (auth?.userData) {
    return <Routes />;
  }
  return <Spinner />;
};

export default AppAuth;
