import { handleResponse, handleError, format } from "./utilApi";
import axiosInstance from "../helpers/axiosInstance";

const baseUserURL = "/api/address/";

export async function getById(id) {
  return axiosInstance
    .get(baseUserURL + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAddressFromCP(CP) {
  return axiosInstance
    .get(baseUserURL + CP)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAddressFromCPWithDepartment(CP) {
  return axiosInstance
      .get(baseUserURL +"department/" + CP)
    .then(handleResponse)
    .catch(handleError);
}

export async function getCitiesByProvince(provinceId) {
  const extension = "cities";
  return axiosInstance
    .get(`${baseUserURL + extension}?provinceId=${format(provinceId)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getComarquesByProvince(provinceId) {
    const extension = "comarques";
    return axiosInstance
        .get(`${baseUserURL + extension}?provinceId=${format(provinceId)}`)
        .then(handleResponse)
        .catch(handleError);
}

export async function getPostalCodesByCity(cityId) {
  const extension = "postalCodes";
  return axiosInstance
    .get(`${baseUserURL + extension}?cityId=${format(cityId)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllProvinces() {
  const extension = "provinces";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllCityHalls() {
  const extension = "cityHalls";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredContacts(filters) {
  const extension = "filter";
  return axiosInstance
    .get(
      `${baseUserURL + extension}?search=${format(
        filters.search
      )}&roleId=${format(filters.roleId)}&page=${format(
        filters.page
      )}&sizePerPage=${format(filters.sizePerPage)}&sortOrder=${format(
        filters.sortOrder
      )}&orderBy=${format(filters.orderBy)}`
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function createContact(contact) {
  return axiosInstance
    .post(baseUserURL, contact)
    .then(handleResponse)
    .catch(handleError);
}

export async function updateContact(id, contact) {
  return axiosInstance
    .put(baseUserURL + id, contact)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllCountries() {
  const extension = "countries";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}
