import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as apiMasterEntities from "api/masterEntitiesApi";

export const getGenderTypes = createAsyncThunk(
  "masters/genderTypes",
  async () => {
    const response = await apiMasterEntities.gendersMaster();
    return response;
  }
);

export const getWorkspaces = createAsyncThunk(
  "masters/workspaces",
  async () => {
    const response = await apiMasterEntities.workspacesMaster();

    return response;
  }
);

export const getMarketingTypes = createAsyncThunk(
  "masters/marketingType",
  async () => {
    const response = await apiMasterEntities.marketingTypesMaster();
    return response;
  }
);

export const getProfessionalStatus = createAsyncThunk(
  "masters/professionalStatus",
  async () => {
    const response = await apiMasterEntities.professionalStatusMaster();
    return response;
  }
);

export const getProfessionalActivity = createAsyncThunk(
  "masters/professionalActivity",
  async () => {
    const response = await apiMasterEntities.professionalActivityMaster();
    return response;
  }
);

export const getStatusType = createAsyncThunk(
  "masters/statusType",
  async () => {
    const response = await apiMasterEntities.statusTypeMaster();
    return response;
  }
);

export const getCommitteeType = createAsyncThunk(
  "masters/committeeType",
  async () => {
    const response = await apiMasterEntities.committeeTypeMaster();
    return response;
  }
);

export const getValidity = createAsyncThunk(
  "masters/validity",
  async () => {
    const response = await apiMasterEntities.validityMaster();
    return response;
  }
);

export const getLocalEntitiesTypes = createAsyncThunk(
  "masters/localEntitiesTypes",
  async () => {
    const response = await apiMasterEntities.localEntitiesMaster();
    return response;
  }
);

export const getSectorTypes = createAsyncThunk(
  "masters/sectorTypes",
  async () => {
    const response = await apiMasterEntities.sectorsMaster();
    return response;
  }
);

export const getDocumentTypes = createAsyncThunk(
  "masters/documentTypes",
  async () => {
    const response = await apiMasterEntities.documentsMaster();
    return response;
  }
);

export const getRoadTypes = createAsyncThunk(
  "masters/roadTypes", async () => {
  const response = await apiMasterEntities.roadsMaster();
  return response;
});

export const getFormations = createAsyncThunk(
  "masters/formations", async () => {
  const response = await apiMasterEntities.formationsMaster();
  return response;
});

export const getSchools = createAsyncThunk(
  "masters/schools", async () => {
  const response = await apiMasterEntities.schoolsMaster();
  return response;
});

export const getContactTypes = createAsyncThunk(
  "masters/contactTypes",
  async () => {
    const response = await apiMasterEntities.contactsMaster();
    return response;
  }
);

export const getCarnetTypes = createAsyncThunk(
  "masters/carnetTypes",
  async () => {
    const response = await apiMasterEntities.carnetTypesMaster();
    return response;
  }
);

export const getAreasTypes = createAsyncThunk(
  "masters/areasTypes",
  async () => {
    const response = await apiMasterEntities.areasTypesMaster();
    return response;
  }
);

export const getJobs = createAsyncThunk(
  "masters/jobs",
  async () => {
    const response = await apiMasterEntities.getAllJobs();
    return response;
  }
);

export const getRelations = createAsyncThunk(
  "masters/relations",
  async () => {
    const response = await apiMasterEntities.getAllRelations();
    return response;
  }
);

export const getAssociations = createAsyncThunk(
  "masters/associations",
  async () => {
    const response = await apiMasterEntities.getAllAssociations();
    return response;
  }
);

export const getAssociationTypes = createAsyncThunk(
    "masters/associationEntityTypes",
    async () => {
        const response = await apiMasterEntities.getAllAssociationTypes();
        return response;
    }
);

const masterSlice = createSlice({
  name: "master",
  initialState: {
    genderTypes: { data: [], status: "idle", error: null },
    localEntitiesTypes: { data: [], status: "idle", error: null },
    sectorTypes: { data: [], status: "idle", error: null },
    documentTypes: { data: [], status: "idle", error: null },
    roadTypes: { data: [], status: "idle", error: null },
    contactTypes: { data: [], status: "idle", error: null },
    carnetTypes: { data: [], status: "idle", error: null },
    formations: { data: [], status: "idle", error: null },
    schools: { data: [], status: "idle", error: null },
    areasTypes: { data: [], status: "idle", error: null },
    jobs: { data: [], status: "idle", error: null },
    relations: { data: [], status: "idle", error: null },
    associations: { data: [], status: "idle", error: null },
    associationTypes: { data: [], status: "idle", error: null },
    workspaces: { data: [], status: "idle", error: null },
    marketingTypes: { data: [], status: "idle", error: null },
    professionalStatus: { data: [], status: "idle", error: null },
    professionalActivity: { data: [], status: "idle", error: null },
    statusType: { data: [], status: "idle", error: null },
    committeeType: { data: [], status: "idle", error: null },
    validity: { data: [], status: "idle", error: null }
  },
  reducers: {},
  extraReducers: {
    [getGenderTypes.pending]: (state) => {
      state.genderTypes.status = "loading";
    },
    [getGenderTypes.fulfilled]: (state, { payload }) => {
      state.genderTypes.status = "success";
      state.genderTypes.data = payload;
    },
    [getGenderTypes.rejected]: (state, { error }) => {
      state.genderTypes.status = "failed";
      state.genderTypes.error = error.message;
    },
    [getLocalEntitiesTypes.pending]: (state) => {
      state.localEntitiesTypes.status = "loading";
    },
    [getLocalEntitiesTypes.fulfilled]: (state, { payload }) => {
      state.localEntitiesTypes.status = "success";
      state.localEntitiesTypes.data = payload;
    },
    [getLocalEntitiesTypes.rejected]: (state, { error }) => {
      state.localEntitiesTypes.status = "failed";
      state.localEntitiesTypes.error = error.message;
    },
    [getSectorTypes.pending]: (state) => {
      state.sectorTypes.status = "loading";
    },
    [getSectorTypes.fulfilled]: (state, { payload }) => {
      state.sectorTypes.status = "success";
      state.sectorTypes.data = payload;
    },
    [getSectorTypes.rejected]: (state, { error }) => {
      state.sectorTypes.status = "failed";
      state.sectorTypes.error = error.message;
    },
    [getDocumentTypes.pending]: (state) => {
      state.documentTypes.status = "loading";
    },
    [getDocumentTypes.fulfilled]: (state, { payload }) => {
      state.documentTypes.status = "success";
      state.documentTypes.data = payload;
    },
    [getDocumentTypes.rejected]: (state, { error }) => {
      state.documentTypes.status = "failed";
      state.documentTypes.error = error.message;
    },
    [getRoadTypes.pending]: (state) => {
      state.roadTypes.status = "loading";
    },
    [getRoadTypes.fulfilled]: (state, { payload }) => {
      state.roadTypes.status = "success";
      state.roadTypes.data = payload;
    },
    [getRoadTypes.rejected]: (state, { error }) => {
      state.roadTypes.status = "failed";
      state.roadTypes.error = error.message;
    },
    [getContactTypes.pending]: (state) => {
      state.contactTypes.status = "loading";
    },
    [getContactTypes.fulfilled]: (state, { payload }) => {
      state.contactTypes.status = "success";
      state.contactTypes.data = payload;
    },
    [getContactTypes.rejected]: (state, { error }) => {
      state.contactTypes.status = "failed";
      state.contactTypes.error = error.message;
    },
    [getCarnetTypes.pending]: (state) => {
      state.carnetTypes.status = "loading";
    },
    [getCarnetTypes.fulfilled]: (state, { payload }) => {
      state.carnetTypes.status = "success";
      state.carnetTypes.data = payload;
    },
    [getCarnetTypes.rejected]: (state, { error }) => {
      state.carnetTypes.status = "failed";
      state.carnetTypes.error = error.message;
    },
    [getFormations.pending]: (state) => {
      state.formations.status = "loading";
    },
    [getFormations.fulfilled]: (state, { payload }) => {
      state.formations.status = "success";
      state.formations.data = payload;
    },
    [getFormations.rejected]: (state, { error }) => {
      state.formations.status = "failed";
      state.formations.error = error.message;
    },
    [getSchools.pending]: (state) => {
      state.schools.status = "loading";
    },
    [getSchools.fulfilled]: (state, { payload }) => {
      state.schools.status = "success";
      state.schools.data = payload;
    },
    [getSchools.rejected]: (state, { error }) => {
      state.schools.status = "failed";
      state.schools.error = error.message;
    },
    [getAreasTypes.pending]: (state) => {
      state.areasTypes.status = "loading";
    },
    [getAreasTypes.fulfilled]: (state, { payload }) => {
      state.areasTypes.status = "success";
      state.areasTypes.data = payload;
    },
    [getAreasTypes.rejected]: (state, { error }) => {
      state.areasTypes.status = "failed";
      state.areasTypes.error = error.message;
    },
    [getJobs.pending]: (state) => {
      state.jobs.status = "loading";
    },
    [getJobs.fulfilled]: (state, { payload }) => {
      state.jobs.status = "success";
      state.jobs.data = payload;
    },
    [getJobs.rejected]: (state, { error }) => {
      state.jobs.status = "failed";
      state.jobs.error = error.message;
    },
    [getRelations.pending]: (state) => {
      state.relations.status = "loading";
    },
    [getRelations.fulfilled]: (state, { payload }) => {
      state.relations.status = "success";
      state.relations.data = payload;
    },
    [getRelations.rejected]: (state, { error }) => {
      state.relations.status = "failed";
      state.relations.error = error.message;
    },
    [getAssociations.pending]: (state) => {
      state.associations.status = "loading";
    },
    [getAssociations.fulfilled]: (state, { payload }) => {
      state.associations.status = "success";
      state.associations.data = payload;
    },
    [getAssociations.rejected]: (state, { error }) => {
      state.associations.status = "failed";
      state.associations.error = error.message;
    },
    [getAssociationTypes.pending]: (state) => {
        state.associationTypes.status = "loading";
    },
    [getAssociationTypes.fulfilled]: (state, { payload }) => {
        state.associationTypes.status = "success";
        state.associationTypes.data = payload;
    },
    [getAssociationTypes.rejected]: (state, { error }) => {
        state.associationTypes.status = "failed";
        state.associationTypes.error = error.message;
    },
    [getWorkspaces.pending]: (state) => {
      state.workspaces.status = "loading";
    },
    [getWorkspaces.fulfilled]: (state, { payload }) => {
      state.workspaces.status = "success";
      state.workspaces.data = payload;
    },
    [getWorkspaces.rejected]: (state, { error }) => {
      state.workspaces.status = "failed";
      state.workspaces.error = error.message;
    },
    [getMarketingTypes.pending]: (state) => {
      state.marketingTypes.status = "loading";
    },
    [getMarketingTypes.fulfilled]: (state, { payload }) => {
      state.marketingTypes.status = "success";
      state.marketingTypes.data = payload;
    },
    [getMarketingTypes.rejected]: (state, { error }) => {
      state.marketingTypes.status = "failed";
      state.marketingTypes.error = error.message;
    },
    [getProfessionalStatus.pending]: (state) => {
      state.professionalStatus.status = "loading";
    },
    [getProfessionalStatus.fulfilled]: (state, { payload }) => {
      state.professionalStatus.status = "success";
      state.professionalStatus.data = payload;
    },
    [getProfessionalStatus.rejected]: (state, { error }) => {
      state.professionalStatus.status = "failed";
      state.professionalStatus.error = error.message;
    },
    [getProfessionalActivity.pending]: (state) => {
      state.professionalActivity.status = "loading";
    },
    [getProfessionalActivity.fulfilled]: (state, { payload }) => {
      state.professionalActivity.status = "success";
      state.professionalActivity.data = payload;
    },
    [getProfessionalActivity.rejected]: (state, { error }) => {
      state.professionalActivity.status = "failed";
      state.professionalActivity.error = error.message;
    },
    [getStatusType.pending]: (state) => {
      state.statusType.status = "loading";
    },
    [getStatusType.fulfilled]: (state, { payload }) => {
      state.statusType.status = "success";
      state.statusType.data = payload;
    },
    [getStatusType.rejected]: (state, { error }) => {
      state.statusType.status = "failed";
      state.statusType.error = error.message;
    },
    [getCommitteeType.pending]: (state) => {
      state.committeeType.status = "loading";
    },
    [getCommitteeType.fulfilled]: (state, { payload }) => {
      state.committeeType.status = "success";
      state.committeeType.data = payload;
    },
    [getCommitteeType.rejected]: (state, { error }) => {
      state.committeeType.status = "failed";
      state.committeeType.error = error.message;
    },
    [getValidity.pending]: (state) => {
      state.validity.status = "loading";
    },
    [getValidity.fulfilled]: (state, { payload }) => {
      state.validity.status = "success";
      state.validity.data = payload;
    },
    [getValidity.rejected]: (state, { error }) => {
      state.validity.status = "failed";
      state.validity.error = error.message;
    }
  },
});

export const genderTypesData = (state) => state.master.genderTypes.data;
export const localEntitiesTypesData = (state) => state.master.localEntitiesTypes.data;
export const sectorTypesData = (state) => state.master.sectorTypes.data;
export const documentTypesData = (state) => state.master.documentTypes.data;
export const roadTypesData = (state) => state.master.roadTypes.data;
export const contactTypesData = (state) => state.master.contactTypes.data;
export const carnetTypesData = (state) => state.master.carnetTypes.data;
export const formationsData = (state) => state.master.formations.data;
export const schoolsData = (state) => state.master.schools.data;
export const areasTypesData = (state) => state.master.areasTypes.data;
export const jobsData = (state) => state.master.jobs.data;
export const relationsData = (state) => state.master.relations.data;
export const associationsData = (state) => state.master.associations.data;
export const associationTypesData = (state) => state.master.associationTypes.data;
export const workspacesData = (state) => state.master.workspaces.data;
export const marketingTypeData = (state) => state.master.marketingTypes.data;
export const professionStatusData = (state) => state.master.professionalStatus.data;
export const professionalActivityData = (state) => state.master.professionalActivity.data;
export const statusTypeData = (state) => state.master.statusType.data;
export const committeeTypeData = (state) => state.master.committeeType.data;
export const validityData = (state) => state.master.validity.data;

export default masterSlice.reducer;
