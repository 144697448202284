import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import ManageDataForm from "./DataForm/ManageDataForm";
import ManageAreas from "./AreaForm/ManageAreas";
import AssociationForm from "./AssociationForm/AssociationForm";
import RelationForm from "./RelationForm/RelationForm";
import ErrorsForm from "./ErrorsForm";
import GlobalSubmitForm from "./GlobalSubmitForm";
import { Roles } from "constants/Roles";
import CanAccessContent from "components/shared/CanAccessContent";

export const ContactForm = (props) => {
  const {
    validationSchema,
    onSubmit,
    successMessage,
    errorsFromServer,
    onResetError,
    contact,
    currentContactTab,
    onResetForm,
  } = props;

  return (
    <>
      <Formik
        initialValues={contact}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        <Form>
          <ErrorsForm
            errorsFromServer={errorsFromServer}
            onResetError={onResetError}
            successMessage={successMessage}
          />
          {currentContactTab === 1 ? (
            <ManageDataForm />
          ) : currentContactTab === 2 ? (
            <ManageAreas />
          ) : currentContactTab === 3 ? (
            <AssociationForm />
          ) : currentContactTab === 4 ? (
            <RelationForm />
          ) : (
            <></>
          )}
          <CanAccessContent roles={[Roles.ADMIN, Roles.EDITOR]}>
            <GlobalSubmitForm onResetForm={onResetForm} />
          </CanAccessContent>
        </Form>
      </Formik>
    </>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorsFromServer: PropTypes.object.isRequired,
  successMessage: PropTypes.string,
  currentContactTab: PropTypes.number.isRequired,
  onResetError: PropTypes.func.isRequired,
  onResetForm: PropTypes.func.isRequired,
};
