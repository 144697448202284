import React from "react";
import Proptypes from 'prop-types';
import { useFormikContext } from "formik";
import { toSelect } from "helpers/utils";
import { useSelector } from 'react-redux';
import TextAreaField from "components/shared/TextAreaField";
import Select from "components/shared/Select";
import { viewModeState, editModeState } from "redux/slices/contactSlice";
import { workspacesData, marketingTypeData, professionStatusData } from "redux/slices/masterSlice";
import { ARTISAN_YOUNGTALENT_WORKSPACE } from "constants/applicationConstants";
import AddressInfoForm from "../../../DataForm/components/AddressInfoForm";

const WorkspaceForm = (props) => {

  const { values, errors, setFieldValue, handleChange } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const editMode = useSelector(editModeState);
  const workspace = toSelect(useSelector(workspacesData));
  const marketingTypes = toSelect(useSelector(marketingTypeData));
  const professionalStatus = toSelect(useSelector(professionStatusData));

  const getIndex = () =>  getWorkspacesCopy().map(x => x.id).indexOf(props.data.id)

  const getName = () => ARTISAN_YOUNGTALENT_WORKSPACE +"[" + getIndex().toString() + "]"

  const getWorkspacesCopy = () => [...values.area.artisan.contactAreaYoungTalent?.workspaces]

  const getWorkspaceErrorsCopy = () => [errors.area?.artisan?.contactAreaYoungTalent?.workspaces]

  const deleteWorkspace = () => {
    let workspaceErrors = getWorkspaceErrorsCopy()[0]
    let workspace = getWorkspacesCopy()
    let index = workspace.map(x => x.id).indexOf(props.data.id);
    
    if(workspaceErrors && workspaceErrors.length > 0)
    workspaceErrors.splice(index, 1);
    workspace.splice(index, 1);

    setFieldValue(ARTISAN_YOUNGTALENT_WORKSPACE, workspace);
  }

  const handleWorkspaceChange = (value) => {
    let workspace = getWorkspacesCopy()

    workspace.filter(x => x.id === props.data.id)[0].workspaceId = value;

    setFieldValue(ARTISAN_YOUNGTALENT_WORKSPACE, workspace);
  }
  
  const handleMarketingTypeChange = async (value) => {
    let workspace = getWorkspacesCopy()

    workspace.filter(x => x.id === props.data.id)[0].marketingTypeId = value;

    setFieldValue(ARTISAN_YOUNGTALENT_WORKSPACE, workspace);  
  }

  const handleProfessionalStatusChange = async (value) => {
    let workspace = getWorkspacesCopy()
    
    workspace.filter(x => x.id === props.data.id)[0].professionalStatusId = value;

    setFieldValue(ARTISAN_YOUNGTALENT_WORKSPACE, workspace);  
  }

  const handlePrizesChanges = async (value) => {
    let workspace = getWorkspacesCopy()
    
    workspace.filter(x => x.id === props.data.id)[0].prizes = value;

    setFieldValue(ARTISAN_YOUNGTALENT_WORKSPACE, workspace);  
  }

  return (
    <>
    <div className="carnet-card margin-top-24px">
      <div className="row form-content no-margin-top">
      <div className="col-sm-11 form-col no-margin-top"></div>
      <div className="col-sm-1 form-col no-margin-top">
        {
          editMode 
          ?
          <i 
            onClick={() => deleteWorkspace()} 
            className="uil-trash form-col-btn-icon delete-button" 
            title="Eliminar Espai de treball"
          />
          : <i className="uil-award form-col-btn-icon delete-button" />
        }
      </div>
      </div>
      <div className="row form-content minus-50px-margin-top">
        <div className="col-sm-3 form-col">
          <Select
            label="Espai de treball"
            name={getName()+"workspaces.workspaceId"}
            defaultOption="Selecciona espai de treball"
            value={props.data.workspaceId}
            options={workspace}
            error={
              errors?.area?.artisan?.contactAreaYoungTalent?.workspaces
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()]
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()].workspacesId
            }
            onChange={(ev) => handleWorkspaceChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <Select
            label="Tipus de comercialització"
            name={getName()+".workspaces.marketingTypeId"}
            value={props.data.marketingTypeId}
            options={marketingTypes}
            defaultOption="Seleccionar tipus de comercialització"
            error={
              errors?.area?.artisan?.contactAreaYoungTalent?.workspaces  
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()]
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()].marketingType?.id
            }
            onChange={(ev) => handleMarketingTypeChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <Select
            label="Estat professional"
            name={getName()+".workspaces.professionalStatusId"}
            value={props.data.professionalStatusId}
            options={professionalStatus}
            defaultOption="Seleccionar tipus de comercialització"
            error={
              errors?.area?.artisan?.contactAreaYoungTalent?.workspaces  
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()]
              && errors?.area?.artisan?.contactAreaYoungTalent?.workspaces[getIndex()].professionalStatus?.id
            }
            onChange={(ev) => handleProfessionalStatusChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
      </div>
      <div className="row form-content">
        <div className="col-sm-5 form-col">
        <TextAreaField
            label="Premis/Mencions/Exposicions"
            name={getName()+".workspaces.prizes"}
            placeholder="Premis/Mencions/Exposicions"
            value={props.data.prizes}
            rows="4"
            onChange={(ev) => handlePrizesChanges(ev.target.value)}
            disabled={viewMode}
          />
        </div>
      </div>
      <AddressInfoForm showContactData={true} />
    </div>
    </>
  );
};

WorkspaceForm.propTypes = {
  data: Proptypes.object
};

export default WorkspaceForm;