import { handleResponse, handleError, format } from "./utilApi";
import axiosInstance from "../helpers/axiosInstance";

const baseUserURL = "/api/contacts/";

export async function getById(id) {
  return axiosInstance
    .get(baseUserURL + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function getSchoolById(id) {
  const extension = "school/";
  return axiosInstance
    .get(baseUserURL + extension + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredContacts(filters) {
  const extension = "filter";
  return axiosInstance
      .get(`${baseUserURL + extension}?name=${format(filters.name)}&email=${format(filters.email)}&documentNumber=${format(filters.documentNumber)}&contactTypeId=${format(filters.contactTypeId)}&consentTypeId=${format(filters.consentTypeId)}&inactiveTypeId=${format(filters.inactiveTypeId)}&cityHallId=${format(filters.cityHallId)}&email=${format(filters.email)}&areaTypeId=${format(filters.areaTypeId)}&jobType=${format(filters.jobType)}&artisanContactCarnetTypeId=${format(filters.artisanContactCarnetTypeId)}&subscriptedFrom=${format(filters.subscriptedFrom)}&associationTypeId=${format(filters.associationTypeId)}&relationTypeId=${format(filters.relationTypeId)}&subscriptedTo=${format(filters.subscriptedTo)}&page=${format(filters.page)}&sizePerPage=${format(filters.sizePerPage)}&surname=${filters.surname}&sortOrder=${format(filters.sortOrder)}&orderBy=${format(filters.orderBy)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredContactsExcel(filters) {
  const extension = "export";
  return axiosInstance
      .get(`${baseUserURL + extension}?name=${format(filters.name)}&email=${format(filters.email)}&documentNumber=${format(filters.documentNumber)}&contactTypeId=${format(filters.contactTypeId)}&consentTypeId=${format(filters.consentTypeId)}&inactiveTypeId=${format(filters.inactiveTypeId)}&cityHallId=${format(filters.cityHallId)}&email=${format(filters.email)}&areaTypeId=${format(filters.areaTypeId)}&jobType=${format(filters.jobType)}&artisanContactCarnetTypeId=${format(filters.artisanContactCarnetTypeId)}&subscriptedFrom=${format(filters.subscriptedFrom)}&associationTypeId=${format(filters.associationTypeId)}&relationTypeId=${format(filters.relationTypeId)}&subscriptedTo=${format(filters.subscriptedTo)}&page=${format(filters.page)}&sizePerPage=${format(filters.sizePerPage)}&surname=${filters.surname}&sortOrder=${format(filters.sortOrder)}&orderBy=${format(filters.orderBy)}`,
      { responseType: "blob"} )
      .then(response => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        const fileName = response.headers["content-disposition"].split("filename=")[1].split(";")[0]
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(handleError);
}

export async function sendExcel(file) {
  const extension = "import";
  return axiosInstance
    .post(baseUserURL + extension, file)
    .then(handleResponse)
    .catch((handleError));
}

export async function getFilteredAssociations(filters) {
  const extension = "associations";
  return axiosInstance
    .get(`${baseUserURL + extension}?name=${format(filters?.name)}&areaTypeId=${format(filters?.areaTypeId)}&cif=${format(filters?.cif)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredRelations(filters) {
  const extension = "relations";
  return axiosInstance
    .get(`${baseUserURL + extension}?name=${format(filters?.name)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function createContact(contact) {
  return axiosInstance
    .post(baseUserURL, contact)
    .then(handleResponse)
    .catch((handleError));
}

export async function updateContact(id, contact) {
  return axiosInstance
    .put(baseUserURL + id, contact)
    .then(handleResponse)
    .catch((handleError));
}