import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

function AlertMessage(props) {

    const { isError, neverClose } = props

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        setMessage(props.message)
    }, [props.message])

    useEffect(() => {
        setShowAlert(props.showAlert)
    }, [props.showAlert])

    const resetErrors = () => {
        setShowAlert(false)
        props.handleResetErrors()
    }

    return (
        showAlert && (
            neverClose
            ?
            <Alert variant={isError ? "danger" : "success"} onClose={() => resetErrors()}>
                <p>{message}</p>
            </Alert>
            :
            <Alert variant={isError ? "danger" : "success"} onClose={() => resetErrors()} dismissible>
                {
                message.includes("\n") 
                    ? 
                    <p>
                        {message.split("\n")
                        .splice(0, message.length-1)
                        .map((msg, i) => <span key={i}> {msg} {i < (message.split("\n").length-1) && (<br/>)}</span>)}
                    </p>
                    : 
                    <p>{message}</p>
                }
            </Alert>
        )
    );
}

AlertMessage.propTypes = {
    message: PropTypes.string.isRequired,
    isError: PropTypes.bool.isRequired,
    neverClose: PropTypes.bool,
    showError: PropTypes.bool.isRequired,
    handleResetErrors: PropTypes.func.isRequired
};

export default AlertMessage;



