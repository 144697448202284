import { handleResponse, handleError, format } from "./utilApi";
import axiosInstance from "../helpers/axiosInstance";

const baseUserURL = "/api/masters/";

export async function gendersMaster() {
  const extension = "genderTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getMarketingTypeById(id) {
  const extension = "marketingType/";
  return axiosInstance
    .get(baseUserURL + extension + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function getProfessionalStatusById(id) {
  const extension = "professionalStatus/";
  return axiosInstance
    .get(baseUserURL + extension + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function localEntitiesMaster() {
  const extension = "localEntitiesTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function sectorsMaster() {
  const extension = "sectorTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function documentsMaster() {
  const extension = "documentTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function workspacesMaster() {
  const extension = "workspaces";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function marketingTypesMaster() {
  const extension = "marketingType";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function professionalStatusMaster() {
  const extension = "professionalStatus";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function professionalActivityMaster() {
  const extension = "professionalActivity";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function statusTypeMaster() {
  const extension = "statusType";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function committeeTypeMaster() {
  const extension = "committeeType";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function validityMaster() {
  const extension = "validity";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function formationsMaster() {
  const extension = "formations";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function schoolsMaster() {
  const extension = "schools";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function roadsMaster() {
  const extension = "roadTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function contactsMaster() {
  const extension = "contactTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function consentsMaster() {
  const extension = "consentTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function carnetTypesMaster() {
  const extension = "carnetTypes";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function areasTypesMaster() {
  const extension = "areas";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredJobs(filters) {
  const extension = "filterJobs";
  return axiosInstance
    .get(`${baseUserURL + extension}?jobType=${format(filters?.jobType)}`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllJobs() {
  const extension = "jobs";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllRelations() {
  const extension = "relations";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllAssociations() {
  const extension = "associations";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllAssociationTypes() {
    const extension = "associationEntityTypes";
    return axiosInstance
        .get(baseUserURL + extension)
        .then(handleResponse)
        .catch(handleError);
}

export async function getContactWorkspaces() {
  const extension = "contactWorkspaces";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}