import PageTitle from "components/shared/PageTitle";
import UserHeader from "./components/UserHeader";
import UserList from "./components/UserList";
import React, { useEffect, useState } from "react";
import { getFilteredUsers } from "api/userApi";
import { toSelect, toTextDate } from "helpers/utils";
import { getAllProfiles } from "api/profileApi";
import { useHistory } from "react-router-dom";

export const UsersPage = () => {
  const title = "USUARIS";

  const breadcrumbItems = [
    {
      text: "Inici",
      current: false,
      linkTo: "/",
    },
    {
      text: "Usuaris",
      current: true,
      linkTo: "/users",
    },
  ];

  const history = useHistory();

  const [users, setUsers] = useState([]);

  const [filters, setFilters] = useState({
    totalSize: 0,
    page: 1,
    sizePerPage: 10,
    orderBy: "",
    sortOrder: "asc",
    search: "",
    roleId: "",
  });

  const [profiles, setProfiles] = useState([]);
  const [paginationOptions, setPaginationOptions] = useState([]);
  const [timeOut, setTimeOut] = useState(null);

  const columns = [
    {
      dataField: "id",
      text: "UserId",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Nom",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "surname1",
      text: "Cognom",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "userName",
      text: "Àlies",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "rolename",
      text: "Perfil",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
      headerStyle: () => {
        return { width: "35%" };
      },
    },
    {
      dataField: "created",
      text: "Alta",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "deleted",
      text: "Baixa",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
  ];

  const getProfiles = async () => {
    await getAllProfiles().then((profiles) => {
      setProfiles(toSelect(profiles));
    });
  };

  useEffect(() => {
    const getUsers = async () => {
      const filteredUsers = await getFilteredUsers(filters);
      const { data, ...filtersServer } = filteredUsers;
      setUsers(
        data.map(({ created, deleted, ...rest }) => ({
          created: toTextDate(created),
          deleted: toTextDate(deleted),
          ...rest,
        }))
      );
      setPaginationOptions({
        pageStartIndex: 1,
        firstPageText: "Primer",
        firstPageTitle: "Primera pàgina",
        prePageText: "Anterior",
        prePageTitle: "Pàgina anterior",
        nextPageText: "Següent",
        nextPageTitle: "Següent pàgina",
        lastPageText: "Últim",
        lastPageTitle: "Última pàgina",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [5, 10, 25],
        page: filtersServer.page,
        sizePerPage: filtersServer.sizePerPage,
        totalSize: filtersServer.totalSize,
      });
    };
    getUsers();
  }, [filters]);

  useEffect(() => {
    getProfiles();
  }, []);

  const handleTable = async (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "pagination") {
      setFilters({ ...filters, page: page, sizePerPage: sizePerPage });
    }

    if (type === "sort") {
      setFilters({
        ...filters,
        sortOrder: sortOrder,
        orderBy: sortField,
      });
    }
  };

  const noResultsFound = () => `No s'han trobat resultats`;

  const defaultSorted = [
    {
      dataField: "surname1",
      order: "asc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
      Mostrant {from} a {to} de {size} entrades
    </span>
  );

  const handleChangeSearch = (search) => {
    if (timeOut) clearTimeout(timeOut);
    setTimeOut(
      setTimeout(() => {
        setFilters({ ...filters, search });
      }, 30)
    );
  };

  const handleChangeProfile = async (event) => {
    setFilters({ ...filters, roleId: event.target.value });
  };

  const handleRowEvents = (e, row, rowIndex) => {
    history.push(`/user/${row.id}`);
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowEvents(e, row, rowIndex);
    },
  };
  
  const handleReset = async () => {
    setFilters({ ...filters, roleId: "", search: "" });
  };

  return (
    <>
      <PageTitle title={title} breadcrumbItems={breadcrumbItems} />
      <div className="main-content">
        <div className="row">
          <UserHeader
            filters={filters}
            onChangeSearch={handleChangeSearch}
            onChangeProfile={handleChangeProfile}
            profiles={profiles}
            onReset={handleReset}
          />
        </div>
        <div className="table-content">
          <UserList
            users={users}
            defaultSorted={defaultSorted}
            columns={columns}
            handleTable={handleTable}
            noResultsFound={noResultsFound}
            paginationOptions={paginationOptions}
            wrapperClasses="table-responsive"
            bordered={false}
            rowEvents={rowEvents}
          />
        </div>
      </div>
    </>
  );
};
