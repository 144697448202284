import React from 'react';
import { useFormikContext } from 'formik';
import Checkbox from 'components/shared/Checkbox';
import { useSelector } from 'react-redux';
import { viewModeState } from 'redux/slices/contactSlice';

const FashionPersonalInfoForm = () => {
    const { values, errors } = useFormikContext();
    const viewMode = useSelector(viewModeState);

    return (
        <>
            <div className="row form-content">
                <div className="col-sm-2 form-col first-form-element">
                    <Checkbox
                        name="area.services.belongs"
                        label="El contacte pertany a l'àrea"
                        value={values.area.services.belongs}
                        type="checkbox"
                        disabled={true}
                    />
                </div>
                <div className="col-sm-2 form-col margin-left-34px">
                    <Checkbox
                        name="area.services.interested"
                        label="El contacte té interés a l'àrea"
                        value={values.area.services.interested}
                        disabled={viewMode}
                    />
                </div>
                <div className="col-sm-1 form-col"></div>
            </div>
            <div className="row form-content">
              <div className="col-sm-6 form-col first-form-element error-msg">
                {errors?.area?.artisan?.belongs}
              </div>
            </div>
        </>
    );
};

export default FashionPersonalInfoForm;
