import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

//IMPORTANT!!
//COMMENTED LINES ARE FOR SELECT ONE ROW AND STILL DOING ACTIONS INSIDE MODAL

function ListModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleRowEvents = (e, row, rowIndex) => {
    // Array.from(e.currentTarget.parentElement.children).forEach(element => element.className = "");
    // e.currentTarget.className = "list-item-selected"
    // setSelectedValue({id:row.id, name:row.name});
    props.onAccept({
      id: row.id,
      businessName: row.businessName,
      tradeName: row.tradeName,
    });
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      handleRowEvents(e, row, rowIndex);
    },
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={props.onCancel}
        fade={true}
        centered={true}
        size={props.size ?? "lg"}
        modalTransition={{ timeout: 250 }}
      >
        <ModalHeader toggle={() => props.onCancel(false)}>
          {props.title}
        </ModalHeader>
        <ModalBody>
          <div className="modal-list-container">
            <div className="modal-list-filters">{props.filterElement}</div>
            <div className="table-content scrollableList">
              <BootstrapTable
                remote
                keyField="id"
                wrapperClasses="table-responsive"
                data={props.data}
                // defaultSorted={props.defaultSorted}
                columns={props.columns}
                // onTableChange={props.onTableChange}
                noDataIndication={props.noResultsFound}
                //pagination={props.showPagination ? paginationFactory(props.paginationOptions) : null}
                bordered={false}
                //filters={props.filters}
                rowEvents={rowEvents}
                bootstrap4
              />
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <div className="btn-form-group">
            <Button className="app-btn cancel-btn" onClick={() => props.onCancel(false)}>Cancel·lar</Button>
            <Button className="app-btn submit-btn" onClick={() => props.onAccept(selectedValue)}>Seleccionar</Button>
          </div>
        </ModalFooter> */}
      </Modal>
    </>
  );
}

ListModal.propTypes = {
  title: PropTypes.string.isRequired,
  noResultsFound: PropTypes.string.isRequired,
  // showPagination: propTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  // onTableChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  // defaultSorted: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  // paginationOptions: PropTypes.object.isRequired,
  size: PropTypes.string,
  filterElement: PropTypes.element,
};

export default ListModal;
