import React from "react";
import PropTypes from "prop-types";
import InputField from "components/shared/InputField";

const JobsModalListFilters = props => {

    const { filters, onChangeSearch } = props

    return (
    <>
        <div className="row form-content">
            <div className="col-sm-12 form-col">
                <InputField
                    label="Ofici:"
                    placeholder="Buscar ofici"
                    value={filters.jobType}
                    onChange={(e) => onChangeSearch({jobType:e.target.value})}
                    horizontal={true}
                    classNameCols={[1,11]}
                />
            </div>
        </div>
    </>
    );
      
}

JobsModalListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired
};

export default JobsModalListFilters;