import { handleResponse, handleError, format } from "./utilApi";
import axiosInstance from "../helpers/axiosInstance";

const baseUserURL = "/api/users/";

export async function getMe() {
  const extension = "me";
  return axiosInstance
    .get(baseUserURL + extension)
    .then(handleResponse)
    .catch(handleError);
}

export async function getById(id) {
  return axiosInstance
    .get(baseUserURL + id)
    .then(handleResponse)
    .catch(handleError);
}

export async function getFilteredUsers(filters) {
  const extension = "filter";
  return axiosInstance
    .get(
      `${baseUserURL + extension}?search=${format(
        filters.search
      )}&roleId=${format(filters.roleId)}&page=${format(
        filters.page
      )}&sizePerPage=${format(filters.sizePerPage)}&sortOrder=${format(
        filters.sortOrder
      )}&orderBy=${format(filters.orderBy)}`
    )
    .then(handleResponse)
    .catch(handleError);
}

export async function createUser(user) {
  return axiosInstance
    .post(baseUserURL, user)
    .then(handleResponse)
    .catch(handleError);
}

export async function updateUser(id, user) {
  return axiosInstance
    .put(baseUserURL + id, user)
    .then(handleResponse)
    .catch(handleError);
}

export async function signOut() {
  return axiosInstance
    .get(baseUserURL + "logout")
    .then(handleResponse)
    .catch(handleError);
}
