import React from "react";
import Loader from "react-loader-spinner";

function Spinner() {

    return (
        <div className='loader'>
            <Loader
                type="ThreeDots"
                color="#CC0000"
                height={70}
                width={70}
            />
            <div className='loader-message'>Processant...</div>
        </div>
    )
      
}

export default Spinner