import React from "react";
import PropTypes from "prop-types";
import InputField from "components/shared/InputField";

const CompaniesModalListFilters = props => {

    const { filters, onChangeSearch, onReset } = props

    return (
    <>
        <div className="row form-content">
            <div className="col-sm-9 form-col">
                <InputField
                    label="Empresa:"
                    placeholder="Nom de l'empresa"
                    value={filters.businessName}
                    onChange={(e) => onChangeSearch({businessName:e.target.value})}
                    horizontal={true}
                />
            </div>
            <div className="col-sm-3 form-col">
                <InputField
                    label="CIF:"
                    placeholder="Buscar CIF"
                    value={filters.cif}
                    onChange={(e) => onChangeSearch({cif:e.target.value})}
                    horizontal={true}
                />
            </div>
        </div>
        <div className="row form-content">
            <div className="col-sm-9 form-col">
                <InputField
                    label="Raó social:"
                    placeholder="Raó social"
                    value={filters.tradeName}
                    onChange={(e) => onChangeSearch({tradeName:e.target.value})}
                    horizontal={true}
                />
            </div>
            <div className="col-sm-3 form-col">
                <span
                    className="uil-filter-slash delete-filters-btn"
                    title="Netejar filtres"
                    onClick={onReset}
                ></span>
            </div>
        </div>
    </>
    );
      
}

CompaniesModalListFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onChangeSearch: PropTypes.func.isRequired
};

export default CompaniesModalListFilters;