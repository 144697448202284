
export async function handleResponse(response) {
  if (response.status === 200) return response.data;

  if (response.status === 401) {
    throw new Error(response.error);
  }

  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export async function handleRemoveResponse(response) {
  if (response.status === 200) return;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

export async function handleFileResponse(response) {
  if (response.status === 200) return await response.blob();

  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// TODO: call an error logging service.
export function handleError(error) {
  if (error.response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    throw new Error(JSON.stringify(error.response));
  }
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

const getSortingParams = (loadOptions) =>
  loadOptions.sorting && loadOptions.sorting.length > 0
    ? {
        sort: loadOptions.sorting.map((s) => ({
          selector: s.columnName,
          desc: s.direction === "desc",
        })),
      }
    : {};

const getPagingParams = (loadOptions) => {
  const params = {};
  if (loadOptions.pageSize) params.take = loadOptions.pageSize;
  if (loadOptions.currentPage > 0)
    params.skip = loadOptions.currentPage * loadOptions.pageSize;
  return params;
};

const getFilterParams = (loadOptions) =>
  loadOptions.filters && loadOptions.filters.length > 0
    ? {
        filter: loadOptions.filters.reduce((r, v) => {
          if (r.length > 0) r.push("and");
          r.push([v.columnName, v.operation, v.value]);
          return r;
        }, []),
      }
    : {};

const getGroupParams = (loadOptions) =>
  loadOptions.grouping && loadOptions.grouping.length > 0
    ? {
        group: loadOptions.grouping.map((g) => ({
          selector: g.columnName,
          isExpanded: false,
        })),
        requireGroupCount: true,
        // skip and take override any previous settings when
        // these params are combined with others
        skip: undefined,
        take: undefined, // always query all groups
      }
    : {};

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

export const createQueryURL = (baseUrl, loadOptions) => {
  const loadOptionsParsed = Object.assign.apply({}, [
    getSortingParams(loadOptions),
    getPagingParams(loadOptions),
    getFilterParams(loadOptions),
    getGroupParams(loadOptions), // overrides skip and take
    {
      requireTotalCount: true,
    },
  ]);

  let query = "?";

  [
    "skip",
    "take",
    "requireTotalCount",
    "requireGroupCount",
    "sort",
    "filter",
    "totalSummary",
    "group",
    "groupSummary",
  ].forEach(function (i) {
    if (i in loadOptionsParsed && isNotEmpty(loadOptionsParsed[i])) {
      query += `${i}=${JSON.stringify(loadOptionsParsed[i])}&`;
    }
  });

  query = query.slice(0, -1);

  return query ? baseUrl.concat(query) : baseUrl;
};

export const format = (data) => {
  if(!data)
    return ''
  return data
}
