import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import Checkbox from "components/shared/Checkbox";
import InputField from "components/shared/InputField";
import Select from "components/shared/Select";
import { useSelector, useDispatch } from "react-redux";
import { toSelect } from "helpers/utils";
import { viewModeState } from "redux/slices/contactSlice";
import { Accordion, Card } from "react-bootstrap";
import { professionalActivityData, statusTypeData, committeeTypeData, validityData, getCommitteeType, getProfessionalActivity, getValidity, getStatusType } from "redux/slices/masterSlice";
import moment from "moment";

const ArtisanDataForm = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCommitteeType());
    dispatch(getProfessionalActivity());
    dispatch(getValidity());
    dispatch(getStatusType());
  }, [dispatch]);


  const { values, errors, handleChange, setFieldValue} = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const professionalActivity = toSelect(useSelector(professionalActivityData));
  const statusType = toSelect(useSelector(statusTypeData));
  const committeeType = toSelect(useSelector(committeeTypeData));
  const validity = toSelect(useSelector(validityData));

  const [arrowClass, setArrowClass] = useState("accordion-arrow-down")

  const handleArrowClass = () => {
    const newArrowClass = arrowClass === "accordion-arrow-down" ? "accordion-arrow-up" : "accordion-arrow-down";
    setArrowClass(newArrowClass)
  }

  const handleStatusTypeChange = async (value) => {
    const statusTypeId = value;
    setFieldValue("area.artisan.contactAreaArtisanData.statusTypeId", statusTypeId);
  };

  const handleProfessionalActivityChange = async (value) => {
    const professionalActivityId = value;
    setFieldValue("area.artisan.contactAreaArtisanData.professionalActivityId", professionalActivityId);
  };

  const handleCommitteeTypeChange = async (value) => {
    const committeeTypeId = value;
    setFieldValue("area.artisan.contactAreaArtisanData.committeeTypeId", committeeTypeId);
  };

  const handleValidityChange = async (value) => {
    const validityId = value;
    setFieldValue("area.artisan.contactAreaArtisanData.validityId", validityId);
  };

  return (
    <>
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} onClick={handleArrowClass} eventKey="0">
          Dades artesà
          <i className={"uil-arrow-circle-down " + arrowClass} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          <div className="row form-content no-margin-top">
            <div className="col-sm-3 form-col">
                  <InputField
                      label="Data de naixement"
                      name="area.artisan.contactAreaYoungTalent.born"
                      placeholder="Data de naixement"
                      value={
                        values.area?.artisan?.contactAreaYoungTalent?.born 
                        && values.area?.artisan?.contactAreaYoungTalent?.born !== "0001-01-01T00:00:00"
                        ? moment(values.area?.artisan?.contactAreaYoungTalent?.born).format("YYYY-MM-DD")
                        : ""}
                      error={errors.area?.artisan?.contactAreaYoungTalent?.born}
                      onChange={handleChange}
                      horizontal={false}
                      isMandatory={true}
                      type="date"
                      disabled={viewMode}
                  />
            </div>   
            <div className="col-sm-2 form-col margin-left-20px">
              <Checkbox
                name="area.artisan.contactAreaArtisanData.isVIP"
                label="Es VIP"
                value={values.area?.artisan?.contactAreaArtisanData?.isVIP}
                disabled={viewMode}
              />
            </div>
            <div className="col-sm-2 form-col margin-left-20px">
              <Checkbox
                name="area.artisan.contactAreaArtisanData.isAmbassador"
                label="Es Ambaixador"
                value={values.area?.artisan?.contactAreaArtisanData?.isAmbassador}
                disabled={viewMode}
              />
            </div>

          </div>
          <div className="row form-content">
              <div className="col-sm-3 form-col">
                  <InputField
                    label="Pàgina web artesà"
                    name="area.artisan.contactAreaArtisanData.web"
                    value={values.area?.artisan?.contactAreaArtisanData?.web}
                    horizontal={false}
                    disabled={viewMode}
                    onChange={handleChange}
                  />
              </div>
              <div className="col-sm-3 form-col margin-left-20px">
                  <Select
                    label="Exercici professional de l'activitat artesanal"
                    name="area.artisan.contactAreaArtisanData.professionalActivityId"
                    defaultOption="Selecciona exercici professional"
                    value={values.area?.artisan?.contactAreaArtisanData?.professionalActivityId}
                    options={professionalActivity}
                    error={
                      errors?.area?.artisan?.contactAreaArtisanData?.professionalActivityId
                    }
                    onChange={(ev) => handleProfessionalActivityChange(ev.target.value)}
                    horizontal={false}
                    isMandatory={true}
                    disabled={viewMode}
                  />
              </div>
              <div className="col-sm-3 form-col margin-left-20px">
                  <Select
                    label="Col·lectiu"
                    name="area.artisan.contactAreaArtisanData.committeeTypeId"
                    defaultOption="Selecciona un col·lectiu"
                    value={values.area?.artisan?.contactAreaArtisanData?.committeeTypeId}
                    options={committeeType}
                    error={
                      errors?.area?.artisan?.contactAreaArtisanData?.committeeTypeId
                    }
                    onChange={(ev) => handleCommitteeTypeChange(ev.target.value)}
                    horizontal={false}
                    isMandatory={true}
                    disabled={viewMode}
                  />
              </div>
          </div>
          <div className="row form-content">
              <div className="col-sm-3 form-col">
                      <Select
                        label="Estat"
                        name="area.artisan.contactAreaArtisanData.statusTypeId"
                        defaultOption="Selecciona l'estat de l'artesà"
                        value={values.area?.artisan?.contactAreaArtisanData?.statusTypeId}
                        options={statusType}
                        error={
                          errors?.area?.artisan?.contactAreaArtisanData?.statusTypeId
                        }
                        onChange={(ev) => handleStatusTypeChange(ev.target.value)}
                        horizontal={false}
                        isMandatory={true}
                        disabled={viewMode}
                      />
              </div>
              <div className="col-sm-3 form-col margin-left-20px">
                      <Select
                        label="Vigència"
                        name="area.artisan.contactAreaArtisanData.validityId"
                        defaultOption="Selecciona una vigència"
                        value={values.area?.artisan?.contactAreaArtisanData?.validityId}
                        options={validity}
                        error={
                          errors?.area?.artisan?.contactAreaArtisanData?.validity
                        }
                        onChange={(ev) => handleValidityChange(ev.target.value)}
                        horizontal={false}
                        isMandatory={true}
                        disabled={viewMode}
                      />
              </div>
          </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
      
    </>
  );
};

export default ArtisanDataForm;
