export const SPAIN_INE_CODE = "108";
export const SPAIN_ID = "6";
export const COMPANY_TYPE = "2";
export const POSTAL_CODE_LENGTH = 5;
export const ARTISAN_CARNETS_ARRAY_NAME =
  "area.artisan.contactAreaArtisanCarnets";
export const ARTISAN_DATA_ARRAY_NAME =
  "area.artisan.contactAreaArtisanData";
export const ARTISAN_YOUNGTALENT_NAME = "area.artisan.contactAreaYoungTalent";
export const ARTISAN_YOUNGTALENT_WORKSPACE = "area.artisan.contactAreaYoungTalent.workspaces";
export const ARTISAN_YOUNGTALENT_ARRAY_NAME =
  "area.artisan.contactAreaYoungTalent.formations";

export const baseURL = window.location.origin;
