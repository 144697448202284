import moment from "moment";

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();

export const toInputDate = (stringDate) =>
  moment(stringDate).format("YYYY-MM-DD");

export const toTextDate = (stringDate) => {
  if (!stringDate) return "";
  return moment(stringDate).format("DD/MM/YYYY");
};

export const toSelect = (lisItems) => {
  return lisItems
    ? lisItems.map((item) => ({
        value: item.id,
        text: item.name ? item.name : item.value,
      }))
    : [];
};

export const newGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const isCIF = (value) => {
  var pares = 0;
  var impares = 0;
  var suma;
  var ultima;
  var unumero;
  var uletra = ["J", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
  var xxx;
  var texto = value.toUpperCase();

  var regular = new RegExp(/^[ABCDEFGHKLMNPQS]\d\d\d\d\d\d\d[0-9,A-J]$/g);
  if (!regular.exec(texto)) return false;

  ultima = texto.substr(8, 1);

  for (var cont = 1; cont < 7; cont++) {
    xxx = (2 * parseInt(texto.substr(cont++, 1))).toString() + "0";
    impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
    pares += parseInt(texto.substr(cont, 1));
  }
  xxx = (2 * parseInt(texto.substr(cont, 1))).toString() + "0";
  impares += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

  suma = (pares + impares).toString();
  unumero = parseInt(suma.substr(suma.length - 1, 1));
  unumero = (10 - unumero).toString();
  if (unumero === 10) unumero = 0;

  return ultima === unumero || ultima === uletra[unumero];
};

export async function wait(milliseconds) {
  return new Promise(function (resolve) {
    setTimeout(resolve, milliseconds);
  });
}
