import React, { Suspense, useEffect } from "react";
import "./custom.css";
// Themes
import "./assets/scss/app.scss";
import store from "./redux/store";
import { Provider } from "react-redux";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { AuthProvider } from "oidc-react";
import AppAuth from "./AppAuth";
import { Spinner } from "reactstrap";
import { baseURL } from "constants/applicationConstants";

const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <AuthProvider
        authority={baseURL}
        clientId="CCAM.Artesans.Web"
        redirectUri={`${baseURL}/authentication/login-callback`}
        postLogoutRedirectUri={`${baseURL}/authentication/logout-callback`}
        responseType="code"
        scope="CCAM.Artesans.WebAPI openid profile"
      >
        <Provider store={store}>
          <AppAuth />
        </Provider>
      </AuthProvider>
    </Suspense>
  );
};

export default App;
