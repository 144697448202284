import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ConfirmationModal(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={props.onCancel}>
        <ModalHeader toggle={props.onCancel}>{props.title}</ModalHeader>
        <ModalBody>
          <p>{props.message}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="app-btn submit-btn" onClick={props.onAccept}>
            {"D' acord"}
          </Button>
          <Button className="app-btn cancel-btn" onClick={props.onCancel}>
            {"Cancel·la"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;
