/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Select from "components/shared/Select";
import InputField from "components/shared/InputField";
import RadioButton from "components/shared/RadioButton";
import InputFile from "components/shared/InputFile";
import AutoSuggestInput from "components/shared/AutoSuggestInput";
import { getFilteredContactsExcel, sendExcel } from "api/contactApi";
import { Button } from "react-bootstrap";
import CanAccessContent from "components/shared/CanAccessContent";
import { Roles } from "constants/Roles";
import AlertMessage from "components/shared/AlertMessage";

const ContactHeader = (props) => {
  const {
    filters,
    contactTypes,
    cityHalls,
    consentTypes,
    inactiveTypes,
    areaTypes,
    carnetTypes,
    jobs,
    associations,
    relations,
    onChangeName,
    onChangeEmail,
    onChangeDocumentNumber,
    onChangeContactType,
    onChangeConsentType,
    onChangeInactiveType,
    onChangeCityHall,
    onChangeAreaType,
    onChangeJobType,
    onChangeArtisanContactCarnetType,
    onChangeAssociationType,
    onChangeRelationType,
    onChangeSubscriptedFrom,
    onChangeSubscriptedTo,
    onReset,
  } = props;

  const [successMessage, setSuccessMessage] = useState("")
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errors, setErrors] = useState("")
  const [showErrors, setShowErrors] = useState(false)

  const exportExcel = async () => {
    getFilteredContactsExcel(filters);
  }

  const importExcel = async (file) => {
    var formData = new FormData();
    formData.append('formFile', file);
    formData.append('fileName', file.name);
    await sendExcel(formData)
      .then(() => {
        setShowSuccessMessage(true)
        setSuccessMessage("S'han carregat correctament tots els contactes.");
        setShowErrors(false);
        setErrors("");
      })
      .catch((err) => {
        setShowErrors(true);
        setErrors(err.response.data);
      });
  }

  const handleResetErrors = () => {
    setSuccessMessage("")
    setShowSuccessMessage(false)
    setErrors("")
    setShowErrors(false)
  }
  
  return (
    <>
      <div className="row">
        <div className="col-sm-1 header-app">

            <CanAccessContent roles={[Roles.ADMIN, Roles.EDITOR]}>
                <Link to="/contact/" className="uil-plus-circle plus-btn text-center"></Link>
            </CanAccessContent>
            <Button className="app-btn-functional excel-btn btn-block btn-header" variant="primary" onClick={exportExcel}>
              <i className="uil-down-arrow export-icon" />
              Export
            </Button>
        </div>

     

        <div className="col-sm-2 header-app">
          <InputField
            label="Cognom: "
            placeholder="Cognom"
            value={filters.surname}
            onChange={(e) => onChangeName(e.target.value)}
            horizontal={false}
          />
        </div>

        <div className="col-sm-2 header-app">
          <InputField
            label="Document: "
            placeholder="Nº Document"
            value={filters.documentNumber}
            onChange={(e) => onChangeDocumentNumber(e.target.value)}
            horizontal={false}
          />
        </div>

        <div className="col-sm-2 header-app">
          <InputField
            label="Email: "
            placeholder="Email"
            value={filters.email}
            onChange={(e) => onChangeEmail(e.target.value)}
            horizontal={false}
          />
        </div>
        <div className="col-sm-2 header-app">
          <Select
            label="Ajuntament: "
            name="cityHallId"
            defaultOption="Ajuntament"
            value={filters.cityHallId}
            onChange={onChangeCityHall}
            options={cityHalls}
            horizontal={false}
            required={true}
          />
        </div>
        <div className="col-sm-2 header-app header-filter-app">
            <RadioButton
                label="Baixes: "
                name="inactiveTypeId"
                defaultOption="Només actius"
                selectedOption={filters.inactiveTypeId}
                onChange={onChangeInactiveType}
                options={inactiveTypes}
                horizontal={false}
            />
        </div>

              <div className="col-sm-1 header-app">
                  <span
                      className="uil-filter-slash delete-filters-padding-top-btn"
                      title="Netejar filtres"
                      onClick={onReset}
                  ></span>
              </div>
      </div>

      <div className="row">
        <div className="col-sm-1 header-filter-app btn-block">
          {/* <Button className="app-btn-functional excel-btn btn-block" variant="primary" onClick={exportExcel}>
            <i className="uil-down-arrow export-icon" />
            Export
          </Button> */}
          <CanAccessContent roles={[Roles.ADMIN, Roles.EDITOR]}>
                      <InputFile className="btn btn-primary app-btn-functional excel-btn btn-block btn-header"
              label="Import"
              onChange={importExcel}
            />
          </CanAccessContent>
        </div>
        <div className="col-sm-2 header-filter-app">
          <Select
            label="Tipus de contacte: "
            name="contactTypeId"
            defaultOption="Qualsevol"
            value={filters.contactTypeId}
            onChange={onChangeContactType}
            options={contactTypes}
            horizontal={false}
            required={true}
          />
        </div>
        <div className="col-sm-2 header-filter-app">
          <Select
            label="Àrea: "
            name="areaTypeId"
            defaultOption="Qualsevol"
            value={filters.areaTypeId}
            onChange={onChangeAreaType}
            options={areaTypes}
            horizontal={false}
            required={true}
          />
        </div>
        <div className="col-sm-2 header-filter-app">
          <Select
            label="Associació: "
            name="associationTypeId"
            defaultOption="Qualsevol"
            value={filters.associationTypeId}
            onChange={onChangeAssociationType}
            options={associations}
            horizontal={false}
            required={true}
          />
        </div>
        <div className="col-sm-2 header-filter-app">
          <Select
            label="Relació: "
            name="relationTypeId"
            defaultOption="Qualsevol"
            value={filters.relationTypeId}
            onChange={onChangeRelationType}
            options={relations}
            horizontal={false}
            required={true}
          />
        </div>
        <div className="col-sm-2 header-filter-app">
          <RadioButton
            label="Consentiment: "
            name="contactTypeId"
            defaultOption="Tipus de contacte"
            selectedOption={filters.consentTypeId}
            onChange={onChangeConsentType}
            options={consentTypes}
            horizontal={false}
          />
        </div>
      </div>
      {filters.areaTypeId === "1" && (
        <div className="row">
          <div className="col-sm-2 header-filter-app first-header-element"></div>
          <div className="col-sm-2 header-filter-app">
            <AutoSuggestInput
              value={filters.jobType}
              onChange={onChangeJobType}
              label="Ofici: "
              placeholder="Selecciona un ofici"
              options={jobs.map((o) => o.text)}
              maxResults={10}
            />
          </div>
          <div className="col-sm-2 header-filter-app">
            <Select
              label="Modalitat de carnet: "
              name="artisanContactCarnetTypeId"
              defaultOption="Qualsevol"
              value={filters.artisanContactCarnetTypeId}
              onChange={onChangeArtisanContactCarnetType}
              options={carnetTypes}
              horizontal={false}
              required={true}
            />
          </div>
          <div className="col-sm-2 header-filter-app">
            <InputField
              label="Carnet des de: "
              type="date"
              value={filters.subscriptedFrom}
              onChange={(e) => onChangeSubscriptedFrom(e.target.value)}
              horizontal={false}
            />
          </div>
          <div className="col-sm-2 header-filter-app">
            <InputField
              label="Carnet fins: "
              type="date"
              value={filters.subscriptedTo}
              onChange={(e) => onChangeSubscriptedTo(e.target.value)}
              horizontal={false}
            />
          </div>
        </div>
        )
      }
      {showErrors && (
        <>
          <br />
          <AlertMessage
            message={errors}
            isError={true}
            showAlert={true}
            handleResetErrors={handleResetErrors}
          />
        </>
      )}
      {showSuccessMessage && (
        <>
          <br />
          <AlertMessage
            message={successMessage}
            isError={false}
            showAlert={true}
            handleResetErrors={handleResetErrors}
          />
        </>
      )}
    </>
  );
};

ContactHeader.propTypes = {
  filters: PropTypes.object.isRequired,
  contactTypes: PropTypes.array.isRequired,
  cityHalls: PropTypes.array.isRequired,
  consentTypes: PropTypes.array.isRequired,
  inactiveTypes:PropTypes.array.isRequired,
  areaTypes: PropTypes.array.isRequired,
  jobs: PropTypes.array.isRequired,
  associations: PropTypes.array.isRequired,
  relations: PropTypes.array.isRequired,
  carnetTypes: PropTypes.array.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeDocumentNumber: PropTypes.func.isRequired,
  onChangeContactType: PropTypes.func.isRequired,
  onChangeConsentType: PropTypes.func.isRequired,
  onChangeCityHall: PropTypes.func.isRequired,
  onChangeAreaType: PropTypes.func.isRequired,
  onChangeJobType: PropTypes.func.isRequired,
  onChangeArtisanContactCarnetType: PropTypes.func.isRequired,
  onChangeAssociationType: PropTypes.func.isRequired,
  onChangeRelationType: PropTypes.func.isRequired,
  onChangeSubscriptedFrom: PropTypes.func.isRequired,
  onChangeSubscriptedTo: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default ContactHeader;
