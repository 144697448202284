import InputField from "components/shared/InputField";
import Select from "components/shared/Select";
import TextAreaField from "components/shared/TextAreaField";
import React from "react";
import moment from "moment";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import {
  cityHallsData,
  editModeState,
  viewModeState,
} from "redux/slices/contactSlice";
import { localEntitiesTypesData, sectorTypesData } from "redux/slices/masterSlice";

import { toSelect } from "helpers/utils";

const OtherInfoForm = () => {
  const { values, errors, handleChange } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const editMode = useSelector(editModeState);
  const cityHalls = toSelect(useSelector(cityHallsData));
  const localEntitiesTypes = toSelect(useSelector(localEntitiesTypesData));
  const sectorTypes = toSelect(useSelector(sectorTypesData));
  return (
    <>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
        <Select
            label="Tipus d’ens local"
            name="dades.localEntityType"
            value={values.dades.localEntityType}
            error={errors.dades?.localEntityType}
            onChange={handleChange}
            options={localEntitiesTypes}
            defaultOption="Seleccionar tipus"
            horizontal={false}
            disabled={viewMode}
            required={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>

        <div className="col-sm-2 form-col">
          <InputField
            label="Nom"
            name="dades.localEntityName"
            placeholder="Nom de l'ens local"   
            value={values.dades.localEntityName}
            error={errors.dades?.localEntityName} 
            onChange={handleChange}         
            horizontal={false}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>

        <div className="col-sm-2 form-col">
          <Select
            label="Sector"
            name="dades.localEntitySector"
            value={values.dades.localEntitySector}
            error={errors.dades?.localEntitySector}
            onChange={handleChange}
            options={sectorTypes}
            defaultOption="Seleccionar sector"
            horizontal={false}
            disabled={viewMode}
            required={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>

        <div className="col-sm-3 form-col">
            <input
              name="dades.isAmbassador"
              checked={values.dades.isAmbassador}
              value={values.dades.isAmbassador}
              onChange={handleChange}
              className="checkbox-col-form"
              type="checkbox"
              disabled={viewMode}
            />
            <label className="form-check-label" htmlFor="dades.isAmbassador">
              Ambaixador/a{" "}
              <span className="asterisc">*</span>
            </label>
          </div>
      </div>

      <div className="row form-content">
        {(viewMode || editMode) && (
            <>
              <div className="col-sm-2 form-col">
                <InputField
                  label="Alta"
                  name="dades.created"
                  value={
                    values.dades.created
                      ? moment(values.dades.created).format("YYYY-MM-DD")
                      : ""
                  }
                  horizontal={false}
                  disabled={true}
                  type="date"
                />
              </div>
              <div className="col-sm-1 form-col"></div>
              <div className="col-sm-2 form-col">
                <InputField
                  label="Baixa"
                  name="dades.deleted"
                  value={
                    values.dades.deleted
                      ? moment(values.dades.deleted).format("YYYY-MM-DD")
                      : ""
                  }
                  horizontal={false}
                  disabled={!editMode}
                  onChange={handleChange}
                  type="date"
                />
              </div>
              <div className="col-sm-1 form-col"></div>
            </>
          )}

          <div className="col-sm-3 form-col">
            <input
              name="dades.consent"
              checked={values.dades.consent}
              value={values.dades.consent}
              onChange={handleChange}
              className="checkbox-col-form"
              type="checkbox"
              disabled={viewMode}
            />
            <label className="form-check-label" htmlFor="dades.consent">
              Consentiment dades per newsletters{" "}
              <span className="asterisc">*</span>
            </label>
          </div>
      </div>

      {/* Hereeeeeeeeeeeeee */}
      <div className="row form-content">
        <div className="col-sm-2 form-col">
              <InputField
                label="Web"
                name="dades.web"
                placeholder="Web"   
                value={values.dades.web}
                error={errors.dades?.web} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
              <InputField
                label="Facebook"
                name="dades.facebook"
                placeholder="Facebook"   
                value={values.dades.facebook}
                error={errors.dades?.facebook} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
              <InputField
                label="Twitter"
                name="dades.twitter"
                placeholder="Twitter"   
                value={values.dades.twitter}
                error={errors.dades?.twitter} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
              <InputField
                label="Instagram"
                name="dades.instagram"
                placeholder="Instagram"   
                value={values.dades.instagram}
                error={errors.dades?.instagram} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
      </div>

      <div className="row form-content">
      <div className="col-sm-2 form-col">
              <InputField
                label="Pinterest"
                name="dades.pinterest"
                placeholder="Pinterest"   
                value={values.dades.pinterest}
                error={errors.dades?.pinterest} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
              <InputField
                label="Altres"
                name="dades.othersocialnetwork"
                placeholder="Altres"   
                value={values.dades.othersocialnetwork}
                error={errors.dades?.othersocialnetwork} 
                onChange={handleChange}         
                horizontal={false}
                disabled={viewMode}
              />
        </div>
        <div className="col-sm-1 form-col"></div>
      </div>

      <div className="row form-content">
        <div className="col-sm-12 form-col no-padding-right">
          <TextAreaField
            label="Observacions"
            name="dades.observations"
            placeholder="Introduir observacions"
            value={values.dades.observations}
            error={errors.observations}
            onChange={handleChange}
            rows="4"
            disabled={viewMode}
          />
        </div>
      </div>
    </>
  );
};

export default OtherInfoForm;
