import React, { useState, useEffect } from "react";
import Select from "components/shared/Select";
import InputField from "components/shared/InputField";
import { useFormikContext } from "formik";
import { toSelect } from "helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { roadTypesData } from "redux/slices/masterSlice";
import {
  citiesData,
  comarquesData,
  countriesData,
  provincesData,
  setCities,
  setProvinces,
  setComarques,
  viewModeState,
} from "redux/slices/contactSlice";
import { POSTAL_CODE_LENGTH } from "constants/applicationConstants";
import {
  getAddressFromCP,
  getAddressFromCPWithDepartment,
  getCitiesByProvince,
  getComarquesByProvince,
  getPostalCodesByCity,
  getAllProvinces,
} from "api/addressApi";

const AddressInfoForm = (props) => {
  const { values, errors, handleChange, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const roadTypes = toSelect(useSelector(roadTypesData));
  const countries = useSelector(countriesData);
  const cities = useSelector(citiesData);
    const provinces = useSelector(provincesData);
    const comarques = useSelector(comarquesData);
  const viewMode = useSelector(viewModeState);
  const [waitingPostalCode, setWaitingPostalCode] = useState(false);
  const [isSpain, setIsSpain] = useState(true);
  const [isDepartment, setIsDepartment] = useState(true);


  const getProvinces = async () => {
    const provinces = await getAllProvinces();
    dispatch(setProvinces(toSelect(provinces)));
  };

  useEffect(() => {
    getProvinces()
  }, [])
  
  useEffect(() => {
    setIsSpain(values.dades.countryId.toString() === "6")
  }, [values])


  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(
      (x) => x.id === Number(e.target.value)
    );
    setIsSpain(e.target.value === "6");
    setFieldValue("dades.countryId", e.target.value);
    setFieldValue("dades.country", selectedCountry.name);
  };

  const handleProvinceChange = async (e) => {
    const provinceId = e.target.value;
    if (provinceId !== "") {
      const selectedProvince = provinces.find(
        (x) => x.value === Number(provinceId)
      );
      setFieldValue("dades.provinceId", provinceId);
      setFieldValue("dades.province", selectedProvince.text);


      const cities = await getCitiesByProvince(provinceId);
      const comarques = await getComarquesByProvince(provinceId);

       
      dispatch(setComarques(toSelect(comarques)));
      dispatch(setCities(toSelect(cities)));
      setWaitingPostalCode(true);
    } else {
      setFieldValue("dades.provinceId", "");
      setFieldValue("dades.province", "");
    }
  };

  const handleCityChange = async (e) => {
    const cityId = e.target.value;
    if (cityId !== "") {
      const selectedCity = cities.find((x) => x.value === Number(cityId));
      setFieldValue("dades.cityId", cityId);
      setFieldValue("dades.city", selectedCity.text);
      const citiesFetched = await getPostalCodesByCity(cityId);
      if (citiesFetched.length === 1) {
        setFieldValue("dades.postalCode", citiesFetched[0].name);
        setWaitingPostalCode(false);
      }else{
        setFieldValue("dades.postalCode", "");
        setWaitingPostalCode(true);
      }
    } else {
      setFieldValue("dades.cityId", "");
      setFieldValue("dades.city", "");
    }
  };

  const handlePostalCodeChange = async (e) => {
    const CP = e.target.value;
    setFieldValue("dades.postalCode", CP);
    if (isSpain && !waitingPostalCode) {

      //set department condition
      let CPsubstring = CP.substring(0,2)
      if (CP && CP.length === POSTAL_CODE_LENGTH && (CPsubstring === "08" || CPsubstring === "17" || CPsubstring === "25" || CPsubstring === "43"  )) {
        setIsDepartment(true)
        const addresFromCp = await getAddressFromCPWithDepartment(CP);
        if (addresFromCp.length > 0) {

          setFieldValue("dades.provinceId", "");
          setFieldValue("dades.cityId", "");
          setAddressHierarchySelects(CP, addresFromCp);
          setFieldValue("dades.provinceId", addresFromCp[0].provinceId);
          setFieldValue("dades.cityId", addresFromCp[0].cityId);
        }else{
          resetProvincesAndCities()
          setFieldValue("dades.postalCode", "");
        }
      }

      else if (CP && CP.length === POSTAL_CODE_LENGTH) {
        //set isDepartment to false
        setIsDepartment(false)
        const addresFromCp = await getAddressFromCP(CP);
        if (addresFromCp.length > 0) {
          setFieldValue("dades.provinceId", "");
          setFieldValue("dades.cityId", "");
          setAddressHierarchySelects(CP, addresFromCp);
          setFieldValue("dades.provinceId", addresFromCp[0].provinceId);
          setFieldValue("dades.cityId", addresFromCp[0].cityId);
        }else{
          resetProvincesAndCities()
          setFieldValue("dades.postalCode", "");
        }
      } else if (!CP || CP.length === 0) {
        resetProvincesAndCities();
        setFieldValue("dades.postalCode", CP);
      }
    }

    if (waitingPostalCode && CP.length === POSTAL_CODE_LENGTH)
      setWaitingPostalCode(false);
  };

  const resetProvincesAndCities = () => {
    dispatch(setCities([]));
    getProvinces();
    setFieldValue("dades.provinceId", "");
    setFieldValue("dades.province", "");
    setFieldValue("dades.cityId", "");
    setFieldValue("dades.city", "");
  }

  const setAddressHierarchySelects = (CP, addresFromCp) => {
    if (addresFromCp && addresFromCp.length > 0) {
      const cities = addresFromCp.map((item) => ({
        value: item.cityId,
        text: item.city,
      }));
      dispatch(setCities(cities));
      dispatch(
        setProvinces([
          {
            value: addresFromCp[0].provinceId,
            text: addresFromCp[0].province,
          },
        ])
      );
      //dispatch departments
    }
  };

  return (
    <>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <Select
            label="Tipus de via"
            name="dades.addressType"
            value={values.dades.addressType}
            error={errors.dades?.addressType}
            onChange={handleChange}
            options={roadTypes}
            defaultOption="Seleccionar via"
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
            required={true}         
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-5 form-col">
          <InputField
            label="Nom via"
            name="dades.addressName"
            placeholder="Nom de la via"
            value={values.dades.addressName}
            error={errors.dades?.addressName}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
          />
        </div>

        {props.showContactData ? (
          <>
          <div className="col-sm-1 form-col"style={{"marginLeft": "2em"}}>
          <InputField
            label="Número"
            name="dades.addressNumber"
            placeholder=""
            value={values.dades.addressNumber}
            error={errors.dades?.addressNumber}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            type="text"
            className="form-group-small"
          />
        </div>
        <div className="col-sm-1 form-col">
          <InputField
            label="Pis"
            name="dades.addressFloor"
            placeholder=""
            value={values.dades.addressFloor}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            className="form-group-small"
          />
        </div>
        <div className="col-sm-1 form-col">
          <InputField
            label="Porta"
            name="dades.addressDoor"
            placeholder=""
            value={values.dades.addressDoor}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            className="form-group-small"
          />
        </div>
        </>)
        :
        (
          <>
      <div className="col-sm-1 form-col"></div>
        <div className="col-sm-1 form-col">
          <InputField
            label="Número"
            name="dades.addressNumber"
            placeholder=""
            value={values.dades.addressNumber}
            error={errors.dades?.addressNumber}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            type="text"
            className="form-group-small"
          />
        </div>
        <div className="col-sm-1 form-col">
          <InputField
            label="Pis"
            name="dades.addressFloor"
            placeholder=""
            value={values.dades.addressFloor}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            className="form-group-small"
          />
        </div>
        <div className="col-sm-1 form-col">
          <InputField
            label="Porta"
            name="dades.addressDoor"
            placeholder=""
            value={values.dades.addressDoor}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            className="form-group-small"
          />
        </div>   
      </>)}
      </div>
      <div className="row form-content">
        <div className="col-sm-2 form-col">
          <InputField
            label="Codi postal"
            name="dades.postalCode"
            placeholder="C.P."
            value={values.dades.postalCode}
            error={errors.dades?.postalCode}
            onChange={(ev) => handlePostalCodeChange(ev)}
            horizontal={false}
            disabled={viewMode}
            // isMandatory={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>

        {isSpain ? (
          <>
            {isDepartment ? (
              
              <>
                <div className="col-sm-2 form-col">
                <Select
                  label="Província"
                  name="dades.provinceId"
                  value={values.dades.provinceId}
                  error={errors.dades?.provinceId}
                  onChange={(ev) => handleProvinceChange(ev)}
                  options={provinces}
                  defaultOption="Província"
                  horizontal={false}
                  disabled={viewMode}
                  // isMandatory={true}
                  required={true}
                />
                </div>

                

                <div className="col-sm-1 form-col"></div>
                <div className="col-sm-2 form-col">
                  <Select
                    label="Població"
                    name="dades.cityId"
                    value={values.dades.cityId}
                    error={errors.dades?.cityId}
                    onChange={(ev) => handleCityChange(ev)}
                    options={cities}
                    defaultOption="Població"
                    horizontal={false}
                    disabled={viewMode}
                    // isMandatory={true}
                    required={true}
                  />
                  </div>

                <div className="col-sm-1 form-col"></div>
                <div className="col-sm-2 form-col">
                    <Select
                        label="Comarca"
                        name="dades.comarqueId"
                        value={values.dades.comarqueId}
                        error={errors.dades?.comarqueId}                        
                        options={comarques}
                        defaultOption="Comarca"
                        horizontal={false}
                        disabled={viewMode}
                        // isMandatory={true}
                        required={false}                      
                    />
                </div>

                  {/* <div className="row form-content"> */}
                    <div className="col-sm-2 form-col" style={{marginTop:"24px"}}>
                        <Select
                          label="País"
                          name="dades.countryId"
                          value={values.dades.countryId}
                          error={errors.dades?.countryId}
                          onChange={(ev) => handleCountryChange(ev)}
                          options={toSelect(countries)}
                          horizontal={false}
                          disabled={viewMode}
                          // isMandatory={true}
                          required={true}                          
                        />
                      </div>
                  {/* </div> */}

              </>
                )
                : 
                ( <> 
                      <div className="col-sm-2 form-col">
                      <Select
                        label="Província"
                        name="dades.provinceId"
                        value={values.dades.provinceId}
                        error={errors.dades?.provinceId}
                        onChange={(ev) => handleProvinceChange(ev)}
                        options={provinces}
                        defaultOption="Província"
                        horizontal={false}
                        disabled={viewMode}
                        // isMandatory={true}
                        required={true}
                      />
                      </div>

                      <div className="col-sm-1 form-col"></div>
                      <div className="col-sm-2 form-col">
                        <Select
                          label="Població"
                          name="dades.cityId"
                          value={values.dades.cityId}
                          error={errors.dades?.cityId}
                          onChange={(ev) => handleCityChange(ev)}
                          options={cities}
                          defaultOption="Població"
                          horizontal={false}
                          disabled={viewMode}
                          // isMandatory={true}
                          required={true}
                        />
                        </div>          

                      <div className="col-sm-1 form-col"></div> 
                      <div className="col-sm-2 form-col">
                        <Select
                          label="País"
                          name="dades.countryId"
                          value={values.dades.countryId}
                          error={errors.dades?.countryId}
                          onChange={(ev) => handleCountryChange(ev)}
                          options={toSelect(countries)}
                          horizontal={false}
                          disabled={viewMode}
                          // isMandatory={true}
                          required={true}
                        />
                      </div>               
                
                </> )
              }
          </>
        ) : (
          <>
            <div className="col-sm-2 form-col">
              <InputField
                label="Població"
                name="dades.city"
                placeholder="Població"
                value={values.dades.city}
                error={errors.dades?.city}
                onChange={handleChange}
                horizontal={false}
                disabled={viewMode}
                // isMandatory={true}
              />
            </div>
            <div className="col-sm-1 form-col"></div>
            <div className="col-sm-2 form-col">
              <Select
                label="País"
                name="dades.countryId"
                value={values.dades.countryId}
                error={errors.dades?.countryId}
                onChange={(ev) => handleCountryChange(ev)}
                options={toSelect(countries)}
                horizontal={false}
                disabled={viewMode}
                // isMandatory={true}
                required={true}
              />
            </div>
          </>
        )}
      </div>
          {!props.showContactData && (
      <div className="row form-content">
        <div className="col-sm-5 form-col">
          <InputField
            label="Email"
            name="dades.email"
            placeholder="Correu electrònic"
            value={values.dades.email}
            error={errors.dades?.email}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
            isMandatory={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <InputField
            label="Telèfon 1"
            name="dades.phoneNumber1"
            placeholder="Telèfon 1"
            value={values.dades.phoneNumber1}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-2 form-col">
          <InputField
            label="Telèfon 2"
            name="dades.phoneNumber2"
            placeholder="Telèfon 2"
            value={values.dades.phoneNumber2}
            onChange={handleChange}
            horizontal={false}
            disabled={viewMode}
          />
        </div>
      </div>)}
    </>
  );
};

export default AddressInfoForm;
