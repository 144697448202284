import React from 'react'
import PropTypes from "prop-types";

const InputFile = (props) => {

    const { onChange, label, className } = props;
    const handleFileInput = (event) => { onChange(event.target.files[0]) }

    let classNameAux = className!=null && className!=undefined ? className : "btn btn-primary app-btn-functional excel-btn"

  return (
      <>
          <label className={classNameAux} htmlFor="uploadFile">
            <i className="uil-upload import-icon" />{label}
        </label>
        <input 
            type="file" 
            className="file-btn"
            id="uploadFile"
            onChange={handleFileInput}
            onClick={(event)=> event.target.value = null}
        />
    </>
  ) 
}

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default InputFile;
