import React, { useState, useEffect } from 'react';
import { ButtonGroup, ButtonToolbar, Button } from 'react-bootstrap';
import ArtisanPersonalInfoForm from './ArtisanForm/components/ArtisanPersonalInfoForm';
import FashionPersonalInfoForm from './FashionForm/components/FashionPersonalInfoForm';
import CommercePersonalInfoForm from './CommerceForm/components/CommercePersonalInfoForm';
import ServicesPersonalInfoForm from './ServicesForm/components/ServicesPersonalInfoForm';
import DistinctivesForm from './ArtisanForm/components/DistinctivesForm';
import JobForm from './ArtisanForm/components/JobForm';
import ArtisanDataForm from './ArtisanForm/components/ArtisanDataForm';
import YoungTalentManager from './ArtisanForm/YoungTalentManager';
import CarnetManager from './ArtisanForm/CarnetManager';
import { useDispatch } from 'react-redux';
import { getCarnetTypes } from 'redux/slices/masterSlice';
import { useFormikContext } from 'formik';

const ManageAreas = () => {
  const dispatch = useDispatch();

  const { values } = useFormikContext();

  useEffect(() => {
    dispatch(getCarnetTypes());
  }, [dispatch]);

  const [currentAreaTab, setCurrentAreaTab] = useState(1);
  const isActive = (numTab) => currentAreaTab === numTab;

  return (
    <>
      <ButtonToolbar
        className="area-toolbar"
        aria-label="Toolbar contacts form"
      >
        <ButtonGroup aria-label="First group">
          <Button onClick={() => setCurrentAreaTab(1)} active={isActive(1)}>
            Artesania
          </Button>
          <Button
            onClick={() => setCurrentAreaTab(2)}
            active={isActive(2)}
          >
            Moda
          </Button>
          <Button
            onClick={() => setCurrentAreaTab(3)}
            active={isActive(3)}
          >
            Comerç
          </Button>
          <Button
            onClick={() => setCurrentAreaTab(4)}
            active={isActive(4)}
          >
            Serveis
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      {currentAreaTab === 1 ? (
        <>
          <ArtisanPersonalInfoForm />
          {
            values.area.artisan.belongs && (
              <>
                <ArtisanDataForm />
                <DistinctivesForm />
                <JobForm />
                <CarnetManager />
                <YoungTalentManager />
              </>
            )
          }
        </>
      ) : currentAreaTab === 2 ? (
        <FashionPersonalInfoForm />
      ) : currentAreaTab === 3 ? (
        <CommercePersonalInfoForm />
      ) : currentAreaTab === 4 ? (
        <ServicesPersonalInfoForm />
      ) : (
        <></>
      )}
    </>
  );
};

export default ManageAreas;
