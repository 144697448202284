import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import Select from "components/shared/Select";
import SimpleTable from "components/shared/SimpleTable";
import MultiSelectListEditableModal from "components/shared/MultiSelectListEditableModal";
import AssociationsModalListFilters from "components/pages/contacts/components/Modals/AssociationsModalListFilters";
import { getFilteredAssociations } from "api/contactApi";
import { useDispatch, useSelector } from "react-redux";
import { toSelect } from "helpers/utils";
import { editModeState } from "redux/slices/contactSlice";
import { getAreasTypes, areasTypesData } from "redux/slices/masterSlice";
import { getAssociationTypes, associationTypesData } from "redux/slices/masterSlice";
import Checkbox from "components/shared/Checkbox";
const AssociationForm = () => {
    const dispatch = useDispatch();
    const associationTypes = toSelect(useSelector(associationTypesData));

    const [currentAssociationType, setCurrentAssociationType] = useState('');
    const [currentScopeType, setCurrentScopeType] = useState('');
    const [currentAmbassador, setCurrentAmbassador] = useState(false);

    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [currentRow, setCurrentRow] = useState({ associationEntityType: '', associationEntityTypeId: '', isNational: '', isNationalId: '', IsContactAmbassador:false });

    const { values, errors, handleChange,  setFieldValue } = useFormikContext();

  const [openAssociationsListModal, setOpenAssociationsListModal] = useState(
    false
  );
  const [modalListAssociations, setModalListAssociations] = useState([]);
  const [associationFilters, setAssociationFilters] = useState({
    name: "",
    areaTypeId: "",
    cif: "",
  });



    const getAmbitoValues = () => {
        return toSelect([{
            id: 1, name: 'Nacional'
        }, { id: 2, name: 'Internacional' }]);
    };

  const areaTypes = toSelect(useSelector(areasTypesData));
  const editMode = useSelector(editModeState);

  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      editable: false,
    },
    {
      dataField: "name",
      text: "Nom",
      sort: false,
      headerStyle: () => {
        return { width: "35%" };
      },
      editable: false,
    },
    {
      dataField: "cif",
      text: "CIF",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      dataField: "areaTypeId",
      text: "areaTypeId",
      hidden: true,
      editable: false,
    },
    {
      dataField: "areaType",
      text: "Àrea",
      sort: false,
      headerStyle: () => {
        return { width: "20%" };
      },
      editable: false,
    },
    {
      dataField: "position",
      text: "Càrrec",
      sort: false,
      headerStyle: () => {
        return { width: "25%" };
      },
      }, {
        dataField: "associationEntityType",
        text: "Tipu d’entitat",
          sort: false,
          headerStyle: () => {
              return { width: "25%" };
          },
      },
      {
          dataField: "isNational",
          text: "Ambit",
          sort: false,
          headerStyle: () => {
              return { width: "25%" };
          },
      }
  ];

  useEffect(() => {
      dispatch(getAreasTypes());
      dispatch(getAssociationTypes());
  }, [dispatch]);

  useEffect(() => {
    getAssociations();
  }, []);

  useEffect(() => {
    getAssociations(associationFilters);
  }, [associationFilters]);

  const onChangeAssociationSearch = (values) => {
    setAssociationFilters({
      ...associationFilters,
      name: values.hasOwnProperty("name")
        ? values.name
        : associationFilters.name,
      areaTypeId: values.hasOwnProperty("areaTypeId")
        ? values.areaTypeId
        : associationFilters.areaTypeId,
      cif: values.hasOwnProperty("cif") ? values.cif : associationFilters.cif,
    });
  };

  const handleReset = () => {
    setAssociationFilters({
      name: "",
      areaTypeId: "",
      cif: "",
    });
  };

  const associationsModalListFilters = AssociationsModalListFilters({
    onChangeSearch: onChangeAssociationSearch,
    filters: associationFilters,
    areaTypes: areaTypes,
    onReset: handleReset,
  });

  const getAssociationModal = () => setOpenAssociationsListModal(true);

  const getAssociations = async (associationFilters) => {
    const data = await getFilteredAssociations(associationFilters);
    setModalListAssociations(data);
  };

  const handleAssociations = (values) => {
    setFieldValue("association.associations", [...values]);
    setOpenAssociationsListModal(false);
  };

    const updateAssociationType = (val) => {

        console.log(associationTypes);
        values.association.associations[currentRowIndex].associationEntityType = associationTypes.filter(a => a.value == val)[0].text;
        values.association.associations[currentRowIndex].associationEntityTypeId = val;
        setCurrentAssociationType(val);
    }

    const updateScopeType = (val) => {

        console.log(val);

        values.association.associations[currentRowIndex].isNational = getAmbitoValues().filter(a => a.value == val)[0].text;
        values.association.associations[currentRowIndex].isNationalId = val;

        setCurrentScopeType(val);
    }

    const onChangeAssociationType = (e) => {
        var val = e.target.value;
        updateAssociationType(val);
    }

    const onChangeAmbassador = (e) => {

        updateAmbassadorVal(e);
        
    }

    const updateAmbassadorVal = (e) => {
        values.association.associations[currentRowIndex].isContactAmbassador = e;
        setCurrentAmbassador(e)        
    }

    const onChangeScopeType = (e) => {
        var val = e.target.value;
        updateScopeType(val);
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: '#e7c5c5'
    };

    const rowEvent = {
        onClick: (e, row, rowIndex) => {


            console.log(values.association.associations);
            console.log(row);
            setCurrentRowIndex(rowIndex);

            setCurrentRow(row);

        }
    };

  return (
    <>
      <div className="row form-content">
        <div>
          
        </div>
        <div className="col-6 form-col table-content scrollableList-big no-margin-bottom">
          <SimpleTable
            columns={columns}
                      data={values.association.associations}
                      rowEvents={rowEvent}
                      selectRow={selectRow}      
            noResultsFound={"Cap associació assignada."}
          />
        </div>

              <div class="col-5">
              <div className="row">
                
              {(currentRowIndex != null && currentRowIndex >= 0) &&
                  <div className="col-4 form-col">

                      <Select
                          label="Tipus d’entitat/associació"
                          name="associationEntityType"
                          value={values.association.associations[currentRowIndex].associationEntityTypeId}

                          onChange={onChangeAssociationType}
                          options={associationTypes}
                          defaultOption="Seleccionar"
                          horizontal={false}
                          disabled={!editMode}
                          // isMandatory={true}
                          required={true}
                      />
                  </div>
              }
              {(currentRowIndex != null && currentRowIndex >= 0) &&

                  <div className="col-4 form-col">
                  <Select
                          label="Tipus ambito"
                          name="associationEntityType"
                          value={values.association.associations[currentRowIndex].isNationalId}

                          onChange={onChangeScopeType}
                          options={getAmbitoValues()}
                          defaultOption="Seleccionar"
                          horizontal={false}
                          disabled={!editMode}
                          // isMandatory={true}
                          required={true}
                  /> 
                 </div>
              }

              {(currentRowIndex != null && currentRowIndex >= 0) &&

                  <div className="col-3 form-col margin-left-20px">
                      <Checkbox
                          name="isContactAmbassador"
                          label="Ambaixador"
                          value={values.association.associations[currentRowIndex].isContactAmbassador}
                              onChange={onChangeAmbassador}
                              checked={currentAmbassador}
                      disabled={!editMode}
                      />
                  </div>
                      }
                  </div>
                  </div>

      </div>
      {editMode && (
        <div className="form-content no-margin-top">
          <button
            type="button"
            className="open-modal-btn form-col-btn margin-left-24px no-margin-top"
            onClick={() => {
              getAssociationModal();
            }}
          >
            <i className="uil-plus-circle form-col-btn-icon" />
            <span className="form-col-btn-text">Seleccionar associacions</span>
          </button>
        </div>
      )}
      <MultiSelectListEditableModal
        isOpen={openAssociationsListModal}
        title="SELECCIONAR ASSOCIACIÓ"
        data={modalListAssociations}
        columns={columns}
        filterElement={associationsModalListFilters}
        noResultsFound={"No s'han trobat associacions."}
        onAccept={handleAssociations}
        onCancel={setOpenAssociationsListModal}
        selectedItems={values.association.associations}
      />
    </>
  );
};

export default AssociationForm;
