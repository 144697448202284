import React from "react";
import Proptypes from 'prop-types';
import { useFormikContext } from "formik";
import { toSelect } from "helpers/utils";
import { useSelector } from 'react-redux';
import InputField from "components/shared/InputField";
import Select from "components/shared/Select";
import { viewModeState, editModeState } from "redux/slices/contactSlice";
import { getSchoolById } from "api/contactApi"
import { formationsData, schoolsData } from "redux/slices/masterSlice";
import { ARTISAN_YOUNGTALENT_ARRAY_NAME } from "constants/applicationConstants";

const FormationForm = (props) => {

  const { values, errors, setFieldValue } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const editMode = useSelector(editModeState);
  const formations = toSelect(useSelector(formationsData));
  const schools = toSelect(useSelector(schoolsData));

  const getIndex = () =>  getFormationsCopy().map(x => x.id).indexOf(props.data.id)

  const getName = () => ARTISAN_YOUNGTALENT_ARRAY_NAME+"[" + getIndex().toString() + "]"

  const getFormationsCopy = () => [...values.area.artisan.contactAreaYoungTalent?.formations]

  const getErrorsCopy = () => [errors.area?.artisan?.contactAreaYoungTalent?.formations]

  const deleteFormation = () => {
    let formationErrors = getErrorsCopy()[0]
    let formations = getFormationsCopy()
    let index = formations.map(x => x.id).indexOf(props.data.id);
    
    if(formationErrors && formationErrors.length > 0)
      formationErrors.splice(index, 1);
    formations.splice(index, 1);

    setFieldValue(ARTISAN_YOUNGTALENT_ARRAY_NAME, formations);
  }

  const handleFormationChange = (value) => {
    let formations = getFormationsCopy()
    formations.filter(x => x.id === props.data.id)[0].formationId = value;
    setFieldValue(ARTISAN_YOUNGTALENT_ARRAY_NAME, formations);
  }
  
  const handleSchoolChange = async (value) => {
    let formations = getFormationsCopy()
    if(value === ""){
      formations.filter(x => x.id === props.data.id)[0].school = {};
    }else{
      const school = await getSchoolById(value);
      formations.filter(x => x.id === props.data.id)[0].school = school;
    }    
    setFieldValue(ARTISAN_YOUNGTALENT_ARRAY_NAME, formations);
  }
  
  return (
    <>
    <div className="carnet-card margin-top-24px">
      <div className="row form-content no-margin-top">
      <div className="col-sm-11 form-col no-margin-top"></div>
      <div className="col-sm-1 form-col no-margin-top">
        {
          editMode 
          ?
          <i 
            onClick={() => deleteFormation()} 
            className="uil-trash form-col-btn-icon delete-button" 
            title="Eliminar formació"
          />
          : <i className="uil-award form-col-btn-icon delete-button" />
        }
      </div>
      </div>
      <div className="row form-content minus-50px-margin-top">
        <div className="col-sm-3 form-col">
          <Select
            label="Formació reglada"
            name={getName()+".formationId"}
            defaultOption="Selecciona formació"
            value={props.data.formationId}
            options={formations}
            error={
              errors?.area?.artisan?.contactAreaYoungTalent?.formations  
              && errors?.area?.artisan?.contactAreaYoungTalent?.formations[getIndex()]
              && errors?.area?.artisan?.contactAreaYoungTalent?.formations[getIndex()].formationId
            }
            onChange={(ev) => handleFormationChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
      </div>
      <div className="row form-content">
        <div className="col-sm-3 form-col">
          <Select
            label="Nom de l'escola"
            name={getName()+".school.id"}
            value={props.data.school.id}
            options={schools}
            defaultOption="Seleccionar escola"
            error={
              errors?.area?.artisan?.contactAreaYoungTalent?.formations  
              && errors?.area?.artisan?.contactAreaYoungTalent?.formations[getIndex()]
              && errors?.area?.artisan?.contactAreaYoungTalent?.formations[getIndex()].school?.id
            }
            onChange={(ev) => handleSchoolChange(ev.target.value)}
            horizontal={false}
            isMandatory={true}
            disabled={viewMode}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <InputField
            label="Email de contacte"
            name={getName()+".school.email"}
            value={props.data.school.email}
            horizontal={false}
            disabled={true}
          />
        </div>
        <div className="col-sm-1 form-col"></div>
        <div className="col-sm-3 form-col">
          <InputField
            label="Pàgina web escola"
            name={getName()+".school.web"}
            value={props.data.school.web}
            horizontal={false}
            disabled={true}
          />
        </div>
      </div>
    </div>
    </>
  );
};

FormationForm.propTypes = {
  data: Proptypes.object
};

export default FormationForm;
