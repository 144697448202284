import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { Accordion, Card } from "react-bootstrap";
import { newGuid } from "helpers/utils";
import InputField from "components/shared/InputField";
import { 
  viewModeState, 
  editModeState, 
} from "redux/slices/contactSlice";
import Checkbox from "components/shared/Checkbox";
import moment from "moment"
import { getFormations, getMarketingTypes, getProfessionalStatus, getSchools, getWorkspaces } from "redux/slices/masterSlice";
import FormationForm from "./components/FormationForm";
import WorkspaceForm from "./components/WorkspaceForm";

const YoungTalentManager = () => {

  const { values, errors, handleChange, setFieldValue } = useFormikContext();
  const viewMode = useSelector(viewModeState);
  const editMode = useSelector(editModeState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormations());
    dispatch(getSchools());
    dispatch(getWorkspaces());
    dispatch(getMarketingTypes());
    dispatch(getProfessionalStatus());
  }, [dispatch]);

  const [arrowClass, setArrowClass] = useState("accordion-arrow-down")

  const handleArrowClass = () => {
    const newArrowClass = arrowClass === "accordion-arrow-down" ? "accordion-arrow-up" : "accordion-arrow-down";
    setArrowClass(newArrowClass)
  }

  const formations = () => {
    if(values.area.artisan.contactAreaYoungTalent 
      && values.area.artisan.contactAreaYoungTalent.formations?.length > 0){
        return values.area.artisan.contactAreaYoungTalent.formations.map((formation) => {
            return <FormationForm key={formation.id} data={formation} />
        });
    }
    return <div className="margin-top-24px">El contacte no té formacions.</div>;
  }

  const workspaces = () => {
    if(values.area.artisan.contactAreaYoungTalent 
      && values.area.artisan.contactAreaYoungTalent.workspaces?.length > 0){
        return values.area.artisan.contactAreaYoungTalent.workspaces.map((workspace) => {
            return <WorkspaceForm key={workspace.id} data={workspace} />
        });
    }
    return <div className="margin-top-24px">El contacte no té lloc de treball.</div>;
  }

  const addFormation = () => {
    let formations = values.area.artisan.contactAreaYoungTalent
      && values.area.artisan.contactAreaYoungTalent.formations
        ? [...values.area.artisan.contactAreaYoungTalent.formations]
        : []
    formations.push({
      id: newGuid(),
      formationId: "",
      school:{
        id: "",
        name: "",
        email: "",
        web: ""
      }
     
    }) 
    setFieldValue("area.artisan.contactAreaYoungTalent.formations", formations);
  }

const addWorkspace = () => {
  let workspaces = values.area.artisan.contactAreaYoungTalent
    && values.area.artisan.contactAreaYoungTalent.workspaces
      ? [...values.area.artisan.contactAreaYoungTalent.workspaces]
      : []
  workspaces.push({
    id: newGuid(),
    workspaceId: "",
    marketingTypeId: "",
    professionalStatusId: "",
    prizes: "",
  })
  setFieldValue("area.artisan.contactAreaYoungTalent.workspaces", workspaces);
}

  return (
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} onClick={handleArrowClass} eventKey="0">
          Talent Jove
          <i className={"uil-arrow-circle-down " + arrowClass} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="row form-content no-margin-top">
              <div className="col-sm-3 form-col">
                <Checkbox
                  label="És un talent jove"
                  name="area.artisan.isYoungTalent"
                  value={values.area.artisan.isYoungTalent}
                  disabled={viewMode}
                />
              </div>
            </div>
              { 
              values.area.artisan.isYoungTalent && (
              <>
              <div className="row form-content">
                <div className="col-sm-3 form-col no-margin-top">
                  <InputField
                    label="Data de naixement"
                    name="area.artisan.contactAreaYoungTalent.born"
                    placeholder="Número de carnet"
                    value={
                      values.area?.artisan?.contactAreaYoungTalent?.born 
                      && values.area?.artisan?.contactAreaYoungTalent?.born !== "0001-01-01T00:00:00"
                      ? moment(values.area?.artisan?.contactAreaYoungTalent?.born).format("YYYY-MM-DD")
                      : ""}
                    error={errors.area?.artisan?.contactAreaYoungTalent?.born}
                    onChange={handleChange}
                    horizontal={false}
                    isMandatory={true}
                    type="date"
                    disabled={viewMode}
                  />
                </div>
              </div>
              <div className="row form-content">
                {editMode && (
                <div className="col-sm-2 form-col">
                    <button
                        type="button"
                        className="open-modal-btn form-col-btn no-margin-top"
                        onClick={() => addFormation()}
                    >
                    <i className="uil-plus-circle form-col-btn-icon" />
                    <span className="form-col-btn-text">Afegir formació</span>
                    </button>
                </div>
                )}
              </div>
              {formations()}
              <div className="row form-content">
                {editMode && (
                <div className="col-sm-2 form-col">
                    <button
                        type="button"
                        className="open-modal-btn form-col-btn no-margin-top"
                        onClick={() => addWorkspace()}
                    >
                    <i className="uil-plus-circle form-col-btn-icon" />
                    <span className="form-col-btn-text">Afegir lloc de treball</span>
                    </button>
                </div>
                )}
              </div>
              {workspaces()}
              </>
            )}          
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}


export default YoungTalentManager;
