import React from "react";
import PropTypes from "prop-types";
import Select from "components/shared/Select";
import InputField from "components/shared/InputField";
import TextAreaField from "components/shared/TextAreaField";
import { Formik } from "formik";
import AlertMessage from "components/shared/AlertMessage";
import moment from "moment";

export const UserForm = (props) => {
    const {
        validationSchema,
        onSubmit,
        profiles,
        newMode,
        isPending,
        setOpenDeleteConfirmation,
        editMode,
        viewMode,
        successMessage,
        errors,
        onResetError,
        user,
    } = props;
    return (
        <>
            {
                <>
                    {errors.onSave && (
                        <AlertMessage
                            message={errors.onSave}
                            isError={true}
                            showAlert={true}
                            handleResetErrors={onResetError}
                        />
                    )}

                    {successMessage !== "" && (
                        <AlertMessage
                            message={successMessage}
                            isError={false}
                            showAlert={true}
                            handleResetErrors={onResetError}
                        />
                    )}
                </>
            }
            <Formik
                initialValues={user}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
            >
                {(props) => {
                    const {
                        values,
                        //touched,
                        errors,
                        //dirty,
                        //isSubmitting,
                        handleChange,
                        //handleBlur,
                        handleSubmit,
                        //handleReset,
                        //setFieldValue,
                    } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="row form-content">
                                <div className="col-sm-6 form-col">
                                    <InputField
                                        label="Àlies"
                                        name="userName"
                                        placeholder="Àlies"
                                        value={values.userName}
                                        error={errors.userName}
                                        onChange={handleChange}
                                        horizontal={false}
                                        disabled={viewMode}
                                        isMandatory={true}
                                        autofocus={true}
                                    />
                                </div>
                                <div className="col-sm-6 form-col no-padding-right">
                                    <InputField
                                        label="Nom"
                                        name="name"
                                        placeholder="Nom"
                                        value={values.name}
                                        error={errors.name}
                                        onChange={handleChange}
                                        horizontal={false}
                                        disabled={viewMode}
                                        isMandatory={true}
                                    />
                                </div>
                            </div>

                            <div className="row form-content">
                                <div className="col-sm-6 form-col">
                                    <InputField
                                        label="Cognom 1"
                                        name="surname1"
                                        placeholder="Primer cognom"
                                        value={values.surname1}
                                        error={errors.surname1}
                                        onChange={handleChange}
                                        horizontal={false}
                                        disabled={viewMode}
                                        isMandatory={true}
                                    />
                                </div>
                                <div className="col-sm-6 form-col no-padding-right">
                                    <InputField
                                        label="Cognom 2"
                                        name="surname2"
                                        placeholder="Segon cognom"
                                        value={values.surname2}
                                        error={errors.surname2}
                                        onChange={handleChange}
                                        horizontal={false}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>

                            <div className="row form-content">
                                <div className="col-sm-6">
                                    <InputField
                                        label="Email"
                                        name="email"
                                        placeholder="Correu electrònic"
                                        value={values.email}
                                        error={errors.email}
                                        onChange={handleChange}
                                        horizontal={false}
                                        disabled={viewMode}
                                        isMandatory={true}
                                    />
                                </div>
                                <div className="col-sm-6 form-col no-padding-right">
                                    <Select
                                        label="Perfil"
                                        name="role"
                                        value={values.role}
                                        error={errors.role}
                                        onChange={handleChange}
                                        options={profiles}
                                        defaultOption="Tots els perfils"
                                        horizontal={false}
                                        disabled={viewMode}
                                        isMandatory={true}
                                    />
                                </div>
                            </div>

                            <div className="row form-content">
                                <div className="col-sm-6 form-col">
                                    <TextAreaField
                                        label="Observacions"
                                        name="observations"
                                        placeholder="Introduir observacions"
                                        value={values.observations}
                                        error={errors.observations}
                                        onChange={handleChange}
                                        rows="4"
                                        disabled={viewMode}
                                    />
                                </div>

                                {(viewMode || editMode) && (
                                    <>
                                        <div className="col-sm-3 form-col">
                                            <InputField
                                                label="Alta"
                                                name="created"
                                                value={
                                                    values.created
                                                        ? moment(values.created).format("YYYY-MM-DD")
                                                        : ""
                                                }
                                                horizontal={false}
                                                disabled={true}
                                                type="date"
                                            />
                                        </div>
                                        <div className="col-sm-3 form-col no-padding-right">
                                            <InputField
                                                label="Baixa"
                                                name="deleted"
                                                value={
                                                    values.deleted
                                                        ? moment(values.deleted).format("YYYY-MM-DD")
                                                        : ""
                                                }
                                                horizontal={false}
                                                disabled={!editMode}
                                                onChange={handleChange}
                                                type="date"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            {(newMode || editMode) && (
                                <div className="row form-content btn-form-group">
                                    <input
                                        type="button"
                                        className="app-btn cancel-btn"
                                        value="Cancel·lar"
                                        disabled={isPending}
                                        onClick={() => {
                                            setOpenDeleteConfirmation(true);
                                        }}
                                    />
                                    <input
                                        type="submit"
                                        disabled={isPending}
                                        className="app-btn submit-btn"
                                        value="Guardar"
                                    />
                                </div>
                            )}
                        </form>
                    );
                }}
            </Formik>
        </>
    );
};

UserForm.propTypes = {
    user: PropTypes.object,
    validationSchema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    profiles: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    successMessage: PropTypes.string,
    viewMode: PropTypes.bool.isRequired,
    newMode: PropTypes.bool.isRequired,
    editMode: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired,
    setOpenDeleteConfirmation: PropTypes.func.isRequired,
    onResetError: PropTypes.func.isRequired,
};
