import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as apiAddress from 'api/addressApi';

export const getCountries = createAsyncThunk('contact/countries', async () => {
  const response = await apiAddress.getAllCountries();
  return response;
});

export const getAllCityHalls = createAsyncThunk(
  'contact/cityHalls',
  async () => {
    const response = await apiAddress.getAllCityHalls();
    return response;
  }
);

const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    viewMode: false,
    editMode: false,
    newMode: false,
    isPending: false,
    countries: { data: [], status: 'idle', error: null },
    cityHalls: { data: [], status: 'idle', error: null },
    spainSelected: true,
    isCompanyType: false,
    cities: [],
    provinces: [],
    comarques: [],
    modalListJobs: [],
    selectedJobs: [],
  },
  reducers: {
    enableViewMode: (state) => {
      state.viewMode = true;
    },
    disableViewMode: (state) => {
      state.viewMode = false;
    },
    enableEditMode: (state) => {
      state.editMode = true;
    },
    disableEditMode: (state) => {
      state.editMode = false;
    },
    enableNewMode: (state) => {
      state.newMode = true;
    },
    disableNewMode: (state) => {
      state.newMode = false;
    },
    selectSpain: (state) => {
      state.spainSelected = true;
    },
    unselectSpain: (state) => {
      state.spainSelected = false;
    },
    setIsCompanyType: (state, { payload }) => {
      state.isCompanyType = payload;
    },
    setIsPending: (state, { payload }) => {
      state.isPending = payload;
    },
    setCities: (state, { payload }) => {
      state.cities = payload;
    },
    setComarques: (state, { payload }) => {
        state.comarques = payload;
    },
    setProvinces: (state, { payload }) => {
      state.provinces = payload;
    },
    setModalListJobs: (state, { payload }) => {
      state.modalListJobs = payload;
    },
    setSelectedJobs: (state, { payload }) => {
      state.selectedJobs = payload;
    },
  },
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.countries.status = 'loading';
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries.status = 'success';
      state.countries.data = payload;
    },
    [getCountries.rejected]: (state, { error }) => {
      state.countries.status = 'failed';
      state.countries.error = error.message;
    },
    [getAllCityHalls.pending]: (state) => {
      state.cityHalls.status = 'loading';
    },
    [getAllCityHalls.fulfilled]: (state, { payload }) => {
      state.cityHalls.status = 'success';
      state.cityHalls.data = payload;
    },
    [getAllCityHalls.rejected]: (state, { error }) => {
      state.cityHalls.status = 'failed';
      state.cityHalls.error = error.message;
    },
  },
});

export const {
  enableViewMode,
  disableViewMode,
  selectSpain,
  unselectSpain,
  setIsCompanyType,
  enableEditMode,
  disableEditMode,
  enableNewMode,
  disableNewMode,
  setIsPending,
  setCities,
  setComarques,
  setProvinces,
  setModalListJobs,
  setSelectedJobs,
} = contactSlice.actions;

export const countriesData = (state) => state.contact.countries.data;
export const cityHallsData = (state) => state.contact.cityHalls.data;
export const viewModeState = (state) => state.contact.viewMode;
export const isSpainState = (state) => state.contact.spainSelected;
export const isCompanyTypeState = (state) => state.contact.isCompanyType;
export const editModeState = (state) => state.contact.editMode;
export const newModeState = (state) => state.contact.newMode;
export const isPendingState = (state) => state.contact.isPending;
export const citiesData = (state) => state.contact.cities;
export const provincesData = (state) => state.contact.provinces;
export const comarquesData = (state) => state.contact.comarques;
export const modalListJobsData = (state) => state.contact.modalListJobs;
export const selectedJobsData = (state) => state.contact.selectedJobs;
export default contactSlice.reducer;
