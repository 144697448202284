import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userProfile: {},
    statusUsers: null,
    statusCurrentUser: null,
    errorUsers: "",
    errorCurrentUser: "",
    currentUser: { data: [], status: "idle", error: null },
    users: [],
  },
  reducers: {
    setUserProfile: (state, { payload }) => {
      state.userProfile = payload;
    },
  },
  extraReducers: {},
});

export const { setUserProfile } = userSlice.actions;

export const userProfileData = (state) => state.user.userProfile;

export default userSlice.reducer;
