import React from "react";
import PropTypes from "prop-types";

function Select({
  label,
  name,
  value,
  onChange,
  defaultOption,
  error,
  options,
  horizontal,
  disabled,
  required,
  isMandatory,
  classNameCols
}) {
  let selectClass = "form-control";
  if (error && error.length > 0) {
    selectClass += " is-invalid";
  }

    if (value == 9999) {
        selectClass += " required-field";
    }
  

  return horizontal ? (
    <div className="form-group row">
      <label htmlFor={name} className={classNameCols ? "col-sm-" + classNameCols[0] : "col-sm-2"}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <div className="col-sm-10">
        <select
          name={name}
          value={value}
          onChange={onChange}
          className={selectClass} 
          disabled={disabled}
          required={required}
        >
          {
          defaultOption 
          ? 
          <option className="selectOption" value="">
            {defaultOption}
          </option>
          : 
          null
          }
          
          {options.map((option) => {
            return (
              <option
                className="selectOption"
                key={option.value}
                value={option.value}
              >
                {option.text}
              </option>
            );
          })}
        </select>
        <div className="invalid-feedback">{error}</div>
      </div>
    </div>
  ) : (
    <div className="form-group">
      <label htmlFor={name}>
        {label && (
          <>
            <span>{label}</span>
            {isMandatory && <span className="asterisc">*</span>}
          </>
        )}
      </label>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={selectClass}
        disabled={disabled}
        required={required}
      >
        {
          defaultOption 
          ? 
          <option className="selectOption defaultOption" value="">
            {defaultOption}
          </option>
          : 
          null
          }
          
        {options.map((option) => {
          return (
            <option
              className="selectOption"
              key={option.value}
              value={option.value}
            >
              {option.text}
            </option>
          );
        })}
      </select>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  error: PropTypes.string,
  defaultOption: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  classNameCols: PropTypes.arrayOf(PropTypes.number),
  horizontal: PropTypes.bool,
  required: PropTypes.bool,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Select;
