import React, { useState } from "react";
import { useFormikContext } from "formik";
import Checkbox from "components/shared/Checkbox";
import InputField from "components/shared/InputField";
import { useSelector } from "react-redux";
import { viewModeState } from "redux/slices/contactSlice";
import { Accordion, Card } from "react-bootstrap";

const DistinctivesForm = () => {

  const { values, errors, handleChange, setFieldValue} = useFormikContext();
  const viewMode = useSelector(viewModeState);

  const [arrowClass, setArrowClass] = useState("accordion-arrow-down")

  const handleArrowClass = () => {
    const newArrowClass = arrowClass === "accordion-arrow-down" ? "accordion-arrow-up" : "accordion-arrow-down";
    setArrowClass(newArrowClass)
  }

  const handleDistinctiveDEVAChange = () => {
    const text = values.area?.artisan?.distinctiveDEVA 
      ? "" : values.area.artisan.distinctiveDEVAExpedientNumber;
    setFieldValue("area.artisan.distinctiveDEVAExpedientNumber", text)
  }

  return (
    <>
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} onClick={handleArrowClass} eventKey="0">
          Distintius
          <i className={"uil-arrow-circle-down " + arrowClass} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          <div className="row form-content no-margin-top">
            <div className="col-sm-2 form-col">
              <Checkbox
                name="area.artisan.distinctiveAuthorizedProduct"
                label="Distintiu producte autoritzat"
                value={values.area?.artisan?.distinctiveAuthorizedProduct}
                disabled={viewMode}
              />
            </div>
            <div className="col-sm-2 form-col margin-left-20px">
              <Checkbox
                name="area.artisan.distinctiveLabeledProduct"
                label="Distintiu producte etiquetat"
                value={values.area?.artisan?.distinctiveLabeledProduct}
                disabled={viewMode}
              />
            </div>
            <div className="col-sm-3 form-col margin-left-34px">
              <Checkbox
                name="area.artisan.footPrints"
                label="Està al catàleg de la botiga Empremtes"
                value={values.area?.artisan?.footPrints}
                disabled={viewMode}
              />
            </div>
          </div>
          <div className="row form-content">
              <div className="col-sm-2">
                <Checkbox
                  name="area.artisan.distinctiveDEVA"
                  label="Distintiu a establiment"
                  value={values.area?.artisan?.distinctiveDEVA}
                  onChange={() => handleDistinctiveDEVAChange()}
                  disabled={viewMode}
                />
              </div>
              <div className="col-sm-3 detail-field margin-left-20px">
                <InputField
                  name="area.artisan.distinctiveDEVAExpedientNumber"
                  placeholder="DEVA_XXX/20XX"
                  value={values.area.artisan.distinctiveDEVAExpedientNumber}
                  error={errors.area?.artisan?.distinctiveDEVAExpedientNumber}
                  onChange={handleChange}
                  classNameCols={[1,3]}
                  disabled={viewMode || !values.area?.artisan?.distinctiveDEVA}
                />
              </div>
          </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
      
    </>
  );
};

export default DistinctivesForm;
