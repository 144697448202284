import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import mastersReducer from "./slices/masterSlice";
import contactReducer from "./slices/contactSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    master: mastersReducer,
    contact: contactReducer,
  },
});
