import React, { useState } from "react";
import { useFormikContext } from "formik";
import { useSelector } from 'react-redux';
import { Accordion, Card } from "react-bootstrap";
import { newGuid } from "helpers/utils";
import { editModeState } from "redux/slices/contactSlice";
import CarnetForm from "./components/CarnetForm"

const CarnetManager = () => {

  const { values, setFieldValue } = useFormikContext();
  const editMode = useSelector(editModeState);

  const carnets = () => {
    if(values.area.artisan.contactAreaArtisanCarnets?.length > 0){
        return values.area.artisan.contactAreaArtisanCarnets.sort(function(a,b){
          return new Date(b.subscripted) - new Date(a.subscripted);
        }).map((carnet) => {
            return <CarnetForm key={carnet.id} data={carnet} />
        });
    }
    return ("El contacte no té carnets.");
  }

  const [arrowClass, setArrowClass] = useState("accordion-arrow-down")

  const handleArrowClass = () => {
    const newArrowClass = arrowClass === "accordion-arrow-down" ? "accordion-arrow-up" : "accordion-arrow-down";
    setArrowClass(newArrowClass)
  }

  const addCarnet = () => {
    let contactAreaArtisanCarnets = [...values.area.artisan.contactAreaArtisanCarnets]
    contactAreaArtisanCarnets.push({
        id: newGuid(),
        jobTypes: [],
        artisanContactCarnetTypeId: "",
        number: "",
        observations: "",
        subscripted: "",
        unSubscripted: "",
        companyId: "",
        company: ""
    })
    setFieldValue("area.artisan.contactAreaArtisanCarnets", contactAreaArtisanCarnets);
  }

  return (
    <>
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} onClick={handleArrowClass}  eventKey="0">
          Carnet
          <i className={"uil-arrow-circle-down " + arrowClass} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="row form-content">
                {editMode && (
                <div className="col-sm-2 form-col">
                    <button
                        type="button"
                        className="open-modal-btn form-col-btn no-margin-top"
                        onClick={() => addCarnet()}
                    >
                    <i className="uil-plus-circle form-col-btn-icon" />
                    <span className="form-col-btn-text">Afegir carnet</span>
                    </button>
                </div>
                )
                }
            </div>
            <div className="row form-content">
              {carnets()}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
    </>
  );
};

export default CarnetManager;
