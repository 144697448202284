import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

function Checkbox(props) {
  const { value, onChange, disabled, name, label, mandatory } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <input
        name={name}
        checked={value}
        className="checkbox-col-form"
        type="checkbox"
        disabled={disabled}
        onChange={() => {
          setFieldValue(name, !value);
          if(onChange)
            onChange(!value);
        }}
      />
      <label className="form-check-label" htmlFor={name}>
        {label + ' '}
        {mandatory && <span className="asterisc">*</span>}
      </label>
    </>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  value: PropTypes.bool.isRequired,
};

export default Checkbox;
