import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

const ContactList = (props) => {
  const {
    contacts,
    defaultSorted,
    columns,
    handleTable,
    noResultsFound,
    paginationOptions,
    bordered,
    rowEvents,
    rowClasses
  } = props;

  return (
    <>
      <BootstrapTable
        remote
        keyField="id"
        data={contacts}
        defaultSorted={defaultSorted}
        columns={columns}
        pagination={paginationFactory(paginationOptions)}
        wrapperClasses="table-responsive"
        onTableChange={handleTable}
        rowClasses={rowClasses}
        noDataIndication={noResultsFound}
        bordered={bordered}
        rowEvents={rowEvents}
        bootstrap4
      />
    </>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  defaultSorted: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  handleTable: PropTypes.func.isRequired,
  noResultsFound: PropTypes.func.isRequired,
  paginationOptions: PropTypes.object.isRequired,
  bordered: PropTypes.bool.isRequired,
  rowEvents: PropTypes.object.isRequired,
};

export default ContactList;
