import BootstrapTable from 'react-bootstrap-table-next';
import React from "react";
import PropTypes from "prop-types";
import { propTypes } from 'react-bootstrap/esm/Image';

const SimpleTable = (props) => {

    const { data, columns, noResultsFound, rowEvents, selectRow, rowClasses } = props

    return (
        <BootstrapTable 
            keyField='id' 
            data={ data ?? [] } 
            columns={ columns } 
            noDataIndication={noResultsFound}
            rowEvents={rowEvents}
            selectRow={selectRow}
            rowClasses={rowClasses}
            bordered={ false }
        />
    );

  }

SimpleTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array.isRequired,
    noResultsFound: PropTypes.string.isRequired
};
  
export default SimpleTable;
  