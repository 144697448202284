import { handleResponse, handleError, format } from "./utilApi";
import axiosInstance from "../helpers/axiosInstance";

const baseUserURL = "/api/companies/";

export async function getFilteredCompanies(filters) {
  const extension = "filter";
  return axiosInstance
    .get(`${baseUserURL + extension}?businessName=${format(filters?.businessName)}&tradeName=${format(filters?.tradeName)}&cif=${format(filters?.cif)}&sortOrder=${format(filters?.sortOrder)}&orderBy=${format(filters?.orderBy)}`)
    .then(handleResponse)
    .catch(handleError);
}

