import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "components/shared/PageTitle";
import { capitalizeFirstLetter, toTextDate } from "helpers/utils";
import { ContactForm } from "./components/ContactForm";
import * as Yup from "yup";
import { isNIF, isNIE } from "better-dni";
import { isCIF } from "helpers/utils";
import Spinner from "components/shared/Spinner";
import { createContact, getById, updateContact } from "api/contactApi";
import { ButtonGroup, ButtonToolbar, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  enableViewMode,
  enableEditMode,
  disableEditMode,
  disableViewMode,
  setIsCompanyType,
  disableNewMode,
  enableNewMode,
  newModeState,
  editModeState,
  viewModeState,
  setIsPending,
  isPendingState,
  setCities,
  setProvinces,
} from "redux/slices/contactSlice";
import { COMPANY_TYPE } from "constants/applicationConstants";
import { getAddressFromCP } from "api/addressApi";
import moment from "moment";
import CanAccessContent from "components/shared/CanAccessContent";
import { Roles } from "constants/Roles";
import { useHistory } from "react-router-dom";
import FormHeader from "./components/DataForm/components/FormHeader"

const ContactFormPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMounted, setIsMounted] = useState(false);
  const [isSpain, setIsSpain] = useState(true);
  const newMode = useSelector(newModeState);
  const isPending = useSelector(isPendingState);
  const editMode = useSelector(editModeState);
  const viewMode = useSelector(viewModeState);
  const [contact, setContact] = useState({
    id: "",
    dades: {
      name: "",
      surname1: "",
      surname2: "",
      genderType: "",
      documentTypeId: "1",
      documentType: "",
      documentNumber: "",
      addressType: "5",
      addressName: "",
      addressNumber: "",
      adressFloor: "",
      adressDoor: "",
      postalCode: "",
      province: "",
      provinceId: "",
      department: "",
      departmentId: "",
      city: "",
      cityId: "",
      country: "",
      countryId: "6",
      email: "",
      phoneNumber1: "",
      phoneNumber2: "",
      position: "",
      contactTypeId: "",
      companyId: "",
      company: "",
      tradeName: "",
      businessName: "",
      cif: "",
      cityHallId: "",
  
      localEntityType: "",
      localEntityName: "",
      localEntitySector: "",
      isAmbassador: false,

      created: props.match.params.id ? null : moment(),
      modified: props.match.params.id ? null : moment(),
      deleted: null,
      consent: false,
      web:"",
      facebook:"",
      twitter:"",
      instagram:"",
      pinterest:"",
      othersocialnetwork:"",
      observations: "",
    },
    area: {
      artisan: {
        id: null,
        interested: false,
        belongs: false,
        footPrints: false,
        distinctiveLabeledProduct: false,
        distinctiveAuthorizedProduct: false,
        distinctiveDEVA: false,
        distinctiveDEVAExpedientNumber: "",
        jobTypes: [],
        contactAreaArtisanCarnets: [],
        contactAreaArtisanData: {
          isVIP: false,
          isAmbassador: false,
        },
        isYoungTalent: false,
        contactAreaYoungTalent: {},
      },
      fashion: {
        id: null,
        interested: false,
        belongs: false,
      },
      commerce: {
        id: null,
        interested: false,
        belongs: false,
      },
      services: {
        id: null,
        interested: false,
        belongs: false,
      },
    },
    association: {
      associations: [],
    },
    relation: {
      relations: []
      // ,
      // action: "",
      // year:""
      
    },
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [currentContactTab, setCurrentContactTab] = useState(1);

  useEffect(() => {
    if (props.match.params.id) {
      getContact();
      dispatch(enableViewMode());
      dispatch(disableNewMode());
      dispatch(disableEditMode());
    } else {
      dispatch(enableNewMode());
      dispatch(disableViewMode());
      dispatch(disableEditMode());
      dispatch(setIsPending(false));
      setIsMounted(true);
    }
  }, []);

  const getContact = async () => {
    dispatch(setIsPending(true));
    const contact = await getById(props.match.params.id);
    
      console.log(contact);

      if (contact.dades.documentTypeId == null)
          contact.dades.documentTypeId = 9999;

      if (contact.dades.countryId === 6) {
      const addresFromCp = await getAddressFromCP(contact.dades.postalCode);

      let cities = [{
        value: contact.dades.cityId,
        text: contact.dades.city
      }];
      let provinces = [{
        value: contact.dades.provinceId,
        text: contact.dades.province
      }];

      if(addresFromCp.length > 0){
        cities = addresFromCp.map((item) => ({
          value: item.cityId,
          text: item.city,
        }));
        provinces = [{
          value: addresFromCp[0].provinceId,
          text: addresFromCp[0].province
        }];
      }

      dispatch(setCities(cities));
      dispatch(setProvinces(provinces));
      setIsSpain(true);
    } else {
      setIsSpain(false);
    }

    // if(contact.relations.relation !== null ){
    //   var relationTypes = [];
    //   relationTypes[0] = {id: contact.relation.relation.id , name:contact.relation.relation.name}
    //   contact.relation.relation = relationTypes
    // }
    
    setContact(contact);
    dispatch(setIsCompanyType(contact.dades.contactTypeId === COMPANY_TYPE));
    dispatch(setIsPending(false));
    setIsMounted(true);
  };

  const handleSubmit = async (values) => {


    
    

    dispatch(setIsPending(true));
    if (editMode) {
      if (values.dades.deleted === "") {
        values.dades.deleted = null;
      }
      if (values.dades.deleted && !contact.dades.deleted) {
        removeContact(getContactToSend(values));
      } else {
        //added 
        // alert("test2");
        if(values.relation!=null && values.relation.relation!=null && values.relation.relation.length>0)
          values.relation.relation = {id: values.relation.relation[0].id, name: values.relation.relation[0].name };

          console.log(values);
        editContact(getContactToSend(values));
      }
    } else {
      addContact(getContactToSend(values));
    }
  };

  const getContactToSend = (values) => {
    let contactToSend = {
      ...values,
    };

    if (values.dades.contactTypeId === COMPANY_TYPE) {
      contactToSend.companyId = values.dades.companyId;
      contactToSend.tradeName = values.dades.tradeName;
      contactToSend.businessName = values.dades.businessName;
    } else {
      contactToSend.tradeName = values.dades.tradeName;
      contactToSend.company = null;
    }

    if (editMode) {
      if (contact.area.artisan.id && !contactToSend.area.artisan.id) {
        contactToSend.area.artisan.id = contact.area.artisan.id;
      }
    }

    if (newMode) return contactToSend.dades;

    return contactToSend;
  };

  const addContact = async (contact) => {
    await createContact(contact)
      .then((data) => {
        dispatch(enableViewMode());
        dispatch(disableEditMode());
        dispatch(disableNewMode());
        setErrors({});
        setContact(data);
        history.push(`/contact/${data.id}`)
        setSuccessMessage(
          `El contacte "${contact.name}" s'ha creat correctament.`
        );
      })
      .catch((error) => {
        setErrors({ onSave: handleErrorMessages(error) });
      })
      .finally(() => dispatch(setIsPending(false)));
  };

  const editContact = async (values) => {
  
    await updateContact(contact.id, values)
      .then((data) => {
        dispatch(enableViewMode());
        dispatch(disableEditMode());
        dispatch(disableNewMode());
        setErrors({});
        dispatch(setIsCompanyType(data.dades.contactTypeId === COMPANY_TYPE));
        setContact(data);
      })
      .catch((error) => {
        setErrors({ onSave: handleErrorMessages(error) });
      })
      .finally(() => dispatch(setIsPending(false)));
  };

  const removeContact = async (contact) => {
    await updateContact(contact.id, contact)
      .then((data) => {
        dispatch(enableViewMode());
        dispatch(disableEditMode());
        dispatch(disableNewMode());
        setErrors({});
        setContact(data);
        setSuccessMessage(
          `El contacte "${contact.dades.name}" s'ha donat de baixa amb data "${toTextDate(contact.dades.deleted)}" correctament.`
        );
      })
      .catch((error) => {
        setErrors({ onSave: handleErrorMessages(error) });
      })
      .finally(() => dispatch(setIsPending(false)));
  };

  const handleErrorMessages = (error) => {
    if (
      error.response &&
      error.response.status === 422 &&
      error.response.data
    ) {
      return error.response.data;
    }
    return "Ha hagut un error processant la petició.";
  };

  const handleEditMode = () => {
    dispatch(enableEditMode());
    dispatch(disableViewMode());
    setSuccessMessage("");
  };

  const breadcrumbItems = [
    {
      text: "Inici",
      current: false,
      linkTo: "/",
    },
    {
      text: "Contactes",
      current: false,
      linkTo: "/contacts",
    },
    {
      text: capitalizeFirstLetter(viewMode ? "CONTACTE" : "NOU CONTACTE"),
      current: true,
      linkTo: newMode ? "/contact" : `/contact/:${props.match.params.id}`,
    },
  ];

  const handleResetErrors = () => {
    setErrors({});
  };

  const isActive = (numTab) => currentContactTab === numTab;

  const handleCurrentContactTab = (numTab) => {
    setCurrentContactTab(numTab);
  };

  Yup.addMethod(Yup.string, "isDocumentNumberValid", function (errorMessage) {
    return this.test("test-document-valid", function (value) {
      const { path, createError, parent } = this;

        if (value.toUpperCase() != "NO ESTABLECIDO" && parent.documentTypeId!=9999) {

            
            if (parent.documentTypeId === "1")
                //DNI o NIF
                return (
                    isNIF(value) ||
                    createError({ path, message: errorMessage + " per a un NIF." })
                );

            if (parent.documentTypeId === "2")
                //NIE
                return (
                    isNIE(value) ||
                    createError({ path, message: errorMessage + " per a un NIE." })
                );

            if (parent.documentTypeId === "3")
                //CIF
                return (
                    isCIF(value) ||
                    createError({ path, message: errorMessage + " per a un CIF." })
                );

            if (parent.documentTypeId === "4")
                //Passport
                return true;
        }
        else
            return true;
    });
  });

  const validationNewModeSchema = Yup.object().shape({
    dades: Yup.object().shape({
      name: Yup.string().nullable().required("És obligatori introduir el nom."),
      surname1: Yup.string()
        .nullable()
        .required("És obligatori introduir el primer cognom."),
      surname2: Yup.string().nullable(),
        genderType: Yup.string().nullable(),
      documentNumber: Yup.string()
          .nullable()
        .isDocumentNumberValid("El format del document és incorrecte"),
      documentTypeId: Yup.string()
        .nullable(),
      addressName: Yup.string()
        .nullable(),
      addressNumber: Yup.string().nullable(),
      addressFloor: Yup.string().nullable(),
      addressDoor: Yup.string().nullable(),
      postalCode: Yup.string()
        .nullable(),
      provinceId: Yup.string().nullable()
      .test("provinceId", "", function (value) {
        const isSpain = this.from[1].value.dades.countryId.toString() === "6";
        if (isSpain && (!value || value.length <= 0)) {
          return this.createError({
            path: this.path,
            message: "És obligatori introduir la província.",
          });
        }
        return true;
      }),
      cityId: Yup.string().nullable()
      .test("cityId", "", function (value) {
        const isSpain = this.from[1].value.dades.countryId.toString() === "6";
        if (isSpain && (!value || value.length <= 0)) {
          return this.createError({
            path: this.path,
            message: "És obligatori introduir la població.",
          });
        }
        return true;
      }),
      city: Yup.string().nullable()
      .test("city", "", function (value) {
        const isSpain = this.from[1].value.dades.countryId.toString() === "6";
        if (!isSpain && (!value || value.length <= 0)) {
          return this.createError({
            path: this.path,
            message: "És obligatori introduir la població.",
          });
        }
        return true;
      }),
      countryId: Yup.string().required("És obligatori introduir el país."),
      email: Yup.string()
        .email("El format de correu és incorrecte.")
        .required("És obligatori introduir el correu eletrònic."),
      phoneNumber1: Yup.string().nullable(),
      phoneNumber2: Yup.string().nullable(),
      position: Yup.string().nullable(),
      contactTypeId: Yup.string().required(
        "És obligatori introduir el tipus de contacte."
      ),
      company: Yup.string().nullable(),
      cityHall: Yup.string().nullable(),
      consent: Yup.bool(),
      //add creation considerations
      web: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      twitter: Yup.string().nullable(),
      instagram: Yup.string().nullable(),
      pinterest: Yup.string().nullable(),
      othersocialnetwork: Yup.string().nullable(),

      observations: Yup.string().nullable(),
    }),
  });

  const validationEditModeSchema = Yup.object().shape({
    dades: Yup.object().shape({
      name: Yup.string().nullable().required("És obligatori introduir el nom."),
      surname1: Yup.string()
        .nullable()
        .required("És obligatori introduir el primer cognom."),
      surname2: Yup.string().nullable(),
        genderType: Yup.string().nullable(),
      documentNumber: Yup.string()
          .nullable()
        .isDocumentNumberValid("El format del document és incorrecte"),
      documentTypeId: Yup.string()
        .nullable(),        
      addressName: Yup.string()
        .nullable(),       
      addressNumber: Yup.string().nullable(),
      addressFloor: Yup.string().nullable(),
      addressDoor: Yup.string().nullable(),
      postalCode: Yup.string()
        .nullable(),
        
      provinceId: Yup.string().nullable()
        .test("provinceId", "", function (value) {
          const isSpain = this.from[1].value.dades.countryId.toString() === "6";
          if (isSpain && (!value || value.length <= 0)) {
            return this.createError({
              path: this.path,
              message: "És obligatori introduir la província.",
            });
          }
          return true;
        }),
      cityId: Yup.string().nullable()
        .test("cityId", "", function (value) {
          const isSpain = this.from[1].value.dades.countryId.toString() === "6";
          if (isSpain && (!value || value.length <= 0)) {
            return this.createError({
              path: this.path,
              message: "És obligatori introduir la població.",
            });
          }
          return true;
        }),
      city: Yup.string().nullable()
        .test("city", "", function (value) {
          const isSpain = this.from[1].value.dades.countryId.toString() === "6";
          if (!isSpain && (!value || value.length <= 0)) {
            return this.createError({
              path: this.path,
              message: "És obligatori introduir la població.",
            });
          }
          return true;
      }),
      countryId: Yup.string().required("És obligatori introduir el país."),
      email: Yup.string()
        .email("El format de correu és incorrecte.")
        .required("És obligatori introduir el correu eletrònic."),
      phoneNumber1: Yup.string().nullable(),
      phoneNumber2: Yup.string().nullable(),
      position: Yup.string().nullable(),
      contactTypeId: Yup.string().required(
        "És obligatori introduir el tipus de contacte."
      ),
      company: Yup.string().nullable(),
      cityHall: Yup.string().nullable(),
      consent: Yup.bool(),
      //add edition considerations
      web: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      twitter: Yup.string().nullable(),
      instagram: Yup.string().nullable(),
      pinterest: Yup.string().nullable(),
      othersocialnetwork: Yup.string().nullable(),

      observations: Yup.string().nullable(),
      deleted: Yup.date().nullable()
    }),
    area: Yup.object().shape({
      artisan: Yup.object().shape({
        belongs: Yup.boolean().test(
          "mustBelongOrBeInterested",
          "",
          function (value) {
            if (
              !value &&
              !this.parent.interested &&
              !this.from[1].value.fashion.belongs &&
              !this.from[1].value.fashion.interested &&
              !this.from[1].value.commerce.belongs &&
              !this.from[1].value.commerce.interested &&
              !this.from[1].value.services.belongs &&
              !this.from[1].value.services.interested
            ) {
              return this.createError({
                path: this.path,
                message:
                  "Falta seleccionar àrea a la que pertany o àrea d'interès.",
              });
            }
            return true;
          }
        ),
        interested: Yup.boolean(),
        isYoungTalent: Yup.bool(),
        distinctiveDEVAExpedientNumber: Yup.string()
          .nullable()
          .test("distinctiveDEVAExpedientNumber", "", function (value) {
            const distinctiveDEVA = this.from[1].value.artisan.distinctiveDEVA;
            const belongsToArtisan = this.from[1].value.artisan.belongs;
            if (belongsToArtisan && distinctiveDEVA) {
              if (!value || value.length <= 0) {
                return this.createError({
                  path: this.path,
                  message: "És obligatori informar el número d'expedient.",
                });
              }

              let regex = /DEVA_[0-9]{3}\/20[0-9]{2}/g;

              if (!regex.test(value)) {
                return this.createError({
                  path: this.path,
                  message:
                    "El format del número d'expedient és incorrecte (DEVA_XXX/20XX).",
                });
              }
            }
            return true;
          }),
        jobTypes: Yup.array().nullable(),
        contactAreaArtisanCarnets: Yup.array()
          .nullable()
          .of(
            Yup.object().shape({
              artisanContactCarnetTypeId: Yup.string().nullable().test(
                "carnetTypesTest",
                "",
                function (value) {
                  const belongsToArtisan = this.from[1].value.belongs;
                  if (belongsToArtisan && (!value || value.length <= 0)) {
                    return this.createError({
                      path: this.path,
                      message:
                        "És obligatori introduir la modalitat del carnet.",
                    });
                  }
                  return true;
                }
              ),
              jobTypes: Yup.array()
                .nullable()
                .test("jobTypesCarnetTest", "", function (value) {
                  const belongsToArtisan = this.from[1].value.belongs;
                  if (belongsToArtisan && (!value || value.length <= 0)) {
                    return this.createError({
                      path: this.path,
                      message: "Seleccioneu un ofici com a mínim.",
                    });
                  }
                  return true;
                }),
              number: Yup.string().test(
                "carnetNumberTest",
                "",
                function (value) {
                  const belongsToArtisan = this.from[1].value.belongs;
                  if (belongsToArtisan && (!value || value.length <= 0)) {
                    return this.createError({
                      path: this.path,
                      message: "És obligatori introduir el número de carnet.",
                    });
                  }
                  return true;
                }
              ),
              observations: Yup.string(),
              subscripted: Yup.date()
                .nullable()
                .test("subscriptedDate", "", function (value) {
                  const belongsToArtisan = this.from[1].value.belongs;
                  if (
                    belongsToArtisan &&
                    (!value || value === "" || value.getFullYear() <= 1901)
                  ) {
                    return this.createError({
                      path: this.path,
                      message:
                        "És obligatori introduir la data de concessió del carnet.",
                    });
                  }
                  return true;
                }),
              companyId: Yup.string().nullable(),
              company: Yup.string().nullable(),
            })
          ),
        contactAreaYoungTalent: Yup.object()
          .nullable()
          .shape({
            born: Yup.date().nullable().test(
              "mandatoryBornIfIsYoungTalent",
              "",
              function (value) {
                const belongsToArtisan = this.from[1].value.belongs;
                const isYoungTalent = this.from[1].value.isYoungTalent;
                if (
                  belongsToArtisan &&
                  isYoungTalent &&
                  (!value || value === "" || value.getFullYear() <= 1901)
                ) {
                  return this.createError({
                    path: this.path,
                    message: "És obligatori introduir la data de naixement.",
                  });
                }
                return true;
              }
            ),
            formations: Yup.array()
              .nullable()
              .of(
                Yup.object().shape({
                  id: Yup.string().test(
                    "mandatoryFormationIfIsYoungTalent",
                    "",
                    function (value) {
                      const belongsToArtisan = this.from[1].value.belongs;
                      const isYoungTalent = this.from[3].value.artisan
                        .isYoungTalent;
                      if (belongsToArtisan && isYoungTalent && !value) {
                        return this.createError({
                          path: this.path,
                          message: "És obligatori informar la formació.",
                        });
                      }
                      return true;
                    }
                  ),
                  formationId: Yup.string(),
                  school: Yup.object().shape({
                    id: Yup.string().test(
                      "mandatorySchoolIfIsYoungTalent",
                      "",
                      function (value) {
                        const belongsToArtisan = this.from[1].value.belongs;
                        const isYoungTalent = this.from[3].value.isYoungTalent;
                        if (belongsToArtisan && isYoungTalent && !value) {
                          return this.createError({
                            path: this.path,
                            message: "És obligatori informar l'escola.",
                          });
                        }
                        return true;
                      }
                    ),
                    name: Yup.string(),
                    email: Yup.string(),
                    web: Yup.string(),
                  }),
                })
              ),
          }),
          workspace: Yup.array()
          .nullable()
          .of(
            Yup.object().shape({
              id: Yup.string().test(
                "mandatoryFormationIfIsYoungTalent",
                "",
                function (value) {
                  const belongsToArtisan = this.from[1].value.belongs;
                  const isYoungTalent = this.from[3].value.artisan
                    .isYoungTalent;
                  if (belongsToArtisan && isYoungTalent && !value) {
                    return this.createError({
                      path: this.path,
                      message: "És obligatori És obligatori informar l'espai de treball.",
                    });
                  }
                  return true;
                }
              ),
              workspaceId: Yup.string().nullable(false),
              marketingTypeId: Yup.string().nullable(false),
              professionStatus: Yup.string().nullable(false),
              prizes: Yup.string(),
            })
          ),
      }),
      fashion: Yup.object().shape({
        belongs: Yup.boolean(),
        interested: Yup.boolean(),
      }),
      commerce: Yup.object().shape({
        belongs: Yup.boolean(),
        interested: Yup.boolean(),
      }),
      services: Yup.object().shape({
        belongs: Yup.boolean(),
        interested: Yup.boolean(),
      }),
    }),
  });

  return (
    <>
      <PageTitle
        title={newMode ? "NOU CONTACTE" : "CONTACTE DETALL"}
        breadcrumbItems={breadcrumbItems}
      />
      {!isMounted ? (
        <Spinner />
      ) : (
        <>
          <div className="row main-content">
            <div className="col new-user-subheader">
              <span>{viewMode ? "CONSULTA DE DADES" : "EDICIÓ DE DADES"}</span>

              {(viewMode || editMode) && (
                <CanAccessContent roles={[Roles.ADMIN, Roles.EDITOR]}>
                  <button
                    type="button"
                    className="app-btn float-right edit-btn"
                    disabled={isPending || editMode}
                    value="Edita contacte"
                    onClick={function () {
                      handleEditMode();
                    }}
                  >
                    <i className="uil uil-edit" aria-hidden="true" /> Edita contacte
                  </button>
                </CanAccessContent>
              )}

              <br></br>     
              <br></br> 
              <br></br>   
              <FormHeader
              contact={contact}
              isNewMode={newMode}/>
              <hr className="divider"></hr>

              <ButtonToolbar
                className="contacts-toolbar"
                aria-label="Toolbar contacts form"
              >
                <ButtonGroup aria-label="First group">
                  <Button
                    onClick={() => handleCurrentContactTab(1)}
                    active={isActive(1)}
                  >
                    DADES
                  </Button>
                  <Button
                    onClick={() => handleCurrentContactTab(2)}
                    active={isActive(2)}
                    disabled={newMode}
                  >
                    ÀREA
                  </Button>
                  <Button
                    onClick={() => handleCurrentContactTab(3)}
                    active={isActive(3)}
                    disabled={newMode}
                  >
                    ASSOCIACIONS/ENTITATS
                  </Button>
                  <Button
                    onClick={() => handleCurrentContactTab(4)}
                    active={isActive(4)}
                    disabled={newMode}
                  >
                    RELACIÓ
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <ContactForm
                contact={contact}
                validationSchema={
                  newMode ? validationNewModeSchema : validationEditModeSchema
                }
                onSubmit={handleSubmit}
                errorsFromServer={errors}
                successMessage={successMessage}
                onResetError={handleResetErrors}
                currentContactTab={currentContactTab}
                onResetForm={getContact}
              />
            </div>
          </div>

          {isPending && <Spinner />}
        </>
      )}
    </>
  );
};

ContactFormPage.propTypes = {
  match: PropTypes.object,
};

export default ContactFormPage;
